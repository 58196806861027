import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Work} from '@models';
import * as moment from 'moment';

@Component({
  selector: 'section-certificate',
  templateUrl: './section-certificate.component.html',
  styleUrls: ['./section-certificate.component.scss']
})
export class SectionCertificateComponent implements OnInit, OnChanges{

  @Input() item: Work;
  @Input() selectedOption = 'cm';
  @Input() language;

  constructor() {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
  }

  getAuthorData(data){
    if (!data)
      return;

    if (data.denomination)
      return data.denomination;

    let name = '';
    if (data.name)
      name += data.name + ' ';

    if (data.lastname)
      name += data.lastname;

    let string = '';

    if (name)
      string+= name;

    if (data.place_of_birth || data.date_of_birth || data.place_of_death || data.date_of_death) {
      string+= ' (';

      if (data.place_of_birth)
        string+= data.place_of_birth + ', ';

      if (data.date_of_birth)
        string+= moment(data.date_of_birth).year();

      if (data.place_of_death)
        string+= ' - ' + data.place_of_death + ', ';

      if (data.date_of_death)
        string+= moment(data.date_of_death).year();


      string+= ')';
    }

    return string;
  }

  changeImageSource(imageTag) {
    imageTag.src = 'assets/img/placeholder-img.jpg';
  }

  getUnit(unit) {
    if (this.language === 'ita') {
      if (unit === 'overall') {
        return 'tot.';
      } else if (unit === 'each') {
        return 'cad.';
      } else {
        return '';
      }
    }

    if (this.language === 'eng') {
      if (unit === 'overall') {
        return 'overall';
      } else if (unit === 'each') {
        return 'each';
      } else {
        return '';
      }
    }

    if (this.language === 'both') {
      if (unit === 'overall') {
        return 'tot./overall';
      } else if (unit === 'each') {
        return 'cad./each';
      } else {
        return '';
      }
    }
    return '';
  }

}
