import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-bar',

  template: `
    <div class="topbar-container">
      <ng-content></ng-content>
    </div>
  `,


  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
