export class ContactGroup {

  id?: number;
  name?: string;
  status?: number;

  constructor(data: Partial<ContactGroup> = {}) {
    Object.assign(this, data);
  }

}
