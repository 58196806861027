import {Injectable} from '@angular/core';
import {ResourceService} from '@services';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class SearchService extends ResourceService<Search> {

  API_PATH = 'global_search';
  MODEL = Search ;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  doSearch(str: string){
    return this.httpClient.get(environment.apiBaseUrl + '/' + this.API_PATH, {responseType: 'json', params:{str} });
  }
}

export class Search {

  id?: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }

}

