import {ContactGroup} from './contact-group';
import {Exhibition, ProjectRoom, Publication, Work, WorkFolder} from '@models';

export class Contact {

  id?: number;
  name?: string;
  lastname?: string;
  company?: string;
  denomination?: string;
  fiscal_code?: string;
  p_iva?: string;
  date_of_birth?: string;
  place_of_birth?: string;
  date_of_death?: string;
  place_of_death?: string;
  birthday_alert?: boolean;
  note?: string;
  sdi?: string;
  pec?: string;
  newsletter_flag?: number;
  phones?: Phone[] = [];
  emails?: Email[] = [];
  sites?: Site[] = [];
  addresses?: Address[] = [];
  group_ids?: number[] = [];
  groups?: ContactGroup[] = [];
  works?: Work[] = [];
  publications?: Publication[] = [];
  publicationsAsCurator?: Publication[] = [];
  publicationsAsAuthor?: Publication[] = [];
  exhibitions?: Exhibition[] = [];
  exhibitionsAsCurator?: Exhibition[] = [];
  projectRooms?: ProjectRoom[] = [];
  projectRoomAsCurator?: ProjectRoom[] = [];
  type?: any;
  purchased_works?: Work[] = [];
  documents?: any[] = [];
  artist_description?: string;
  artist_description_eng?: string;
  document_pipeline?:string;

  works_pipeline?: string;
  installation_views?: Cover[] = [];
  folders?: WorkFolder[] = [];
  status?: number;
  main_cover_image?: string;

  constructor(contact: Partial<Contact> = {}) {
    Object.assign(this, contact);

    this.type = (this.type === 'internal');

    if (this.installation_views.length == 0) {
      let newImage = new Cover();
      this.installation_views.push(newImage);
    } else if (this.installation_views.length > 0) {
      this.installation_views = this.installation_views.map(image => {
        return new Cover(image);
      });
    }

    if (contact.emails) {
      if (contact.emails.length || !contact.newsletter_flag) {
        contact.emails.forEach(email => {
          if (email.newsletter_flag) {
            contact.newsletter_flag = 1
          }
        });
      }
    }

    if (contact.addresses) {
      contact.addresses = contact.addresses.map( addr => {
        addr.address = parseNewLineCharacter(addr.address);
        addr.description = parseSpecialICloudCharacters(addr.description);
        return addr
      })
    }

    if (contact.emails) {
      contact.emails = contact.emails.map( x => {
        x.description = parseSpecialICloudCharacters(x.description);
        return x
      })
    }

    if (contact.phones) {
      contact.phones = contact.phones.map( x => {
        x.description = parseSpecialICloudCharacters(x.description);
        return x
      })
    }

    if (contact.sites) {
      contact.sites = contact.sites.map( x => {
        x.description = parseSpecialICloudCharacters(x.description);
        return x
      })
    }

  }
}

function parseNewLineCharacter(string) {
  if (!string) {
    return null;
  }
  let newString = string.split('\\n').join('\n');
  newString = newString.split('\,').join(',');
  newString = newString.split('\\,').join(',');
  newString = newString.split('\\').join('');
  newString = newString.split('\n').join(' ');

  return newString;
}

function parseSpecialICloudCharacters(string) {
  if (!string) {
    return null;
  }
  let newString = string.split('_$!<').join('');
  newString = newString.split('>!$_').join('');
  return newString;
}



export class Phone {
  id?: number;
  type?: string;
  value?: string;
  description?: string;

  constructor(phone: Partial<Phone> = {}) {
    Object.assign(this, phone);
  }
}

export class Address {

  id?: number;
  address?: string;
  description?: string;
  zip?: number;
  country?: string;
  province?: string;
  city?: string;

  constructor(address: Partial<Address> = {}) {
    Object.assign(this, address);
  }
}

export class Site {

  id?: number;
  value?: string;
  oldValue?: string;
  description?: string;

  constructor(item: Partial<Site> = {}) {
    Object.assign(this, item);
  }
}

export class Email {

  id?: number;
  value?: string;
  oldValue?: string;
  description?: string;
  newsletter_flag?: number;

  constructor(email: Partial<Email> = {}) {
    Object.assign(this, email);
  }
}

export class ContactLight {

  id?: number;
  name?: string;
  lastname?: string;
  denomination?: string;

  constructor(contactLight: Partial<ContactLight> = {}) {
    Object.assign(this, contactLight);
  }

}

class Cover {
  id?: number;
  name?: string;
  is_principal?: any;
  HR?: any;
  type?: string;
  photographer_code?: string;
  photographer_id?: number;
  photographer: ContactLight;
  note: string;
  path?: string;
  order_number?: number;
  file?: any;
  contact_id?: number;

  metadata?: {
    data_updated?: boolean;
    file_changed?: boolean;
    checked_for_portfolio?: boolean;
    loading?: boolean;
    size_error?: boolean;
    has_error?: boolean;
    err_message?: string;
    succeeded?: boolean;
  };

  constructor(cover: Partial<Cover> = {}) {
    Object.assign(this, cover);

    this.is_principal = !!cover.is_principal;
    this.HR = !!cover.HR;

    if (cover && cover.metadata)
      this.metadata.loading = false;

    if (!cover.metadata)
      this.metadata = {};
  }
}
