<section>
  <div class="dialog-header">
    <h3>Aggiungi Nuovo Video</h3>
  </div>
  <div class="dialog-content">

    <div fxLayout="column">
      <div fxFlex="100">
          <mat-form-field  fxFlex="90">
            <mat-label>Descrizione (ITA)</mat-label>
            <input matInput [(ngModel)]="video.description">
          </mat-form-field>
      </div>

      <div fxFlex="100">
        <mat-form-field  fxFlex="90">
          <mat-label>Descrizione (ENG)</mat-label>
          <input matInput [(ngModel)]="video.description_eng">
        </mat-form-field>
      </div>


      <div fxFlex="100">
        <mat-form-field fxFlex="90">
          <mat-label>Link</mat-label>
          <input matInput [(ngModel)]="video.link">
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="dialog footer">
    <div class="button-container">
      <button mat-flat-button class="btn" (click)="abort()" tabindex="2">ANNULLA</button>
      <button mat-flat-button [disabled]="!video.description || !video.link" class="btn" (click)="save()">SALVA</button>
    </div>
  </div>
</section>
