<div class="confirm-dialog">

  <div>
    <div class="dialog-content" fxLayout="column" fxLayoutAlign="center center">
      <div class="warning-icon">
        <mat-icon>error</mat-icon>
      </div>
      <h1 class="mat-headline">{{ data.title }}</h1>
      <h4 class="mat-h4" *ngIf="data.contentText">{{ data.contentText }}</h4>
    </div>
    <div class="dialog-footer" fxLayoutAlign="end center">
      <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
        <button mat-raised-button class="cancel-btn" (click)="abort()" tabindex="2"> {{ data.cancelButtonText || 'Annulla' }}</button>
        <button mat-raised-button class="ok-btn" (click)="done()">{{ data.okButtonText}} </button>
      </div>
    </div>
  </div>

</div>
