
export class ExhibitionLight {

  id?: number;
  title?: string;


  constructor(exhibitionLight: Partial<ExhibitionLight> = {}) {
    Object.assign(this, exhibitionLight);
  }

}
