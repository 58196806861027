import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService, LocalStorageService} from '@services';
import {ToastService} from '../components/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService,
              private toastService: ToastService,
              private localStorageService: LocalStorageService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const role = this.localStorageService.getRole();
    if (role === 'admin')
      return true;

    this.toastService.error('Non hai i permessi necessari per accedere a questa sezione');
    this.router.navigate([`admin/fairs`] );
  }

  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
