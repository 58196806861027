import {Contact, Exhibition, Work, Document, ContactLight} from '..';

export class Publication {
  id?: number;
  owner_type?:string;
  title?: string;
  year?: number;
  type?: string;
  release?: string;
  category?: string;
  city?: string;
  publisher?: string;
  exhibition_id?: number;
  exhibition?: Exhibition;
  installation_views?: Cover[] = [];
  authors?: Contact[] = [];
  author_ids?: number[] = [];
  many?: boolean;
  artists?: Contact[] = [];
  artist_ids?: number[] = [];
  note_ita?: string;
  note_eng?: string;
  price?: number;
  curators?: Contact[] = [];
  curator_ids?: number[] = [];
  works?:  Work[] = [];
  work_ids?: number[] = [];
  documents?: Document[] = [];
  visible_on_site?: number;
  document_pipeline?:string;

  constructor(publication: Partial<Publication> = {}) {
    Object.assign(this, publication);

    if (this.installation_views.length == 0) {
      let newImage = new Cover();
      // newImage.is_principal = true;
      this.installation_views.push(newImage);
    }
    else if(this.installation_views.length > 0){
      this.installation_views = this.installation_views.map(image => {return new Cover(image)});
    }
  }
}


class Cover {
  id?: number;
  name?: string;
  is_principal?: any;
  HR?: any;
  type?: string;
  photographer_code?: string;
  photographer_id?: number;
  photographer: ContactLight;
  note: string;
  path?: string;
  order_number?: number;
  file?: any;
  publication_id?: number;

  metadata?: {
    data_updated?: boolean;
    file_changed?: boolean;
    checked_for_portfolio?: boolean;
    loading?: boolean;
    size_error?: boolean;
    has_error?: boolean;
    err_message?: string;
    succeeded?: boolean;
  };

  constructor(cover: Partial<Cover> = {}) {
    Object.assign(this, cover);

    this.is_principal = !!cover.is_principal;
    this.HR = !!cover.HR;

    if(cover && cover.metadata)
      this.metadata.loading = false;

    if(!cover.metadata)
      this.metadata = {};
  }
}
