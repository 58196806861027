import { environment } from '../../../../environments/environment';

export class Auth {
  tokenType: string;
  accessToken: string;
  refreshToken: string;
  expirationTimestamp: number;
  role: string;
  server_url: string;

  constructor(auth) {
    const now = new Date().getTime();
    this.tokenType = auth.type;
    this.accessToken = auth.token;
    this.refreshToken = auth.refreshToken;
    this.expirationTimestamp = now + environment.TOKEN_LIFETIME * 1000;
    this.server_url = auth.server_url;
    this.role = auth.role;
  }


  forStorage() {
    return {
      type: this.tokenType,
      token: this.accessToken,
      refreshToken: this.refreshToken,
      expirationTimestamp: this.expirationTimestamp,
      server_url: this.server_url,
      role: this.role
    };
  }
}
