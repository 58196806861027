<div class="dialog-wrapper" fxLayout="column" (onclose)="abort()">

  <div>
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>

  <div fxFlex fxLayoutAlign="space-between" fxLayout="column">
    <div class="padding-top" fxLayout="column" >

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Titolo" name="title" [(ngModel)]="title" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Curatore" name="curator" [(ngModel)]="curator" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Artista" name="artist" [(ngModel)]="artist" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="row" class="padding-top">
        <mat-form-field>
          <mat-select name="start_date_operator" [(ngModel)]="start_date_operator">
            <mat-option [value]="'>'">maggiore del </mat-option>
            <mat-option [value]="'>='">maggiore o uguale del </mat-option>
            <mat-option [value]="'<'">minore del </mat-option>
            <mat-option [value]="'<='">minore o uguale del </mat-option>
            <mat-option [value]="'='">uguale</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex>
          <input name="start_date" matInput [(ngModel)]="start_date"
                 [matDatepicker]="start_date_picker" placeholder="Data di inizio"/>
          <mat-datepicker-toggle matSuffix [for]="start_date_picker"></mat-datepicker-toggle>
          <mat-datepicker #start_date_picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxLayout="row" class="padding-top">
        <mat-form-field>
          <mat-select name="end_date_operator" [(ngModel)]="end_date_operator">
            <mat-option [value]="'>'">maggiore del </mat-option>
            <mat-option [value]="'>='">maggiore o uguale del </mat-option>
            <mat-option [value]="'<'">minore del </mat-option>
            <mat-option [value]="'<='">minore o uguale del </mat-option>
            <mat-option [value]="'='">uguale</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex>
          <input name="end_date" matInput [(ngModel)]="end_date"
                 [matDatepicker]="end_date_picker" placeholder="Data di fine"/>
          <mat-datepicker-toggle matSuffix [for]="end_date_picker"></mat-datepicker-toggle>
          <mat-datepicker #end_date_picker></mat-datepicker>
        </mat-form-field>
      </div>

    </div>

    <div class="dialog-footer" fxLayoutAlign="end center">
      <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
        <button mat-flat-button class="btn" (click)="abort()"> {{ data.cancelButtonText }}</button>
        <button mat-flat-button class="btn" (click)="search()"> {{ data.okButtonText }}</button>
      </div>
    </div>
  </div>

</div>
