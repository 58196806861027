<div fxLayout="row wrap" class="gallery-container">
  <mat-card class="mat-elevation-z2" *ngFor="let image of images; let i = index">

    <mat-card-content fxFlex="100" fxLayout="column" fxLayoutAlign="space-between start">

      <div class="image-card-container" fxLayoutAlign="center center">
        <img mat-card-image #imageTag (click)="openItem(i)" [src]="image?.data?.thumb"
             (error)="changeImageSource(imageTag)">
      </div>

      <div class="image-caption" fxLayout="column" fxLayoutGap="4px">
        <span> {{image?.data?.photographer ? getName(image?.data?.photographer) : ''}} </span>
        <span>{{image?.data?.photographer_code}}</span>
        <span>{{image?.data?.note}}</span>
      </div>

      <div fxFlexAlign="end" fxLayoutGap="10px" class="icon_section">
        <div class="original">
          <mat-icon style="cursor: pointer;" matTooltip="Foto originale"
                    (click)="seeOriginPhoto(image?.data?.original_path, image?.data?.work_title)">
            photo
          </mat-icon>
        </div>
      </div>

    </mat-card-content>
  </mat-card>

  <div fxFlex="100" fxLayoutAlign="center center" *ngIf="!images.length">
    <p>Nessuna immagine trovata</p>
  </div>
</div>
