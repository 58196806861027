<div class="confirm-dialog">

  <div>
    <div class="dialog-content" fxLayout="column" fxLayoutAlign="center center">

      <h1 class="mat-headline">{{data.title}}</h1>
      <h4 class="mat-h4" *ngIf="data.contentText">{{ data.contentText }}</h4>
    </div>
    <mat-card>
      <div fxFlex="25">
        <mat-form-field>
          <input  name="newGroupName" [(ngModel)]="newGroupName" (ngModelChange)="changeNewGroupName()" matInput placeholder="NOME NUOVO GRUPPO" type="text">
        </mat-form-field>

        <mat-form-field>
          <mat-select name="groupSelected" [(ngModel)]="groupSelected" (ngModelChange)="changeGroupSelected()" [compareWith]="compareObjects">
            <mat-option value="null">--</mat-option>
            <mat-option *ngFor="let group of groups" [value]="group">{{group.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>
    <div class="dialog-footer" fxLayoutAlign="end center">
      <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
        <button mat-raised-button class="cancel-btn" (click)="abort()" tabindex="2"> {{ data.cancelButtonText }}</button>
        <button mat-raised-button class="ok-btn" (click)="save()" [ngClass]="{'disabled': newGroupName === '' && !groupSelected }"
                [disabled]="newGroupName === '' && !groupSelected">{{ data.okButtonText}} </button>
      </div>
    </div>
  </div>

</div>
