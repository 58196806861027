<div *ngFor="let item of imagesForLabels; let index = index; let last = last" class="page"
     [ngClass]="{'break_before': index > 0, 'break_after': !last}">

  <div style="display: flex; justify-content: center">
    <img style="max-height: 160mm; max-width: 160mm" src="{{item?.cover?.path}}">
  </div>

  <div class="text_section">
    <div>
      <span *ngFor="let author of item?.authors"> {{getName(author) + ', '}}</span>
      <span><i>{{item?.title}}</i>, {{item?.year}}</span>
    </div>
    <span>{{item?.technique_ita}} {{item?.technique_eng ? ' / ' : ''}} {{item?.technique_eng}}</span>
    <span *ngIf="item?.dimensions?.height || item?.dimensions?.width || item?.dimensions?.depth">
      {{item?.dimensions?.height || item?.dimensions?.width ? (selectedOption === 'cm' ? 'cm' : 'inches') : ''}}
      {{item?.dimensions?.height | toInch:selectedOption}}
      x
      {{item?.dimensions?.width | toInch:selectedOption}}
      {{item?.dimensions?.depth ? 'x ' + (item?.dimensions?.depth | toInch:selectedOption) : ''}}

      {{item?.dimensions?.height || item?.dimensions?.width ? getUnit(item?.dimensions?.overall_unit) : ''}}
    </span>

    <span
      *ngIf="item?.dimensions?.height_additional || item?.dimensions?.width_additional || item?.dimensions?.depth_additional">
      {{item?.dimensions?.height_additional || item?.dimensions?.width_additional ?
      (selectedOption === 'cm' ? 'cm' : 'inches') : ''}}
      {{item?.dimensions?.height_additional | toInch:selectedOption}}
      x
      {{item?.dimensions?.width_additional | toInch:selectedOption}}
      {{item?.dimensions?.depth_additional ? 'x ' + (item?.dimensions?.depth_additional | toInch:selectedOption) : ''}}

      {{item?.dimensions?.height_additional || item?.dimensions?.width_additional ?
      getUnit(item?.dimensions?.overall_unit_additional) : ''}}
    </span>


    <span *ngIf="item?.dimensions?.work_duration">{{item?.dimensions?.work_duration}}</span>

    <span>{{item?.edition}}</span>

    <span>{{item?.code}}</span>


  </div>
</div>
<div *ngIf="!imagesForLabels.length" fxLayoutAlign="center center" style="margin-top: 30px">
  Nessuna opera selezionata
</div>
