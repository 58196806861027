<div class="page_certificate">
  <div fxLayout="column" >
      <div class="header" fxLayout="row">
        <span fxFlex="100">Scheda opera</span>
        <span fxFlex="100">Work sheet</span>
        <span class="logo" fxFlex="100"><img alt="p420" class="image" src="/assets/img/logo.svg"></span>
      </div>

      <div style="display: flex; justify-content: center" class="img-certificate-container">
        <img *ngIf="item?.cover?.path !== '' && item?.cover?.path != null" #imageTag
             src="{{item?.cover?.path}}" (error)="changeImageSource(imageTag)">
      </div>

      <div class="text-section-container" fxLayoutAlign="start">

        <div fxFlex class="left-text text-section">
          <div class="cell">
            <div class="p">Artista / Artist</div>
            <div class="p bold" *ngFor="let author of item.authors">{{getAuthorData(author)}} </div>
          </div>

          <div class="cell">
            <div class="p">Titolo / Title</div>
            <div class="p bold">{{item.title}}</div>
          </div>

          <div class="cell" *ngIf="item.year">
            <div class="p">Anno / Year</div>
            <div class="p bold">{{item.year}}</div>
          </div>
        </div>

        <div fxFlex class="center-text text-section">
          <div class="cell" *ngIf="(item.technique_ita || item.technique_eng)">
            <div class="p">Tecnica / Technique</div>
            <div class="p bold">{{item.technique_ita}} {{item.technique_eng ? '/ ' + item.technique_eng : ''}}</div>
          </div>

          <div class="cell" *ngIf="(item?.dimensions?.height || item?.dimensions?.width
          || item?.dimensions?.depth || item?.dimensions?.variable_dimensions)">
            <div class="p">Dimensioni / Dimensions</div>

            <div *ngIf="item?.dimensions?.variable_dimensions" class="bold p">Dimensioni variabili</div>

            <div *ngIf="(item?.dimensions?.height || item?.dimensions?.width || item?.dimensions?.depth) && !item?.dimensions?.variable_dimensions"
                 class="bold p">
              {{item?.dimensions?.height | toInch:selectedOption}}
              x
              {{item?.dimensions?.width | toInch:selectedOption}}
              {{item?.dimensions?.depth ? 'x ' + (item?.dimensions?.depth | toInch:selectedOption) : '' }}
              {{selectedOption === 'cm' ? 'cm' : 'inches'}}

              <span *ngIf="(item?.dimensions?.height || item?.dimensions?.width)
                 && (item?.dimensions?.overall_unit !== '--')">{{getUnit(item?.dimensions?.overall_unit)}}</span>
            </div>

            <div *ngIf="item?.dimensions?.height_additional || item?.dimensions?.width_additional || item?.dimensions?.depth_additional;"
                 class="bold p">
              {{item?.dimensions?.height_additional | toInch:selectedOption}}
              x
              {{item?.dimensions?.width_additional | toInch:selectedOption}}
              {{item?.dimensions?.depth_additional ? 'x ' + (item?.dimensions?.depth_additional | toInch:selectedOption) : '' }}
              {{selectedOption === 'cm' ? 'cm' : 'inches'}}

              <span *ngIf="(item?.dimensions?.height_additional || item?.dimensions?.width_additional)
                 && (item?.dimensions?.overall_unit_additional !== '--')">{{getUnit(item?.dimensions?.overall_unit_additional)}}</span>
            </div>
          </div>

          <div class="cell" *ngIf="item?.dimensions?.work_duration">
            <div class="p">Durata / Duration</div>
            <div class="p bold">{{item?.dimensions?.work_duration}}</div>
          </div>

          <div class="cell" *ngIf="item.edition">
            <div class="p">Edizione / Edition</div>
            <div class="p bold">{{item.edition}}</div>
          </div>

          <div class="cell" *ngIf="item.origin_ita || item.origin_eng">
            <div class="p">Provenienza / Provenance</div>
            <div class="p bold">{{item.origin_ita}} {{item.origin_eng ? '/ ' + item.origin_eng : ''}}</div>

          </div>

        </div>

        <div fxFlex class="right-text text-section">
          <div class="cell" *ngIf="item.exhibitions.length">
            <div class="p">Esposizioni / Exhibitions</div>
            <div class="p bold margin-bottom_3px" [innerHTML]="exhibition"  *ngFor="let exhibition of exhibitionTextArray">
            </div>
          </div>

          <div class="cell" *ngIf="item.publications.length && exhibitionText.length <= limitCharactersForFirstPage
          && (exhibitionText.length + publicationsText.length <= limitCharactersForFirstPage)">
            <div class="p">Pubblicazione / Publications </div>
            <div class="p bold margin-bottom_3px" [innerHTML]="publication" *ngFor="let publication of publicationsTextArray"></div>
          </div>

          <div class="cell" *ngIf="item.price.price_no_vat && exhibitionText.length <= limitCharactersForFirstPage
          && (exhibitionText.length + publicationsText.length <= limitCharactersForFirstPage) ">
            <div class="p">Prezzo / Price</div>
            <div class="p bold">{{item.price.price_no_vat + ' (Iva Esclusa)'}}</div>
          </div>

        </div>

      </div>

    </div>
</div>

<div class="page_certificate full-height break_before" fxLayout="column" fxLayoutAlign="space-between"
     *ngIf="exhibitionTextExtraArray.length > 0 || publicationsTextExtraArray.length > 0
     || exhibitionText.length > 600 ||
     exhibitionText.length + publicationsText.length > 600 ||

((item?.note_pdf_ita || item?.note_pdf_eng) &&
     (totalTextPublications.length + totalTextExhibitions.length < 1500))">

  <div  fxLayout="column" fxLayoutAlign="start">
    <div class="text-section-container-extra-no-height" fxLayoutAlign="start">

      <div fxFlex class="left-text text-section">
      </div>

      <div fxFlex class="center-text text-section">
      </div>

      <div fxFlex class="right-text text-section">
        <div class="cell" *ngIf="exhibitionTextExtraArray.length">
          <div class="p bold margin-bottom_3px" [innerHTML]="exhibition"  *ngFor="let exhibition of exhibitionTextExtraArray"></div>
        </div>

        <div class="cell" *ngIf="(publicationsTextArray.length || publicationsTextExtraArray.length) && (exhibitionText.length > limitCharactersForFirstPage
       || (exhibitionText.length + publicationsText.length > limitCharactersForFirstPage))">
          <div class="p">Pubblicazione / Publications </div>
          <div class="p bold margin-bottom_3px" [innerHTML]="publication" *ngFor="let publication of publicationsTextArray"></div>
          <div class="p bold margin-bottom_3px" [innerHTML]="publication" *ngFor="let publication of publicationsTextExtraArray"></div>
        </div>

        <div class="cell" *ngIf="publicationsTextExtraArray.length && !(exhibitionText.length > limitCharactersForFirstPage
       || (exhibitionText.length + publicationsText.length > limitCharactersForFirstPage))">
          <div class="p bold margin-bottom_3px" [innerHTML]="publication" *ngFor="let publication of publicationsTextExtraArray"></div>
        </div>

        <div class="cell" *ngIf="item.price.price_no_vat && (exhibitionText.length >= limitCharactersForFirstPage ||
           (exhibitionText.length + publicationsText.length >= limitCharactersForFirstPage)) ">
          <div class="p">Prezzo / Price</div>
          <div class="p bold">{{item.price.price_no_vat + ' (Iva Esclusa)'}}</div>
        </div>

      </div>

    </div>

    <div class="text-section-container-extra-no-height margin-top-20" fxLayoutAlign="start">

      <div fxFlex class="left-text "></div>
      <div fxFlex class="center-text "></div>

      <div fxFlex *ngIf="(item?.note_pdf_ita || item?.note_pdf_eng)">
        <div class="note-text" fxLayout="column"  >
          <div class="header" fxLayout="row" fxFlex="5">
            <p><b>Note:</b></p>
          </div>

          <div class="note_text">
            <div [innerHTML]="replaceBreakLineWithBrTag(item?.note_pdf_ita)"></div>
            <br *ngIf="item?.note_pdf_ita">
            <div [innerHTML]="replaceBreakLineWithBrTag(item?.note_pdf_eng)"></div>
          </div>
        </div>
      </div>

    </div>
  </div>


  <div class="footer" fxLayoutAlign="end">
    <span class="logo" fxFlex="100"><img alt="p420" class="image" src="/assets/img/logo.svg"></span>
  </div>
</div>


<div class="page_certificate full-height break_before" fxLayout="column" fxLayoutAlign="space-between"
     *ngIf="(item?.note_pdf_ita || item?.note_pdf_eng) &&
     (totalTextPublications.length + totalTextExhibitions.length >= 1500)" >

  <div class="text-section-container-extra" fxLayoutAlign="start">

    <div fxFlex class="left-text text-section">
    </div>

    <div fxFlex class="center-text text-section">
    </div>

    <div fxFlex class=" text-section">
      <div class="note-text note_pdf_page" fxLayout="column" fxLayoutAlign="space-between" >
        <div class="header" fxLayout="row" fxFlex="5">
          <p><b>Note:</b></p>
        </div>

        <div class="note_text">
          {{item?.note_pdf_ita}}
          <br>
          <br>
          {{item?.note_pdf_eng}}
        </div>
      </div>
    </div>

  </div>

  <div class="footer" fxLayoutAlign="end">
    <span class="logo" fxFlex="100"><img alt="p420" class="image" src="/assets/img/logo.svg"></span>
  </div>
</div>
