import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ToastService} from '../../../../common/components/toast/toast.service';
import {WorkFolder} from '@models';
import {WorkFoldersService} from '../../works.service';

@Component({
  selector: 'add-work-to-folder-dialog',
  templateUrl: './add-work-to-folder-dialog.component.html',
  styleUrls: ['./add-work-to-folder-dialog.component.scss']
})
export class AddWorkToFolderDialogComponent {
  ajaxLoading = false;
  newFolderName: string = '';
  folders: WorkFolder[] = [];
  folder;
  authorId;
  workId;
  workIdsSelected;
  folderSelected;

  constructor(
    private toastService: ToastService,
    private workFoldersService: WorkFoldersService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddWorkToFolderDialogComponent>
  ) {}

  ngOnInit() {
    if(this.data) {
      this.authorId = this.data.author_id;
      this.workId = this.data.work_id;
    }

    this.getAuthorFolders(this.authorId);
  }

  abort() {
    this.dialogRef.close(false);
  }

  changeGroupSelected() {
    this.newFolderName = ''
  }

  changeNewFolderName() {
    this.folderSelected = null
  }

  compareObjects(obj1, obj2): boolean {
    if(obj1 && obj2) {
      return obj1.id === obj2.id;
    }
    return false;
  }

  getAuthorFolders(authorId){
    this.workFoldersService.getFoldersByAuthorId(authorId).subscribe( (folders: any) => {
        this.folders = folders;
      },
      () => {})
  }

  save() {
    let data;
    let createdNewGroup = this.newFolderName && this.newFolderName !== '';
    if (createdNewGroup) {
      data = {
        work_id: this.workId,
        author_id: this.authorId,
        folder_name: this.newFolderName
      };
    } else {
      data = {
        work_id: this.workId,
        author_id: this.authorId,
        folder_id: this.folderSelected.id,
      };
    }

    this.ajaxLoading = true;
      this.workFoldersService.assignWorkToFolder(data).subscribe(res => {
          this.ajaxLoading = false;
          this.toastService.success('Opere aggiunte al gruppo con successo');
        if (createdNewGroup) {
          this.dialogRef.close(res);
        } else {
          this.dialogRef.close(res);
        }
        },
        () => {
          this.ajaxLoading = false;
          this.toastService.error('Si è verificato un problema');
        })
  }

}
