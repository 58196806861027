import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Gallery, GalleryItem, ImageSize, ThumbnailsPosition} from 'ng-gallery';
import {Subscription} from 'rxjs';
import {Lightbox} from 'ng-gallery/lightbox';
import {DialogService} from '../../dialog/dialog.service';

@Component({
  selector: 'app-main-cover-image',
  templateUrl: './main-cover-image.component.html',
  styleUrls: ['./main-cover-image.component.scss']
})
export class MainCoverImageComponent implements OnInit, OnChanges, OnDestroy {

  @Input() item: any;
  @Input() imagePath: string;
  @Input() servicePath: string;
  images: GalleryItem[] = [];
  galleryImages: GalleryItem[] = [];
  navigationSubscription: Subscription;

  constructor(
    private gallery: Gallery,
    private lightbox: Lightbox,
    private dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this.initImages()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initImages();
  }

  initImages() {
    var image = {
      data: {
        src: this.imagePath,
        thumb: this.imagePath,
        thumbnail: this.imagePath,
        original_path: this.imagePath,
      }
    };
    this.images = [image];
  }



  loadImages(index) {
    this.galleryImages = [];
    this.galleryImages = [...this.images];
    this.resetAndLoad();
  }

  resetAndLoad() {
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
    });
    lightboxGalleryRef.reset();
    lightboxGalleryRef.load(this.galleryImages);
  }

  openDialog() {
    const config = {
      data: {
        apiPath: this.servicePath,
        item_id: this.item.id
      }
    };
    this.dialogService
      .uploadImage(config)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.imagePath = result.main_cover_image;
          this.initImages();
        }
      });
  }


  openItem(index) {
    this.loadImages(index);
    this.lightbox.open(index, 'anotherLightbox');
  }

  changeImageSource(imageTag) {
    imageTag.src = 'assets/img/placeholder-img.jpg';
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription)
      this.navigationSubscription.unsubscribe();
  }

}
