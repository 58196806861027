import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs/Observable';
import {ConfirmComponent} from './confirm/confirm.component';
import {AddGroupDialogComponent} from '../../modules/contacts/components/add-group-dialog/add-group-dialog.component';
import {AddWorkGroupDialogComponent} from '../../modules/works/components/add-work-group-dialog/add-work-group-dialog.component';
import {AddWorksToGroupDialogComponent} from '../../modules/works/components/add-works-to-group-dialog/add-works-to-group-dialog.component';
import {SettingsModalComponent} from '../components/settings-modal/settings-modal.component';
import {UploadFileComponent} from './upload-file/upload-file.component';
import {AddContactsToGroupDialogComponent} from '../../modules/contacts/components/add-contacts-to-group-dialog/add-contacts-to-group-dialog.component';
import {SettingsLabelModalComponent} from '../components/settings-label-modal/settings-label-modal.component';
import {SelectedImagesModalComponent} from '../components/selected-images-modal/selected-images-modal.component';
import {ExhibitionsSearchComponent} from '../../modules/exhibitions/components/exhibitions-search/exhibitions-search.component';
import {PublicationsSearchComponent} from '../../modules/publications/components/publications-search/publications-search.component';
import {FairsSearchComponent} from '../../modules/fairs/components/fairs-search/fairs-search.component';
import {WorksSortingModalComponent} from '../components/works-sorting-modal/works-sorting-modal.component';
import {AddVideoComponent} from './add-video/add-video.component';
import {WorksSearchComponent} from '../../modules/works/components/works-search/works-search.component';
import {SearchAndAddWorksToGroupDialogComponent} from '../../modules/works/components/search-and-add-works-to-group-dialog/search-and-add-works-to-group-dialog.component';
import {ProjectRoomsSearchComponent} from '../../modules/project-rooms/components/project_rooms-search/project-rooms-search.component';
import {OrderItemsDialogComponent} from '../components/order-items/order-items-dialog.component';
import {InstallationViewsSortingModalComponent} from '../components/installation-views-sorting-modal/installation-views-sorting-modal.component';
import {ContactsSearchComponent} from '../../modules/works/components/contacts-search/contacts-search.component';
import {DocumentSortingModalComponent} from '../components/document-sorting-modal/document-sorting-modal.component';
import {PersonSearchComponent} from '../../modules/contacts/components/person-search/person-search.component';
import {AddWorkToFolderDialogComponent} from '../../modules/works/components/add-work-to-folder-dialog/add-work-to-folder-dialog.component';
import {UploadImageComponent} from './upload-image/upload-image.component';

export interface IConfirmData {
  title?: string;
  contentText?: string;
  type?: string;
  okButtonText?: string;
  cancelButtonText?: string;
  apiPath?: string;
  item?: any;
  language?: string
  callback?: {
    args: any[];
    function(args?): Observable<any>;
  };
}

export interface Iconfig extends MatDialogConfig {
  data?: IConfirmData;
}

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}

  confirm(config?: Iconfig) {
    return this.dialog.open(ConfirmComponent, config);
  }

  addGroupModal(config?: Iconfig) {
    return this.dialog.open(AddGroupDialogComponent, config);
  }

  addWorkGroupModal(config?: Iconfig) {
    return this.dialog.open(AddWorkGroupDialogComponent, config);
  }

  addWorksToGroupModal(config?: Iconfig) {
    return this.dialog.open(AddWorksToGroupDialogComponent, config);
  }

  addWorkToFolderModal(config?: Iconfig) {
    return this.dialog.open(AddWorkToFolderDialogComponent, config);
  }

  searchAndAddWorksToGroup(config?: Iconfig) {
    return this.dialog.open(SearchAndAddWorksToGroupDialogComponent, config);
  }

  addContactsToGroupModal(config?: Iconfig) {
    return this.dialog.open(AddContactsToGroupDialogComponent, config);
  }

  settingsModal(config?: Iconfig) {
    return this.dialog.open(SettingsModalComponent, config);
  }

  labelModal(config?: Iconfig) {
    return this.dialog.open(SettingsLabelModalComponent, config);
  }

  worksSortingModal(config?: Iconfig) {
    return this.dialog.open(WorksSortingModalComponent, config);
  }

  documentSortingModal(config?: Iconfig) {
    return this.dialog.open(DocumentSortingModalComponent, config);
  }

  installationViewsSortingModal(config?: Iconfig) {
    return this.dialog.open(InstallationViewsSortingModalComponent, config);
  }

  uploadDocument(config?: Iconfig) {
    config = config || {};
    return this.dialog.open(UploadFileComponent, config);
  }

  uploadImage(config?: Iconfig) {
    config = config || {};
    return this.dialog.open(UploadImageComponent, config);
  }

  addVideo(config?: Iconfig) {
    config = config || {};
    return this.dialog.open(AddVideoComponent, config);
  }

  selectedImagesModal(config?: Iconfig) {
    return this.dialog.open(SelectedImagesModalComponent, config);
  }

  advancedSearchExhibitions(config?: Iconfig) {
    return this.dialog.open(ExhibitionsSearchComponent, config);
  }

  advancedSearchProjectRooms(config?: Iconfig) {
    return this.dialog.open(ProjectRoomsSearchComponent, config);
  }

  advancedSearchPublications(config?: Iconfig) {
    return this.dialog.open(PublicationsSearchComponent, config);
  }

  advancedSearchFairs(config?: Iconfig) {
    return this.dialog.open(FairsSearchComponent, config);
  }

  advancedSearchWorks(config?: Iconfig) {
    return this.dialog.open(WorksSearchComponent, config);
  }

  advancedSearchContacts(config?: Iconfig) {
    return this.dialog.open(ContactsSearchComponent, config);
  }

  advancedSearchPerson(config?: Iconfig) {
    return this.dialog.open(PersonSearchComponent, config);
  }

  openOrderItemsModal(config?: Iconfig) {
    return this.dialog.open(OrderItemsDialogComponent, config);
  }
}
