import { BrowserModule } from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {SharedModule} from './common/shared.module';
import {ToastComponent} from './common/components/toast/toast.component';
import {ToastService} from './common/components/toast/toast.service';
import {NavigationService} from '@services';
import {HttpClientModule} from '@angular/common/http';
import {WorkFoldersService, WorkGroupsService, WorksService} from './modules/works/works.service';
import {ContactGroupService} from './modules/contacts/contact.service';
import {SettingsModalComponent} from './common/components/settings-modal/settings-modal.component';
import {SearchService} from './common/services/search/search.service';
import {SettingsLabelModalComponent} from './common/components/settings-label-modal/settings-label-modal.component';
import {WorksSortingModalComponent} from './common/components/works-sorting-modal/works-sorting-modal.component';
import {InstallationViewsSortingModalComponent} from './common/components/installation-views-sorting-modal/installation-views-sorting-modal.component';


@NgModule({
    declarations: [
        AppComponent,
        ToastComponent,
        SettingsModalComponent,
        SettingsLabelModalComponent,
        WorksSortingModalComponent,
        InstallationViewsSortingModalComponent
    ],
    imports: [
        HttpClientModule,
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
    ],
    providers: [
      ToastService,
      NavigationService,
      WorkGroupsService,
      WorkFoldersService,
      ContactGroupService,
      SearchService,
      WorksService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

  static injector: Injector;

  constructor(private injectorService: Injector){
    AppModule.injector = injectorService;
  }

}
