import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   centimeter | toInch:unit
 * Example:
 *   {{ 40 | toInch:'inch'}}
 *   formats to: 122 inches
*/
@Pipe({
  name: 'toInch'
})

export class ToInchPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) localeId) {

  }

  transform(value: number = 0, unit: string): string {

    if (isNaN(parseFloat(String(value))) || !isFinite(value)) { return '?'; }

    if (unit == 'cm')
      return value.toString();

    const inchesWithDecimal = value / 2.54;
    const integerPartOfInches = Math.floor(inchesWithDecimal);
    const decimalPartOfInches = inchesWithDecimal - integerPartOfInches;

    if (decimalPartOfInches == 0)
      return integerPartOfInches.toString();

    let baseFraction = 64;
    let fractionOfInches = decimalPartOfInches * baseFraction;

    fractionOfInches = Math.round(fractionOfInches);

    while ((fractionOfInches % 2 === 0) && baseFraction > 2) {
      fractionOfInches = fractionOfInches / 2;
      baseFraction = baseFraction / 2;
    }

    return integerPartOfInches + ' ' + fractionOfInches + '/' + baseFraction;
  }

}
