<section>
  <div class="dialog-header">
    <h3>Carica Nuovo File</h3>
  </div>
  <div class="dialog-content">

    <div class="upload-drop-zone">
        <div fxLayoutAlign="center center" >
          <input type="file" class="input-file" #fileUploader accept="application/msword,
           application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.openxmlformats-officedocument.wordprocessingml.template,
             application/vnd.ms-word.document.macroEnabled.12,
              application/vnd.ms-word.template.macroEnabled.12,
              application/vnd.ms-excel,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
              application/vnd.openxmlformats-officedocument.spreadsheetml.template,
              application/vnd.ms-excel.sheet.macroEnabled.12,
              application/vnd.ms-excel.template.macroEnabled.12,
              application/vnd.ms-excel.addin.macroEnabled.12,
              application/vnd.ms-excel.sheet.binary.macroEnabled.12,
              application/vnd.ms-powerpoint,
              application/vnd.openxmlformats-officedocument.presentationml.presentation,
              application/vnd.openxmlformats-officedocument.presentationml.template,
              application/vnd.openxmlformats-officedocument.presentationml.slideshow,
              application/vnd.ms-powerpoint.addin.macroEnabled.12,
              application/vnd.ms-powerpoint.presentation.macroEnabled.12,
              application/vnd.ms-powerpoint.template.macroEnabled.12,
              application/vnd.ms-powerpoint.slideshow.macroEnabled.12,
              application/vnd.ms-access,
              text/plain,
               application/pdf"
                 name="file" (change)="getSelectedFile($event)"/>

          <button mat-button class="upload-file" (click)="fileUploader.click()">
            Carica File
            <mat-icon>file_upload</mat-icon>
          </button>
          <div class="state">
            <mat-progress-spinner *ngIf="loading" color="accent"
                                  mode="indeterminate" diameter="40"></mat-progress-spinner>
            <mat-icon *ngIf="!loading && error" class="err-icon">error_outline</mat-icon>
          </div>
        </div>

      <div fxLayoutAlign="center center" fxLayout="column">
        <div class="image-preview" [style.background-image]="getThumbnail()"></div>
        <div *ngIf="document?.name">{{document.name}}  </div>
        <div *ngIf="error">{{error}} </div>
      </div>

    </div>

  </div>
  <div class="dialog footer">
    <div class="button-container">
      <button mat-flat-button class="btn" (click)="abort()" tabindex="2">ANNULLA</button>
      <button mat-flat-button [disabled]="!document" class="btn" (click)="upLoadToServer()">SALVA</button>
    </div>
  </div>
</section>
