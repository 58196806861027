<div class="confirm-dialog">

  <div>
    <div class="dialog-content" fxLayout="column" fxLayoutAlign="center center">

      <h1 class="mat-headline">{{data.title}}</h1>
      <h4 class="mat-h4" *ngIf="data.contentText">{{ data.contentText }}</h4>
    </div>
    <mat-card>
      <div fxFlex="25">
        <mat-form-field>
          <input  name="name" [(ngModel)]="group.name" matInput placeholder="NOME *" type="text">
        </mat-form-field>

      </div>
    </mat-card>
    <div class="dialog-footer" fxLayoutAlign="end center">
      <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
        <button mat-raised-button class="cancel-btn" (click)="abort()" tabindex="2"> {{ data.cancelButtonText }}</button>
        <button mat-raised-button class="ok-btn" [ngClass]="{'disabled': !group.name}" [disabled]="!group.name"
                (click)="save()">{{ data.okButtonText}} </button>
      </div>
    </div>
  </div>

</div>
