import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Exhibition, Price, Work} from '@models';
import * as moment from 'moment';

@Component({
  selector: 'section-work-sheet',
  templateUrl: './section-work-sheet.component.html',
  styleUrls: ['./section-work-sheet.component.scss']
})
export class SectionWorkSheetComponent implements OnInit, OnChanges {

  @Input() item: Work;
  @Input() selectedOption = 'cm';
  @Input() language = 'ita';
  exhibitionTextExtraArray = []
  exhibitionTextArray = []
  publicationsTextExtraArray = []
  publicationsTextArray = []
  exhibitionText = ""
  exhibitionTextExtra = ""
  publicationsText = ""
  publicationsTextExtra = ""

  totalTextExhibitions = ""
  totalTextPublications = ""

  limitCharactersForFirstPage = 350

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.item.price.price_no_vat) {
        this.limitCharactersForFirstPage = 350
      } else {
        this.limitCharactersForFirstPage = 600
      }

      if (this.item.exhibitions.length) {
        this.item.exhibitions = this.item.exhibitions.map(exhibition => {
          exhibition.year = new Date(exhibition.start_date).getFullYear();
          return exhibition;
        });

        this.item.exhibitions.forEach(item => {
          const text = this.getExhibitionText(item)
          if (this.exhibitionText.length + text.length <= this.limitCharactersForFirstPage) {
            this.exhibitionText += text;
            this.exhibitionTextArray.push(text);
          } else {
            this.exhibitionTextExtra += text;
            this.exhibitionTextExtraArray.push(text);
          }

          this.totalTextExhibitions += text;

        })
      }
      this.item.publications.forEach(item => {
        const text = this.getPublicationText(item)
        this.totalTextPublications += text;

        if (this.item.exhibitions.length) {
          if (this.publicationsText.length + this.exhibitionText.length <= this.limitCharactersForFirstPage) {
            this.publicationsText += text;
            this.publicationsTextArray.push(text);
          } else {
            this.publicationsTextExtra += text;
            this.publicationsTextExtraArray.push(text);
          }
        } else {
          if (this.publicationsText.length + text.length <= this.limitCharactersForFirstPage) {
            this.publicationsText += text;
            this.publicationsTextArray.push(text);
          } else {
            this.publicationsTextExtra += text;
            this.publicationsTextExtraArray.push(text);
          }
        }
      })
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.item)
      return;

    if (this.item.exhibitions.length) {
      this.item.exhibitions = this.item.exhibitions.map(exhibition => {
        exhibition.year = new Date(exhibition.start_date).getFullYear();
        return exhibition;
      });
    }
  }

  getAuthorData(data) {
    if (!data)
      return;

    if (data.denomination)
      return data.denomination;

    let name = '';
    if (data.name)
      name += data.name + ' ';

    if (data.lastname)
      name += data.lastname;

    let string = '';

    if (name)
      string += name;

    if (data.place_of_birth || data.place_of_birth || data.place_of_birth || data.place_of_birth) {
      string += ' (';

      if (data.place_of_birth)
        string += data.place_of_birth + ', ';

      if (data.date_of_birth)
        string += moment(data.date_of_birth).year();

      if (data.place_of_death)
        string += ' - ' + data.place_of_death + ', ';

      if (data.date_of_death)
        string += moment(data.date_of_death).year();


      string += ')';
    }

    return string;
  }

  replaceBreakLineWithBrTag(text) {
    if (!text) return text;
    text = text.split('\n').join('<br>');
    console.log(text)
    return text;
  }

  changeImageSource(imageTag) {
    imageTag.src = 'assets/img/placeholder-img.jpg';
  }

  getUnit(unit) {
    if (this.language === 'ita') {
      if (unit === 'overall') {
        return 'tot.';
      } else if (unit === 'each') {
        return 'cad.';
      } else {
        return '';
      }
    }

    if (this.language === 'eng') {
      if (unit === 'overall') {
        return 'overall';
      } else if (unit === 'each') {
        return 'each';
      } else {
        return '';
      }
    }

    if (this.language === 'both') {
      if (unit === 'overall') {
        return 'tot./overall';
      } else if (unit === 'each') {
        return 'cad./each';
      } else {
        return '';
      }
    }
    return '';
  }

  getExhibitionText(exhibit: Exhibition) {
    let txt = "";

    txt += "<i>" + exhibit.title + "</i>";

    if (exhibit.curators.length > 0) {
      const curator = exhibit.curators[0];
      if (this.language == "eng") {
        txt += ", curated by " ;
      } else {
        txt += ", a cura di ";
      }

      if (curator.denomination) {
        txt += curator.denomination
      } else {
        txt += (curator.name ? curator.name + ' ' : '') + curator.lastname
      }
    }


    if (exhibit.exhibition_venue) {
      if (exhibit.exhibition_venue.denomination) {
        txt += ", " + exhibit.exhibition_venue.denomination;
      } else {
        txt += ", " +  exhibit.exhibition_venue.lastname + (exhibit.exhibition_venue.name ? " " +
          exhibit.exhibition_venue.name : '');
      }

      if (exhibit.exhibition_venue.city) {
        txt += ", " +  exhibit.exhibition_venue.city;
      }
    }

    if (exhibit.start_date) {
      const date = new Date(exhibit.start_date);
      const year = date.getFullYear();
      txt += ", " + year;
    }

    return txt;
  }

  getPublicationText(publication)  {
    let str = "";

    if (publication.authors) {
      if (publication.authors.length) {
        publication.authors.forEach(author => {
          if (author.lastname || author.name) {
            str += author.name + " " + author.lastname + ", ";
          } else {
            str += author.denomination + ", ";
          }
        });
      }
    }

    if (publication.title) {
      str += "<i> " + publication.title + "</i>";
    }

    if (publication.publisher) {
      str += ', ';
      str += publication.publisher;
    }
    if (publication.city) {
      str += ', ';
      str += publication.city;
    }
    if (publication.year) {
      str += ', ';
      str += publication.year;
    }

    return str;
  }
}
