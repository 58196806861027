import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LayoutComponent} from './common/components/app-layout/layout/layout.component';
import {IsLoggedGuard} from './common/guards/is-logged.guard';
import {IsAdminGuard} from './common/guards/is-admin.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [IsLoggedGuard],
    canActivateChild: [IsLoggedGuard],
  },
  {
    path: 'admin',
    pathMatch: 'prefix',
    component: LayoutComponent,
    canActivate: [IsLoggedGuard],
    canActivateChild: [IsLoggedGuard],

    children: [
      {
        path: 'contacts',
        loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule),
        canActivate: [IsAdminGuard],
        canActivateChild: [IsAdminGuard],
      },
      {
        path: 'works',
        loadChildren: () => import('./modules/works/works.module').then(m => m.WorksModule),
        canActivate: [IsAdminGuard],
        canActivateChild: [IsAdminGuard],
      },
      {
        path: 'publications',
        loadChildren: () => import('./modules/publications/publications.module').then(m => m.PublicationsModule),
        canActivate: [IsAdminGuard],
        canActivateChild: [IsAdminGuard],
      },
      {
        path: 'exhibitions',
        loadChildren: () => import('./modules/exhibitions/exhibitions.module').then(m => m.ExhibitionsModule),
        canActivate: [IsAdminGuard],
        canActivateChild: [IsAdminGuard],
      },
      {
        path: 'project_rooms',
        loadChildren: () => import('./modules/project-rooms/project_rooms.module').then(m => m.ProjectRoomsModule),
        canActivate: [IsAdminGuard],
        canActivateChild: [IsAdminGuard],
      },
      {
        path: 'fairs',
        loadChildren: () => import('./modules/fairs/fairs.module').then(m => m.FairsModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('./modules/calendar/calendar.module').then(m => m.CalendarModule),
        canActivate: [IsAdminGuard],
        canActivateChild: [IsAdminGuard],
      },
      {
        path: 'slots',
        loadChildren: () => import('./modules/slots/slots.module').then(m => m.SlotsModule),
        canActivate: [IsAdminGuard],
        canActivateChild: [IsAdminGuard],
      },
      {
        path: 'homepage-covers',
        loadChildren: () => import('./modules/homepage-covers/homepage-cover.module').then(m => m.HomepageCoverModule),
        canActivate: [IsAdminGuard],
        canActivateChild: [IsAdminGuard],
      }
    ]
  },
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
