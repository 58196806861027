import {Component, Inject} from '@angular/core';
import {ToastService} from '../toast/toast.service';
import {LocalStorageService, NavigationService} from '@services';
import {Document} from '@models';
import {findIndex, remove} from 'lodash';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-document-sorting-modal',
  templateUrl: './document-sorting-modal.component.html',
  styleUrls: ['./document-sorting-modal.component.scss']
})

export class DocumentSortingModalComponent {

  documents: Document[] = [];
  oldData;
  documentsPipeline;
  title = 'Scegli nuovo ordine';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DocumentSortingModalComponent>,
    public toastService: ToastService,
    public navigationService: NavigationService,
    public localStorageService: LocalStorageService,
) {

    this.oldData = {...this.data};
    this.documents = [...this.data.item];
    this.documentsPipeline = this.data.pipeline;
    this.applyPipeline();
    this.title = 'Scegli nuovo ordine e deseleziona i documenti da non visualizzare';
  }

  applyPipeline() {
    if (this.documentsPipeline) {
      this.documents.forEach( (doc: any) => {
        doc.selected = false;
      })
    }

    let pipeline;

    if (this.documentsPipeline) {
      pipeline = this.documentsPipeline.split(',');
    } else {
      pipeline = [];
    }

    const selectedDocuments = [];
    const array = [...this.documents];
    pipeline.forEach( id => {
      const docToMove = remove(array, (doc: any) => {
        return doc.id == id;
      });
      if (docToMove.length) {
        docToMove[0].selected = true;
        selectedDocuments.push(docToMove[0]);
      }
    });

    const newArray = selectedDocuments.concat(array);
    this.documents = [...newArray];
  }

  abort() {
    this.documents = [...this.oldData.item];
    this.documentsPipeline = this.data.pipeline;
    this.dialogRef.close(false);
  }

  confirm() {
    const pipeline = [];
    this.documents.forEach( (doc: any) => {
      let isAlreadyPresent = findIndex(pipeline, doc.id);
      if (isAlreadyPresent == -1 && doc.selected)
        pipeline.push(doc.id);
    });
    this.dialogRef.close(pipeline);
  }

  changeImageSource(imageTag) {
    imageTag.src = 'assets/img/placeholder-img.jpg';
  }

  moveDown(index) {
    let oldElement = this.documents[index+1];
    this.documents[index+1] = this.documents[index];
    this.documents[index] = oldElement;
  }

  moveUp(index) {
    let oldElement = this.documents[index-1];
    this.documents[index-1] = this.documents[index];
    this.documents[index] = oldElement;
  }

  selectAll() {
    this.documents = this.documents.map( (doc: any) => {
      doc.selected = true;
      return doc
    })
  }

  deSelectAll() {
    this.documents = this.documents.map( (doc: any) => {
      doc.selected = false;
      return doc
    })
  }

  moveTo(index, newPosition) {
    if (newPosition < 1 || newPosition > this.documents.length) {
      return this.toastService.error('Posizione non valida')
    }

    newPosition--;

    const itemToMove = this.documents[index];
    this.documents.splice(index, 1);
    this.documents.splice(newPosition, 0, itemToMove);
  }
}
