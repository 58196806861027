import {Injectable} from '@angular/core';
import {ResourceService} from '@services';
import {Exhibition, Video} from '@models';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class ExhibitionsService extends ResourceService<Exhibition> {

  API_PATH = 'exhibitions';
  MODEL = Exhibition;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }


  savePipeline(id, pipeline) {
    return this.httpClient.put(environment.apiBaseUrl + '/' + this.API_PATH + '/' + id + '/save_pipeline', pipeline);
  }

  setHomepageCover(data) {
    return this.httpClient.put(environment.apiBaseUrl + '/saveCoverHomepageItems' , data);
  }

}

@Injectable()
export class ExhibitionsVideoService extends ResourceService<Video> {

  API_PATH = 'exhibition_videos';
  MODEL = Video;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }


}
