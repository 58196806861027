import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'p420-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss']
})
export class FilterInputComponent {
  @Input()
  iconName = 'list_view';
  @Input()
  placeholder = 'Filter';
  @Input()
  enableClear = true;
  @Input()
  enableAutocomplete = true;
  @Input()
  modelValue;
  @Output()
  submit = new EventEmitter<string>();
  @Output()
  keyup = new EventEmitter<string>();

  @Input()
  get model(): string {
    return this.modelValue;
  }

  set model(value: string) {
    this.modelValue = value;
  }

  onSubmit(event) {
    event.stopPropagation();
    this.modelValue = this.apostrophe_changer(this.modelValue);
    this.submit.emit(this.modelValue);
  }

  apostrophe_changer(val: string): string {
    // since Apple uses ‘ and ’ instead of ' we need to change them in the search field
    return val.replace(/[\u2018\u2019]/g, "'");
  }

  onKeyup(event){
    event.stopPropagation();
    this.keyup.emit(this.modelValue);
  }

  clear() {
    this.model = undefined;
    this.modelValue = undefined;
    this.submit.emit(this.modelValue);
  }

  autocomplete(): string {
    return this.enableAutocomplete ? 'on' : 'off';
  }
}
