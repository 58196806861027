<div fxLayout="row wrap" class="gallery-container">

  <div fxLayout="row" fxLayoutAlign="space-between start" class="width100">
    <h4 class="margin-bottom">Cover principale (sito)</h4>
    <div fxLayoutAlign="center center" class="height100">
      <mat-icon matTooltip="Carica immagine"  (click)="openDialog()">add_circle_outline</mat-icon>
    </div>
  </div>

  <mat-card class="mat-elevation-z2" *ngIf="images.length">

    <mat-card-content fxFlex="100" fxLayout="column" fxLayoutAlign="space-between start">

      <div class="image-card-container" fxLayoutAlign="center center">
        <img mat-card-image #imageTag (click)="openItem(0)" [src]="imagePath"
             (error)="changeImageSource(imageTag)">
      </div>

    </mat-card-content>
  </mat-card>

  <div fxFlex="100" fxLayoutAlign="center center" *ngIf="!images.length" class="no-data">
    <p>Nessuna immagine trovata</p>
  </div>
</div>
