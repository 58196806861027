import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@services';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  private isRedirectionNeeded(state: RouterStateSnapshot, isAuthenticated: boolean): boolean {
    const isLoginPage = state.url === '/login';
    return (isAuthenticated && isLoginPage) || (!isAuthenticated && !isLoginPage);
  }

  private getRedirectionPath(state: RouterStateSnapshot): string {
    const isLoginPage = state.url === '/login';
    return isLoginPage ? '/' : '/login';
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const canActivate = this.authService.isAuthenticated();
    if (!canActivate) {
      this.router.navigate(['/login']);
    }
    return canActivate;
  }

  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
