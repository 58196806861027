import {Component, Inject} from '@angular/core';
import {IConfirmData} from '../../dialog/dialog.service';
import {remove} from 'lodash';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageItem} from 'ng-gallery';

@Component({
  selector: 'p420-selected-images-modal',
  templateUrl: './selected-images-modal.component.html',
  styleUrls: ['./selected-images-modal.component.scss']
})

export class SelectedImagesModalComponent {

  images = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<SelectedImagesModalComponent>
  ) {
    this.loadGallery();
  }

  abort() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(this.images);
  }

  loadGallery() {
    this.data.item.forEach(work => {
      if (work.image) {
        this.images.push(new ImageItem(
          {
            // @ts-ignore
            id: work.image.id,
            src: work.image.path,
            thumb: work.image.path,
            work_title: work.title,
            work_technique_ita: work.technique_ita,
            work_technique_eng: work.technique_eng,
            work_year: work.year,
            authors: work.authors,
            work_id: work.id,
            work_dimensions: work.dimensions,
            photographer_code: work.cover.photographer_code,
            edition: work.edition,
          })
        );
      } else if (work.cover){
        this.images.push(new ImageItem(
          {
            // @ts-ignore
            id: work.cover.id,
            src: work.cover.path,
            thumb: work.cover.path,
            work_title: work.title,
            work_technique_ita: work.technique_ita,
            work_technique_eng: work.technique_eng,
            work_year: work.year,
            authors: work.authors,
            work_id: work.id,
            work_dimensions: work.dimensions,
            photographer_code: work.cover.photographer_code,
            edition: work.edition,
          })
        );
      } else {
        this.images.push(new ImageItem(
          {
            // @ts-ignore
            id: null,
            src: null,
            thumb: null,
            work_title: work.title,
            work_technique_ita: work.technique_ita,
            work_technique_eng: work.technique_eng,
            work_year: work.year,
            authors: work.authors,
            work_id: work.id,
            work_dimensions: work.dimensions,
            photographer_code: null,
            edition: work.edition,
          })
        );
      }

    });
  }

  onRemoveImage(obj) {
    remove(this.images, (item) => {
        return item.data.id === obj.item.data.id;
      });
  }

}
