import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IConfirmData} from '../../../../common/dialog/dialog.service';
import {ToastService} from '../../../../common/components/toast/toast.service';
import {WorkGroup} from '../../../../common/models/work/work-group';
import {WorkGroupsService, WorksService} from '../../works.service';
import {Work} from '@models';
import {find} from 'lodash';

@Component({
  selector: 'search-and-add-works-to-group-dialog',
  templateUrl: './search-and-add-works-to-group-dialog.component.html',
  styleUrls: ['./search-and-add-works-to-group-dialog.component.scss']
})
export class SearchAndAddWorksToGroupDialogComponent {
  ajaxLoading = false;
  groups: WorkGroup[] = [];
  workIdsSelected;
  works: Work[] = [];
  worksSelected: Work[] = [];
  group;

  constructor(
    private toastService: ToastService,
    private workGroupService: WorkGroupsService,
    private worksService: WorksService,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<SearchAndAddWorksToGroupDialogComponent>
  ) {
  }

  ngOnInit() {
    if (this.data.item)
      this.workIdsSelected = this.data.item;

    if (this.data['group'])
      this.group = this.data['group'];
  }

  abort() {
    this.dialogRef.close(false);
  }

  compareObjects(obj1, obj2): boolean {
    if (obj1 && obj2) {
      return obj1.id === obj2.id;
    }
    return false;
  }

  filterWorks(filter) {
    this.ajaxLoading = true;
    this.worksService.query({page: 1, filter: filter}).subscribe((results: any) => {
      this.works = results.data.map(work => {
        return new Work(work);
      });

      this.worksSelected.forEach(work => {
        const isAlreadyPresent = find(this.works, item => {
          return work.id === item.id;
        });
        if (!isAlreadyPresent)
          this.works.push(work)
      });
      this.ajaxLoading = false;
    });
  }

  getName(data) {
    if (!data)
      return;

    if (data.denomination)
      return data.denomination;

    let name = '';

    if (data.name)
      name += data.name;

    if (data.lastname)
      name += ' ' + data.lastname;

    if (!data.denomination && !data.lastname && !data.name)
      return '--'

    return name;
  }

  save() {
    const workIdsSelected = [];
    this.worksSelected.forEach(work => {
      workIdsSelected.push(work.id)
    });
    let data = {
      group_id: this.group.id,
      work_ids: workIdsSelected
    };

    this.ajaxLoading = true;
    this.workGroupService.assignWorksToGroup(data).subscribe(group => {
        this.ajaxLoading = false;
        this.toastService.success('Opere aggiunte al gruppo con successo');
        this.dialogRef.close(group);
      },
      () => {
        this.ajaxLoading = false;
        this.toastService.error('Si è verificato un problema');
      });
  }

}
