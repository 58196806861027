<div fxLayout="row wrap" class="gallery-container">
  <mat-card class="mat-elevation-z2" *ngFor="let image of images; let i = index">

    <mat-card-content fxFlex="100" fxLayout="column" fxLayoutAlign="space-between start">

      <div class="image-card-container" fxLayoutAlign="center center">
        <img mat-card-image #imageTag (click)="openItem(i)" [src]="image?.data?.thumb"
             (error)="changeImageSource(imageTag)">
      </div>

      <div class="image-caption" fxLayout="column" fxLayoutGap="4px">

        <span> {{image?.data?.authors.length ? getName(image?.data?.authors[0]) : ''}} </span>

        <a target="_blank" [routerLink]="['/admin/works']"
           [queryParams]="{t: 'all', work_id: image?.data?.work_id, detail: image?.data?.authors[0]?.id, filter:
              (image?.data?.authors[0]?.lastname) ? (image?.data?.authors[0]?.lastname) : (image?.data?.authors[0]?.denominaition)}"><span
          class="pointer">

          <i>{{image?.data?.work_title ? image?.data?.work_title : ''}}</i> </span></a>


        <span>{{image?.data?.work_year ? image?.data?.work_year : ''}}</span>

        <span *ngIf="language === 'ita'">{{image?.data?.work_technique_ita}}</span>
        <span *ngIf="language === 'eng'">{{image?.data?.work_technique_eng}}</span>
        <div *ngIf="language === 'both'">
          {{image?.data?.work_technique_ita}} {{image?.data?.work_technique_eng ? ' / ' + image?.data?.work_technique_eng : ''}}
        </div>

        <span
          *ngIf="image?.data?.work_dimensions?.height || image?.data?.work_dimensions?.width || image?.data?.work_dimensions?.depth">
          {{image?.data?.work_dimensions?.height || image?.data?.work_dimensions?.width ? (selectedOption === 'cm' ? 'cm' : 'inches') : ''}}
          {{image?.data?.work_dimensions?.height ? (image?.data?.work_dimensions?.height | toInch:selectedOption) : ''}}
          {{image?.data?.work_dimensions?.width ? ' x ' + (image?.data?.work_dimensions?.width | toInch:selectedOption) : ''}}
          {{image?.data?.work_dimensions?.depth ? ' x ' + (image?.data?.work_dimensions?.depth | toInch:selectedOption) : ''}}

          <span *ngIf="language === 'ita' && (image?.data?.work_dimensions?.height || image?.data?.work_dimensions?.width)
            && (image?.data?.work_dimensions?.overall_unit !== '--')">
              {{image?.data?.work_dimensions?.overall_unit === 'overall' ? 'tot.' : 'cad.'}}</span>

            <span *ngIf="language === 'eng' && (image?.data?.work_dimensions?.height || image?.data?.work_dimensions?.width)
            && (image?.data?.work_dimensions?.overall_unit !== '--')">
              {{image?.data?.work_dimensions?.overall_unit === 'overall' ? 'overall' : 'each'}}</span>

            <span *ngIf="language === 'both' && (image?.data?.work_dimensions?.height || image?.data?.work_dimensions?.width)
            && (image?.data?.work_dimensions?.overall_unit !== '--')">
              {{image?.data?.work_dimensions?.overall_unit === 'overall' ? 'tot./overall' : 'cad./each'}} </span>

        </span>

        <span
          *ngIf="image?.data?.work_dimensions?.height_additional || image?.data?.work_dimensions?.width_additional || image?.data?.work_dimensions?.depth_additional">
          {{image?.data?.work_dimensions?.height_additional || image?.data?.work_dimensions?.width_additional ? (selectedOption === 'cm' ? 'cm' : 'inches') : ''}}
          {{image?.data?.work_dimensions?.height_additional ? (image?.data?.work_dimensions?.height_additional | toInch:selectedOption) : ''}}
          {{image?.data?.work_dimensions?.width_additional ? ' x ' + (image?.data?.work_dimensions?.width_additional | toInch:selectedOption) : ''}}
          {{image?.data?.work_dimensions?.depth_additional ? ' x ' + (image?.data?.work_dimensions?.depth_additional | toInch:selectedOption) : ''}}

          <span *ngIf="language === 'ita' && (image?.data?.work_dimensions?.height_additional || image?.data?.work_dimensions?.width_additional)
            && (image?.data?.work_dimensions?.overall_unit_additional !== '--')">
              {{image?.data?.work_dimensions?.overall_unit_additional === 'overall' ? 'tot.' : 'cad.'}}</span>

            <span *ngIf="language === 'eng' && (image?.data?.work_dimensions?.height_additional || image?.data?.work_dimensions?.width_additional)
            && (image?.data?.work_dimensions?.overall_unit_additional !== '--')">
              {{image?.data?.work_dimensions?.overall_unit_additional === 'overall' ? 'overall' : 'each'}}</span>

            <span *ngIf="language === 'both' && (image?.data?.work_dimensions?.height_additional || image?.data?.work_dimensions?.width_additional)
            && (image?.data?.work_dimensions?.overall_unit_additional !== '--')">
              {{image?.data?.work_dimensions?.overall_unit_additional === 'overall' ? 'tot./overall' : 'cad./each'}} </span>

        </span>

        <span
          *ngIf="image?.data?.work_dimensions?.work_duration">{{image?.data?.work_dimensions?.work_duration}}</span>

        <span>{{image?.data?.edition}}</span>

      </div>


      <div fxFlexAlign="end" fxLayoutGap="10px" class="icon_section">

        <div class="original">
          <mat-icon style="cursor: pointer;" matTooltip="Foto originale"
                    (click)="seeOriginPhoto(image?.data?.original_path, image?.data?.work_title)">
            photo
          </mat-icon>
        </div>

        <div>
          <div class="checkbox" *ngIf="!noCheckbox">
            <mat-checkbox name="checked_for_portfolio{{i}}" (ngModelChange)="selectedCheckbox($event, i)"
                          [(ngModel)]="image.data.checked_for_portfolio"></mat-checkbox>
          </div>

          <div style="margin-top: 4px;" *ngIf="noCheckbox && !noIcons">
            <mat-icon style="cursor: pointer;" (click)="goToDetail(image)"> visibility</mat-icon>
          </div>

          <div style="margin-top: 4px;" *ngIf="deleteIcon">
            <mat-icon style="cursor: pointer; color: red" (click)="removeFromList(image, i)"> clear</mat-icon>
          </div>

        </div>

      </div>


    </mat-card-content>
  </mat-card>

  <div fxFlex="100" fxLayoutAlign="center center" *ngIf="!images.length">
    <p>Nessuna immagine trovata</p>
  </div>
</div>
