import {Component, Inject} from '@angular/core';
import {IConfirmData} from '../../../../common/dialog/dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-contacts-search',
  templateUrl: './contacts-search.component.html',
  styleUrls: ['./contacts-search.component.scss']
})

export class ContactsSearchComponent {

  name;
  lastname;
  denomination;
  photographer_code;
  ajaxLoading = false;
  oldAdvanceSearch = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<ContactsSearchComponent>
  ) {
    if (this.data['oldAdvanceSearch']) {
      this.initOldSearch();
    }
  }

  initOldSearch() {
    this.oldAdvanceSearch = this.data['oldAdvanceSearch'];
    if (this.oldAdvanceSearch.name)
      this.name = this.oldAdvanceSearch.name;
    if (this.oldAdvanceSearch.lastname)
      this.lastname = this.oldAdvanceSearch.lastname;
    if (this.oldAdvanceSearch.denomination)
      this.denomination = this.oldAdvanceSearch.denomination;
    if (this.oldAdvanceSearch.photographer_code)
      this.photographer_code = this.oldAdvanceSearch.photographer_code;
  }

  abort() {
    this.dialogRef.close(null);
  }

  search() {
    const data = new DataSearchContact;

    if (this.name)
      data.name = this.name;
    if (this.lastname)
      data.lastname = this.lastname;
    if (this.denomination)
      data.denomination = this.denomination;
    if (this.photographer_code)
      data.photographer_code = this.photographer_code;

    this.dialogRef.close(data);
  }

}

class DataSearchContact {
  name: string;
  lastname: string;
  denomination: string;
  photographer_code: string;
}
