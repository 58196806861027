export class DashboardSlot {

  id?: number;
  title?: string;
  description?: string;
  description_eng?: string;
  author?: string;
  date?: any;
  place?: string;
  type?: string;
  type_eng?: string;
  link?: string;
  path?: string;
  original_path?: string;
  thumbnail_path?: string;
  image?: string;
  file?: any;
  metadata?: {
    data_updated?: boolean;
    file_changed?: boolean;
    checked_for_portfolio?: boolean;
    loading?: boolean;
    size_error?: boolean;
    has_error?: boolean;
    err_message?: string;
    succeeded?: boolean;
  };

  constructor(dashboard_slot: Partial<DashboardSlot> = {}) {
    Object.assign(this, dashboard_slot);

    this.metadata =  {
      data_updated: false,
      file_changed: false,
      loading: false,
      size_error: false,
      has_error: false,
      err_message: null,
      succeeded: false
    }
  }

}
