import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmComponent } from '../confirm/confirm.component';
import {DomSanitizer} from '@angular/platform-browser';
import {ExhibitionsService} from '../../../modules/exhibitions/exhibitions.service';
import {Document} from '@models';
import {FairsService} from '../../../modules/fairs/fairs.service';
import {PublicationsService} from '../../../modules/publications/publications.service';
import {ProjectRoomsService} from '../../../modules/project-rooms/project_rooms.service';
import {ContactService} from '../../../modules/contacts/contact.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'str-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  loading: boolean;
  document: any;
  error: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private sanitizer: DomSanitizer,
    private exhibitionService: ExhibitionsService,
    private contactService: ContactService,
    private publicationsService: PublicationsService,
    private projectRoomsService: ProjectRoomsService,
    private fairsService: FairsService,
    public dialogRef: MatDialogRef<ConfirmComponent>
  ) {}

  ngOnInit() {}

  abort() {
    this.dialogRef.close();
  }

  getThumbnail() {
    if (!this.document){
      return '';
    }

    let path = '';

    if (this.document.type === "application/pdf") {
      path = '../../../../assets/img/pdf_icon.png';
    } else if (this.document.type === "application/msword") {
      path = '../../../../assets/img/word.svg';
    } else {
      path = '../../../../assets/img/google_docs.png';
    }

    return this.sanitizer.bypassSecurityTrustStyle(`url(${path})`);
  }

  getSelectedFile($event) {
    let files = $event.target.files;
    if (files.length == 0)
      return;
    this.document = files[0];
  }

  upLoadToServer() {
    this.loading = true;
    const formData = new FormData();

    formData.set('file', this.document, this.document.name);
    formData.append('name', this.document.name);
    formData.append('type', this.document.type);

    if (this.data.fair_id)
      this.updateDocument(formData, this.fairsService, this.data.fair_id);

    if (this.data.exhibition_id)
      this.updateDocument(formData, this.exhibitionService, this.data.exhibition_id);

    if (this.data.publication_id)
      this.updateDocument(formData, this.publicationsService, this.data.publication_id);

    if (this.data.project_room_id)
      this.updateDocument(formData, this.projectRoomsService, this.data.project_room_id);

    if (this.data.contact_id)
      this.updateDocument(formData, this.contactService, this.data.contact_id);
  }

  updateDocument(formData, service, id) {
    service.updateFile(formData, id).subscribe(
      (res: any) => {
        const newDocument = new Document(res);
        this.loading = false;
        this.dialogRef.close(newDocument);
      },
      exception => {
        this.loading = false;
        this.error = exception.data.error.message;
      }
    );
  }


}
