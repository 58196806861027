<div class="page_certificate">
    <div class="container" fxLayout="column">

      <div fxLayout="column" fxLayoutAlign="space-around">
        <div class="header" fxLayout="row">
          <span fxFlex="100">Certificato di autenticità</span>
          <span fxFlex="100">Certificate of authenticity</span>
          <span class="logo" fxFlex="100"><img alt="p420" class="image" src="/assets/img/logo.svg"></span>
        </div>

        <div class="img-certificate-container" style="display: flex; justify-content: center">
          <img #imageTag (error)="changeImageSource(imageTag)"
               *ngIf="item?.cover?.path !== '' && item?.cover?.path != null"
               src="{{item?.cover?.path}}">
        </div>

        <div class="text-section-container" fxLayoutAlign="start">

          <div class="left-text text-section">

            <div class="cell">
              <div class="p">Artista / Artist</div>
              <div *ngFor="let author of item.authors" class="bold p">{{getAuthorData(author)}}</div>
            </div>

            <div class="cell">
              <div class="p">Titolo / Title</div>
              <div class="p bold">{{item.title || '--'}}</div>
            </div>

            <div class="cell" *ngIf="item.year">
              <div class="p">Anno / Year</div>
              <div class="p bold">{{item.year}}</div>
            </div>
          </div>

          <div class="right-text text-section">
              <div class="cell" *ngIf="item.technique_ita || item.technique_eng">
                <div class="p">Tecnica / Technique</div>
                <div class="p bold">{{item.technique_ita}} {{item.technique_eng ? '/ ' + item.technique_eng : ''}}</div>
              </div>

              <div class="cell" *ngIf="item?.dimensions?.height || item?.dimensions?.width || item?.dimensions?.depth
                    || item?.dimensions?.variable_dimensions">
                <div class="p">Dimensioni / Dimensions</div>

                <div *ngIf="item?.dimensions?.variable_dimensions" class="bold p">Dimensioni variabili</div>

                <div class="bold p" *ngIf="(item?.dimensions?.height || item?.dimensions?.width || item?.dimensions?.depth)
                 && !item?.dimensions?.variable_dimensions">
                  {{item?.dimensions?.height | toInch:selectedOption}}
                  x
                  {{item?.dimensions?.width | toInch:selectedOption}}
                  {{item?.dimensions?.depth ? 'x ' + (item?.dimensions?.depth | toInch:selectedOption) : '' }}
                  {{selectedOption === 'cm' ? 'cm' : 'inches'}}

                  <span *ngIf="(item?.dimensions?.height || item?.dimensions?.width)
                 && (item?.dimensions?.overall_unit !== '--')">
                    {{getUnit(item?.dimensions?.overall_unit)}}</span>


                </div>

                <div class="bold p" *ngIf="(item?.dimensions?.height_additional
                || item?.dimensions?.width_additional || item?.dimensions?.depth_additional)
                && !item?.dimensions?.variable_dimensions">

                  {{item?.dimensions?.height_additional | toInch:selectedOption}}
                  x
                  {{item?.dimensions?.width_additional | toInch:selectedOption}}
                  {{item?.dimensions?.depth_additional ? 'x ' + (item?.dimensions?.depth_additional | toInch:selectedOption) : '' }}
                  {{selectedOption === 'cm' ? 'cm' : 'inches'}}

                  <span *ngIf="(item?.dimensions?.height_additional || item?.dimensions?.width_additional)
                 && (item?.dimensions?.overall_unit_additional !== '--')">
                    {{getUnit(item?.dimensions?.overall_unit_additional)}}</span>

                </div>
              </div>

            <div class="cell" *ngIf="item?.dimensions?.work_duration">
              <div class="p">Durata / Duration</div>
              <div class="p bold">{{item?.dimensions?.work_duration}}</div>
            </div>

              <div class="cell" *ngIf="item.edition">
                <div class="p">Edizione / Edition</div>
                <div class="p bold">{{item.edition}}</div>
              </div>

              <div class="cell" *ngIf="item.origin_ita || item.origin_eng">
                <div class="p">Provenienza / Provenance</div>
                <div class="p bold">{{item.origin_ita}} {{item.origin_eng ? '/ ' + item.origin_eng : ''}}</div>
              </div>
          </div>
        </div>
      </div>

      <div class="signature-container" fxLayout="row" fxLayoutAlign="end center" >
        <div class="signature artist-left" fxLayout="column">
          <div class="sigspace"></div>
          <div *ngIf="item.certificate_for_artist ||
              (!item.certificate_for_artist && !item.certificate_for_heirs && !item.certificate_for_family)">
            L'artista / The artist
          </div>
          <div *ngIf="item.certificate_for_family">La famiglia / The family</div>
          <div *ngIf="item.certificate_for_heirs">Gli Eredi / The Estate</div>

          <div style="border-bottom: 1px solid black; margin-top: 40px; width: 100%"></div>

        </div>
        <div class="separator"></div>
        <div class="artist-right" fxLayout="column">
          <div class="sigspace"></div>
          <div>
            P420
          </div>

          <div style="border-bottom: 1px solid black; margin-top: 40px; width: 100%"></div>

        </div>
      </div>

    </div>
</div>
