import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService, LocalStorageService} from '@services';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {ToastService} from '../components/toast/toast.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public localStorageService: LocalStorageService,
    public toastService: ToastService,
    public authService: AuthService,
  ) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.localStorageService.getAuth();
    let headers = new HttpHeaders()
    //const url = httpRequest.url;
    if (auth) {
      headers = headers.append('Authorization', `Bearer ${auth.accessToken}`)
    }
    // @ts-ignore
    return next.handle(httpRequest.clone({
      headers: headers
    })).pipe(catchError(error => {

      if (error instanceof HttpErrorResponse &&
        !httpRequest.url.includes('token/generate') &&
        !httpRequest.url.includes('token/refresh')
        && error.status === 401) {
        return this.handle401Error(httpRequest, next);
      }

      if (error instanceof HttpErrorResponse &&
        !httpRequest.url.includes('token/generate')&&
        !httpRequest.url.includes('token/refresh')
        && error.status === 503) {
        this.localStorageService.removeAuth();
        this.authService.logout();
        return;
      }

      if (error instanceof HttpErrorResponse &&
        !httpRequest.url.includes('token/generate')&&
        !httpRequest.url.includes('token/refresh')
        && error.status === 402) {
        this.localStorageService.removeAuth();
        this.authService.logout();
        this.toastService.error('Tecnico non abilitato');
        return;
      }

      return throwError(error);
    }));
  }

  // tslint:disable-next-line:typedef
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const auth = this.localStorageService.getAuth();
      if (auth.refreshToken) {
        return this.authService.refreshToken(auth.refreshToken).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            const oldAuth = this.localStorageService.getAuth();
            oldAuth.token = token.token;
            this.localStorageService.setAuth(oldAuth);
            this.refreshTokenSubject.next(token.access_token);
            return next.handle(this.addTokenHeader(request, token.access_token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.localStorageService.removeAuth();
            this.authService.logout();
            return throwError(err);
          })
        );
      } else {
        this.localStorageService.removeAuth();
        this.authService.logout();
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  // tslint:disable-next-line:typedef
  addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set(
        'Authorization', `Bearer ${token}`)
    });
  }

}
