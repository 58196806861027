<section>
  <div class="dialog-header">
    <h3>Carica Nuova Immagine</h3>
  </div>
  <div class="dialog-content">

    <div class="upload-drop-zone">
        <div fxLayoutAlign="center center" >
          <input type="file" class="input-file" #fileUploader accept="image/png,image/jpeg,image/jpg"
                 name="file" (change)="getSelectedFile($event)"/>

          <button mat-button class="upload-file" (click)="fileUploader.click()">
            Carica Immagine
            <mat-icon>file_upload</mat-icon>
          </button>
          <div class="state">
            <mat-progress-spinner *ngIf="loading" color="accent"
                                  mode="indeterminate" diameter="40"></mat-progress-spinner>
            <mat-icon *ngIf="!loading && error" class="err-icon">error_outline</mat-icon>
          </div>
        </div>

      <div fxLayoutAlign="center center" fxLayout="column">
        <div class="image-preview" [style.background-image]="getThumbnail(image)"></div>
        <div *ngIf="image?.name">{{image.name}}  </div>
        <div *ngIf="error">{{error}} </div>
      </div>

    </div>

  </div>
  <div class="dialog footer">
    <div class="button-container">
      <button mat-flat-button class="btn" (click)="abort()" tabindex="2">ANNULLA</button>
      <button mat-flat-button [disabled]="!image" class="btn" (click)="upLoadToServer()">SALVA</button>
    </div>
  </div>
</section>
