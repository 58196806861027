import {Injectable} from '@angular/core';
import {Router,} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class NavigationService {

  routeHistory = [];
  private _previusRoute: string;

  currentCurrencyObservable: BehaviorSubject<string> = new BehaviorSubject<string>('EUR');
  currentMeasurementUnitObservable: BehaviorSubject<string> = new BehaviorSubject<string>('cm');
  currentHidePriceFlagObservable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentHideAvailabilityFlagObservable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentLanguageObservable: BehaviorSubject<string> = new BehaviorSubject<string>('ita');

  constructor(private router: Router) {
  }

  goTo(route) {
    this.router.navigate([`/${route}`]);
  }

  goToWithParams(route, params){
    this.router.navigate([`/${route}`],{ queryParams: params });
  }

  goBack() {
    const [url] = [...this.routeHistory].reverse();
    if (!url) {
      return this.router.navigate([`/${this.previusRoute}`]);
    }
    this.router.navigateByUrl(url);
  }

  get previusRoute(): string {
    return this._previusRoute;
  }

  setCurrency(currency: string) {
    this.currentCurrencyObservable.next(currency);
  }

  setMeasurementUnit(measurementUnit: string) {
    this.currentMeasurementUnitObservable.next(measurementUnit);
  }

  setHidePriceFlag(hidePriceFlag: boolean) {
    this.currentHidePriceFlagObservable.next(hidePriceFlag);
  }

  setHideAvailabilityFlag(hidePriceFlag: boolean) {
    this.currentHideAvailabilityFlagObservable.next(hidePriceFlag);
  }

  setLanguage(language: string) {
    this.currentLanguageObservable.next(language);
  }
}
