import * as moment from 'moment';

export class EventModel {
  id?: number;
  title?: string;
  description?: string;
  location?: string;
  start?: any;
  startAtHour?: string;
  end?: any;
  endAtHour?: string;
  allDay?: boolean;
  type?: string;
  backgroundColor?: string;
  editable?: boolean;
  fair?: any;
  exhibition?: any;
  projectRoom?: any;
  contact?: any;
  hasReference = false;

  constructor(event: Partial<EventModel> = {}) {
    event.backgroundColor = EventModel.getColorType(event);
    event.start = new Date(event.start);
    event.end = new Date(event.end);
    event.editable = true;
    event.hasReference = (event.fair || event.exhibition || event.contact || event.projectRoom)
    Object.assign(this, event);
  }

  static getColorType (event) {
    switch (event.type) {
      case 'fair':
        return 'green';
      case 'project_room':
        return 'purple';
      case 'contact':
        return 'red';
      case 'exhibition':
        if (event.exhibition) {
          if (event.exhibition.owner_type == "external") {
            return 'grey'
          }
        }
        return 'blue';
      case 'exhibition_external':
        return 'grey'
      default:
        return 'orange';
    }
  }

  static addTime(momentDate, time){
    time   = moment(time, 'HH:mm');
    momentDate.set({
      hour:   time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second')
    });
    return  momentDate.format('YYYY-MM-DD HH:mm');
  }



}
