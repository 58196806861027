import {Component, Inject} from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'agb-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss']
})

export class ToastComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: { message: string },
        private snackBar: MatSnackBar
    ) {
    }

    close() {
        this.snackBar.dismiss();
    }
}
