<div class="dialog-wrapper" fxLayout="column">

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between center">
    <h1 mat-dialog-title> {{title}}</h1>

    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="abort()"> Annulla</button>
      <button mat-flat-button class="btn" (click)="confirm()"> Salva</button>
    </div>
  </div>

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="selectAll()">Seleziona Tutto</button>
      <button mat-flat-button class="btn" (click)="deSelectAll()">Deseleziona Tutto</button>
    </div>
  </div>

  <div class="print-table">
    <div fxLayoutAlign="start center"
         *ngFor="let doc of documents; let index = index; let last = last">

      <div fxLayoutAlign="start center" class="row-print" [ngClass]="{'opacity': !doc.selected}">

        <div class="toggle">
          <mat-slide-toggle [(ngModel)]="doc.selected"></mat-slide-toggle>
        </div>

        <div class="arrows">
          <mat-icon [ngClass]="{'disabled': last}" (click)="moveDown(index)">arrow_downward</mat-icon>
          <mat-icon [ngClass]="{'disabled': !(index > 0)}" (click)="moveUp(index)">arrow_upward</mat-icon>
        </div>

        <div class="index">
          {{index + 1}}
        </div>

        <div class="moveToSection">
          <mat-icon
            [ngClass]="{'disabled': !doc.newPosition || doc.newPosition < 1 || doc.newPosition > documents.length}"
            (click)="moveTo(index, doc.newPosition)">low_priority
          </mat-icon>
          <input matInput name="newPosition_{{index}}" [(ngModel)]="doc.newPosition" type="number">
        </div>


        <span class="nav-caption title">
          {{doc?.name}}
        </span>


      </div>


    </div>

    <div fxLayoutAlign="start center" *ngIf="!documents?.length">
      <span class="nav-caption">Nessun Documento</span>
    </div>

  </div>


  <div class="dialog-footer" fxLayoutAlign="end center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="abort()"> Annulla</button>
      <button mat-flat-button class="btn" (click)="confirm()"> Salva</button>
    </div>
  </div>

</div>



