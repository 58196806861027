<div class="dialog-wrapper" fxLayout="column" (onclose)="confirm()">

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between start">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>

  <app-image-list [noCheckbox]="true" [selectAll]="false"
                  (removeImage)="onRemoveImage($event)"
                  [images]="images" [noIcons]="true" [deleteIcon]="true"></app-image-list>


  <div class="dialog-footer" fxLayoutAlign="end center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="abort()" > {{ data.cancelButtonText }}</button>
      <button mat-flat-button class="btn" (click)="confirm()" > {{ data.okButtonText }}</button>
    </div>
  </div>

</div>
