<div *ngIf="!isSmallDevice" style="overflow: hidden; height: 100%">

  <mat-toolbar role="heading" color="primary">
    <app-header fxFlex="100" (toggleSideMenu)="sideMenuCollapsed = !sideMenuCollapsed"></app-header>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav [ngClass]="{'small': isMediumDevice || sideMenuCollapsed}"
      #sidenav role="navigation"
      opened fixedInViewport="true" disableClose mode="side" fixedTopGap="64"
      mwlResizable
      [enableGhostResize]="true"
      [resizeEdges]="{right: true, left: false}"
      (resizing)="onResize($event)"
      [ngStyle]="{'width.px': [width]}">
      <app-sidenav-list [isMediumDevice]="isMediumDevice || sideMenuCollapsed"></app-sidenav-list>
    </mat-sidenav>

    <mat-sidenav-content [ngStyle]="{'margin-left': isMediumDevice || sideMenuCollapsed ? '80px' :'220px'}">
      <router-outlet></router-outlet>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>


<div *ngIf="isSmallDevice" fxLayout="column" fxFill fxLayoutAlign="center center" class="not-supported">
  <h2 class="mat-h2">DEVICE DIMENSIONS IS NOT SUPPORTED</h2>
  <h3 class="mat-h3">If you are using an IOS device you can download the application <a href="#">Here</a></h3>
</div>
