<div class="dialog-wrapper" fxLayout="column">

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between start">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>


  <div fxLayout="row" fxFlex class="options-container" fxLayoutAlign="start center">
    <div fxFlex="50">Nascondi i prezzi</div>
    <div fxFlex="50">
      <mat-slide-toggle [(ngModel)]="currentHidePriceFlag" (ngModelChange)="priceFlagChanged($event)"></mat-slide-toggle>
    </div>
  </div>

  <div fxLayout="row" fxFlex class="options-container" fxLayoutAlign="start center">
    <div fxFlex="50">Nascondi disponibilità</div>
    <div fxFlex="50">
      <mat-slide-toggle [(ngModel)]="currentHideAvailabilityFlag" (ngModelChange)="availabilityFlagChanged($event)"></mat-slide-toggle>
    </div>
  </div>

  <div fxLayout="row" fxFlex class="options-container" fxLayoutAlign="space-evenly center">
    <div fxFlex="55">Cambia Vaulta</div>
    <mat-form-field fxFlex="45">
      <mat-select [(ngModel)]="currentCurrency" (ngModelChange)="currencyChanged($event)" style="width: 100px">
        <mat-option [value]="'EUR'">EUR</mat-option>
        <mat-option [value]="'BGP'">GBP</mat-option>
        <mat-option [value]="'USD'">USD</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxFlex class="options-container" fxLayoutAlign="space-evenly center">
    <div fxFlex="55">Cambia Unità di Misura</div>
    <mat-form-field fxFlex="45">
      <mat-select [(ngModel)]="currentMeasurementUnit" (ngModelChange)="measurementUnitChanged($event)" style="width: 100px">
        <mat-option [value]="'cm'">CM</mat-option>
        <mat-option [value]="'in'">INCHES</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxFlex class="options-container" fxLayoutAlign="space-evenly center">
    <div fxFlex="55">Cambia Lingua</div>
    <mat-form-field fxFlex="45">
      <mat-select [(ngModel)]="currentLanguage" (ngModelChange)="languageChanged($event)" style="width: 100px">
        <mat-option [value]="'ita'">ITA</mat-option>
        <mat-option [value]="'eng'">ENG</mat-option>
        <mat-option [value]="'both'">ITA/ENG</mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <div class="dialog-footer" fxLayoutAlign="end center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="abort()" tabindex="2"> {{ data.okButtonText }}</button>
    </div>
  </div>

</div>
