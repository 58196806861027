import {Component, Inject, OnDestroy} from '@angular/core';
import {ToastService} from '../toast/toast.service';
import {LocalStorageService, NavigationService} from '@services';
import {Work} from '@models';
import {findIndex, remove, sortBy} from 'lodash';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-works-sorting-modal',
  templateUrl: './works-sorting-modal.component.html',
  styleUrls: ['./works-sorting-modal.component.scss']
})

export class WorksSortingModalComponent implements OnDestroy{

  works: Work[] = [];
  oldData;
  item;
  selectableWorks = false;
  works_pipeline;
  selectedOption = 'cm';
  language = 'ita';
  title = 'Scegli nuovo ordine';
  navigationSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<WorksSortingModalComponent>,
    public toastService: ToastService,
    public navigationService: NavigationService,
    public localStorageService: LocalStorageService,
) {

    this.oldData = {...this.data};
    this.works = [...this.data.item];
    this.works = sortBy(this.works, ['order_number']);
    this.selectableWorks = this.data.selectableWorks;

    this.works_pipeline = this.data.pipeline;
    if (this.works_pipeline) {
      this.applyPipeline();
    }

    this.language = this.localStorageService.getLanguage();
    this.selectableWorks ? this.title = 'Scegli nuovo ordine e deseleziona le opere da non visualizzare' : this.title = 'Scegli nuovo ordine';
    this.navigationSubscription = this.navigationService.currentMeasurementUnitObservable.subscribe((change) => {
      this.selectedOption = change;
    });
    this.selectedOption = this.localStorageService.getMeasurementUnit();
  }

  applyPipeline() {
    if (this.works_pipeline) {
      this.works.forEach( work => {
        work.selected = false;
      })
    }
    const pipeline = this.works_pipeline.split(',');
    const selectedWorks = [];
    const array = [...this.works];
    pipeline.forEach( id => {
      const workToMove = remove(array, (work) => {
        return work.id == id;
      });
      if (workToMove.length) {
        workToMove[0].selected = true;
        selectedWorks.push(workToMove[0]);
      }
    });

    const newArray = selectedWorks.concat(array);
    this.works = [...newArray];
  }

  abort() {
    this.works = [...this.oldData.item];
    this.selectableWorks = this.oldData.selectableWorks;
    this.works_pipeline = this.data.pipeline;
    this.dialogRef.close(false);
  }

  confirm() {
    const pipeline = [];
    this.works.forEach( work => {
      let isAlreadyPresent = findIndex(pipeline, work.id);

      if (this.selectableWorks) {
        if (isAlreadyPresent == -1 && work.selected) {
          pipeline.push(work.id);
        }
      } else {
        if (isAlreadyPresent == -1) {
          pipeline.push(work.id);
        }
      }

    });
    this.dialogRef.close(pipeline);
  }

  changeImageSource(imageTag) {
    imageTag.src = 'assets/img/placeholder-img.jpg';
  }

  moveDown(index) {
    let oldElement = this.works[index+1];
    this.works[index+1] = this.works[index];
    this.works[index] = oldElement;
  }

  moveUp(index) {
    let oldElement = this.works[index-1];
    this.works[index-1] = this.works[index];
    this.works[index] = oldElement;
  }

  getUnit(unit) {
    if (this.language === 'ita') {
      if (unit === 'overall') {
        return 'tot.';
      } else if (unit === 'each') {
        return 'cad.';
      } else {
        return '';
      }
    }

    if (this.language === 'eng') {
      if (unit === 'overall') {
        return 'overall';
      } else if (unit === 'each') {
        return 'each';
      } else {
        return '';
      }
    }

    if (this.language === 'both') {
      if (unit === 'overall') {
        return 'tot./overall';
      } else if (unit === 'each') {
        return 'cad./each';
      } else {
        return '';
      }
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription)
      this.navigationSubscription.unsubscribe();
  }

  getName(data){
    if (!data)
      return;


    if (data.denomination)
      return data.denomination;

    let name = '';
    if (data.name)
      name += data.name + ' ';

    if (data.lastname)
      name += data.lastname;

    return name;
  }

  selectAll() {
    this.works = this.works.map( work => {
      work.selected = true;
      return work
    })
  }

  deSelectAll() {
    this.works = this.works.map( work => {
      work.selected = false;
      return work
    })
  }

  moveTo(index, newPosition) {
    if (newPosition < 1 || newPosition > this.works.length)
      return this.toastService.error('Posizione non valida')

    newPosition--;

    const itemToMove = this.works[index];
    this.works.splice(index, 1);
    this.works.splice(newPosition, 0, itemToMove);
  }
}
