<div class="dialog-wrapper" fxLayout="column">

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between center">
    <h1 mat-dialog-title> {{title}}</h1>

    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="abort()"> Annulla</button>
      <button mat-flat-button class="btn" (click)="confirm()"> Salva</button>
    </div>
  </div>

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="selectAll()">Seleziona Tutto</button>
      <button mat-flat-button class="btn" (click)="deSelectAll()">Deseleziona Tutto</button>
    </div>
  </div>

  <div class="print-table">
    <div fxLayoutAlign="start center"
         *ngFor="let item of items; let index = index; let last = last" >

      <div fxLayoutAlign="start center" class="row-print" [ngClass]="{'opacity': !item.visible_on_site}">

        <div class="toggle">
          <mat-slide-toggle [(ngModel)]="item.visible_on_site"></mat-slide-toggle>
        </div>

        <div class="arrows">
          <mat-icon [ngClass]="{'disabled': last}" (click)="moveDown(index)">arrow_downward</mat-icon>
          <mat-icon [ngClass]="{'disabled': !(index > 0)}" (click)="moveUp(index)">arrow_upward</mat-icon>
        </div>

        <div class="index">
          {{index+1}}
        </div>

        <div class="moveToSection" >
          <mat-icon [ngClass]="{'disabled': !item.newPosition || item.newPosition < 1 || item.newPosition > items.length}" (click)="moveTo(index, item.newPosition)">low_priority</mat-icon>
          <input matInput name="newPosition_{{index}}" [(ngModel)]="item.newPosition" type="number">
        </div>

        <div class="work-icon-container">
          <img #imageTag class="work-icon" [src]="item?.cover?.path" (error)="changeImageSource(imageTag)">
        </div>

        <span class="nav-caption title">
          {{item.title}}
        </span>

      </div>

    </div>

    <div fxLayoutAlign="start center" *ngIf="!items?.length">
      <span class="nav-caption">Nessun elemento</span>
    </div>

  </div>


  <div class="dialog-footer" fxLayoutAlign="end center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="abort()"> Annulla</button>
      <button mat-flat-button class="btn" (click)="confirm()"> Salva</button>
    </div>
  </div>

</div>



