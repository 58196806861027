export const environment = {
  // MOCK API
  // apiBaseUrl: 'https://private-222b87-p420mock.apiary-mock.com',

  // LOCAL API
  // apiBaseUrl: 'http://localhost:3333/api/v1',

  // STAGING API
    apiBaseUrl: 'https://p420-api.cdbkr.it/api/v1', // staging

  // PRODUCTION API
  //  apiBaseUrl: 'https://api.p420.it/api/v1', // produzione

  TOKEN_LIFETIME: 30000,
  production: false
};
