import {Component, Inject} from '@angular/core';
import {ToastService} from '../toast/toast.service';
import {LocalStorageService, NavigationService} from '@services';
import {sortBy} from 'lodash';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'order-items-dialog',
  templateUrl: './order-items-dialog.component.html',
  styleUrls: ['./order-items-dialog.component.scss']
})

export class OrderItemsDialogComponent {

  items = [];
  oldItems = [];
  item;
  pipeline;
  selectedOption = 'cm';
  language = 'ita';
  title = 'Scegli nuovo ordine e deseleziona gli elementi da non visualizzare';


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<OrderItemsDialogComponent>,
    public toastService: ToastService,
    public navigationService: NavigationService,
    public localStorageService: LocalStorageService,
  ) {
    this.items = [...this.data.item];
    this.items = sortBy(this.items, ['order_number']);
    this.oldItems = [...this.items];
  }


  abort() {
    this.items = [...this.oldItems];
    this.dialogRef.close(false);
  }

  confirm() {
    for (const [index, item] of this.items.entries()) {
      item.order_number = index;
    }
    this.dialogRef.close(this.items);
  }

  changeImageSource(imageTag) {
    imageTag.src = 'assets/img/placeholder-img.jpg';
  }

  moveDown(index) {
    let oldElement = this.items[index+1];
    this.items[index+1] = this.items[index];
    this.items[index] = oldElement;
  }

  moveUp(index) {
    let oldElement = this.items[index-1];
    this.items[index-1] = this.items[index];
    this.items[index] = oldElement;
  }

  selectAll() {
    this.items = this.items.map( item => {
      item.visible_on_site = true;
      return item
    })
  }

  deSelectAll() {
    this.items = this.items.map( item => {
      item.visible_on_site = false;
      return item
    })
  }

  moveTo(index, newPosition) {
    if (newPosition < 1 || newPosition > this.items.length)
      return this.toastService.error('Posizione non valida')

    newPosition--;

    const itemToMove = this.items[index];
    this.items.splice(index, 1);
    this.items.splice(newPosition, 0, itemToMove);
  }
}
