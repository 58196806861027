import {ContactLight} from '@models';

export class Cover {
  id?: number;
  name?: string;
  is_principal?: any;
  HR?: any;
  type?: string;
  photographer_code?: string;
  photographer_id?: number;
  photographer: ContactLight;
  note: string;
  path?: string;
  order_number?: number;
  file?: any;
  publication_id?: number;

  metadata?: {
    data_updated?: boolean;
    file_changed?: boolean;
    checked_for_portfolio?: boolean;
    loading?: boolean;
    size_error?: boolean;
    has_error?: boolean;
    err_message?: string;
    succeeded?: boolean;
  };

  constructor(cover: Partial<Cover> = {}) {
    Object.assign(this, cover);

    this.is_principal = !!cover.is_principal;
    this.HR = !!cover.HR;

    if(cover && cover.metadata)
      this.metadata.loading = false;

    if(!cover.metadata)
      this.metadata = {};
  }
}
