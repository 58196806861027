import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Work} from '@models';

@Component({
  selector: 'section-portfolio',
  templateUrl: './section-portfolio.component.html',
  styleUrls: ['./section-portfolio.component.scss']
})
export class SectionPortfolioComponent implements OnInit, OnChanges{

  @Input() imagesForLabels: Work[] = [];
  @Input() selectedOption;
  @Input() language;

  constructor() {
  }

  ngOnInit() {
    this.imagesForLabels = this.imagesForLabels.map(work => {
      return work = new Work(work);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.imagesForLabels = this.imagesForLabels.map(work => {
      return work = new Work(work);
    });
  }

  getUnit(unit) {
    if (this.language === 'ita') {
      if (unit === 'overall') {
        return 'tot.';
      } else if (unit === 'each') {
        return 'cad.';
      } else {
        return '';
      }
    }

    if (this.language === 'eng') {
      if (unit === 'overall') {
        return 'overall';
      } else if (unit === 'each') {
        return 'each';
      } else {
        return '';
      }
    }

    if (this.language === 'both') {
      if (unit === 'overall') {
        return 'tot./overall';
      } else if (unit === 'each') {
        return 'cad./each';
      } else {
        return '';
      }
    }
    return '';
  }

  getName(data){
    if (!data)
      return;

    if (data.denomination)
      return data.denomination;

    let name = '';
    if (data.name)
      name += data.name + ' ';

    if (data.lastname)
      name += data.lastname;

    return name;
  }
}
