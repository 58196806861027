
<div class="search-input">
  <form #form="ngForm" (ngSubmit)="onSubmit($event)" novalidate fxLayout="row"  fxLayoutAlign="start center">
    <input type="text" name="model" [placeholder]="placeholder" [(ngModel)]="model" [class.padded]="enableClear && modelValue"
           (keyup)="onKeyup($event)" (submit)="onSubmit($event)" [autocomplete]="autocomplete()">
  </form>
      <div class="clear" *ngIf="enableClear" [class.hidden]="!modelValue">
        <mat-icon (click)="clear()">clear</mat-icon>
      </div>
</div>

