
export class FairLight {

  id?: number;
  title?: string;

  constructor(fairLight: Partial<FairLight> = {}) {
    Object.assign(this, fairLight);
  }

}
