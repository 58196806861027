import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmComponent } from '../confirm/confirm.component';
import {DomSanitizer} from '@angular/platform-browser';
import {ExhibitionsService} from '../../../modules/exhibitions/exhibitions.service';
import {Document} from '@models';
import {FairsService} from '../../../modules/fairs/fairs.service';
import {PublicationsService} from '../../../modules/publications/publications.service';
import {ProjectRoomsService} from '../../../modules/project-rooms/project_rooms.service';
import {ContactService} from '../../../modules/contacts/contact.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageSize, ThumbnailsPosition} from 'ng-gallery';

@Component({
  selector: 'str-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  loading: boolean;
  image: any;
  error: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private sanitizer: DomSanitizer,
    private exhibitionService: ExhibitionsService,
    private contactService: ContactService,
    private publicationsService: PublicationsService,
    private projectRoomsService: ProjectRoomsService,
    private fairsService: FairsService,
    public dialogRef: MatDialogRef<ConfirmComponent>
  ) {}

  ngOnInit() {}

  abort() {
    this.dialogRef.close();
  }

  getSelectedFile($event) {
    let files = $event.target.files;
    if (files.length == 0)
      return;
    this.image = files[0];
    const file = files[0];
    this.convertToDataUrl(file, (base64string) => {
      this.image.path = base64string;
    });
  }

  convertToDataUrl(file, callback) {
    let reader = new FileReader();
    reader.addEventListener('load', function () {
      let binaryString = reader.result;
      callback(binaryString);

    }, false);
    reader.readAsDataURL(file);
  }

  upLoadToServer() {
    this.loading = true;
    const formData = new FormData();

    formData.set('file', this.image, this.image.name);
    formData.append('name', this.image.name);
    formData.append('type', this.image.type);

    if (this.data.apiPath.includes('fair'))
      this.updateDocument(formData, this.fairsService, this.data.item_id);

    if (this.data.apiPath.includes('exhibition'))
      this.updateDocument(formData, this.exhibitionService, this.data.item_id);

    if (this.data.apiPath.includes('publication'))
      this.updateDocument(formData, this.publicationsService, this.data.item_id);

    if (this.data.apiPath.includes('project'))
      this.updateDocument(formData, this.projectRoomsService, this.data.item_id);

    if (this.data.apiPath.includes('contact'))
      this.updateDocument(formData, this.contactService, this.data.item_id);
  }

  getThumbnail(image) {
    if (!image) {
      return null;
    }
    if (image.path) {
      return this.sanitizer.bypassSecurityTrustStyle(`url(\'${image.path}\')`);
    }
  }

  updateDocument(formData, service, id) {
    service.updateImage(formData, id).subscribe(
      (res: any) => {
        const newDocument = new Document(res);
        this.loading = false;
        this.dialogRef.close(newDocument);
      },
      exception => {
        this.loading = false;
        this.error = exception.data.error.message;
      }
    );
  }


}
