
export class Video {

  id?: number;
  description?: string;
  description_eng?: string;
  link?: string;
  reference_id: string;
  artist_id: string;
  exhibition_id: string;

  constructor(video: Partial<Video> = {}) {
    Object.assign(this, video);

  }

}
