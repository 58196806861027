import {Injectable} from '@angular/core';
import {ResourceService} from '@services';
import {Publication} from '@models';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PublicationsService extends ResourceService<Publication> {

  API_PATH = 'publications';
  MODEL = Publication ;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

}

