<mat-nav-list [ngClass]="{'small': isMediumDevice, 'not-small': !isMediumDevice}">

  <a mat-list-item [routerLink]="['/admin/contacts']" [queryParams]="{t: 'all'}"  [class.active]="isLinkActive('/admin/contacts')">
    <mat-icon svgIcon="contacts"></mat-icon> <span class="nav-caption" *ngIf="!isMediumDevice">anagrafica</span>
  </a>

  <a mat-list-item [routerLink]="['/admin/works']" [queryParams]="{t: 'all'}"  [class.active]="isLinkActive('/admin/works')">
    <mat-icon svgIcon="works"></mat-icon> <span class="nav-caption" *ngIf="!isMediumDevice">opere</span>
  </a>

  <a mat-list-item [routerLink]="['/admin/publications']" [queryParams]="{t: 'all'}"  [class.active]="isLinkActive('/admin/publications')">
    <mat-icon svgIcon="publications"></mat-icon> <span class="nav-caption" *ngIf="!isMediumDevice">pubblicazioni</span>
  </a>

  <a mat-list-item routerLink="/admin/exhibitions" [queryParams]="{t: 'all'}"  [class.active]="isLinkActive('/admin/exhibitions')">
    <mat-icon svgIcon="exhibitions"></mat-icon> <span class="nav-caption" *ngIf="!isMediumDevice">mostre</span>
  </a>

  <a mat-list-item routerLink="/admin/project_rooms" [queryParams]="{t: 'all'}"  [class.active]="isLinkActive('/admin/project_rooms')">
    <mat-icon><img style="width: 21px" src="/assets/img/icons/projectroom.png"></mat-icon>
    <span class="nav-caption" *ngIf="!isMediumDevice">project rooms</span>
  </a>


  <a mat-list-item routerLink="/admin/fairs" [queryParams]="{t: 'all'}"  [class.active]="isLinkActive('/admin/fairs')">
    <mat-icon svgIcon="fairs"></mat-icon> <span class="nav-caption" *ngIf="!isMediumDevice">fiere e news</span>
  </a>

  <a mat-list-item routerLink="/admin/calendar" routerLinkActive="active">
    <mat-icon svgIcon="calendar"></mat-icon> <span class="nav-caption" *ngIf="!isMediumDevice">calendario</span>
  </a>

  <a mat-list-item routerLink="/admin/slots" routerLinkActive="active">
    <mat-icon svgIcon="add_group"></mat-icon> <span class="nav-caption" *ngIf="!isMediumDevice">homepage slots</span>
  </a>

  <a mat-list-item routerLink="/admin/homepage-covers" routerLinkActive="active">
    <mat-icon svgIcon="add_group"></mat-icon> <span class="nav-caption" *ngIf="!isMediumDevice">homepage cover</span>
  </a>

  <a mat-list-item class="settings" (click)="openSettingsModal()" routerLinkActive="active">
    <mat-icon svgIcon="prv-settings"></mat-icon> <span *ngIf="!isMediumDevice">IMPOSTAZIONI</span>
  </a>

  <a mat-list-item class="logout" (click)="logout()">
    <mat-icon>exit_to_app</mat-icon> <span class="nav-caption" *ngIf="!isMediumDevice">logout</span>
  </a>

</mat-nav-list>
