import {Component, Inject, OnDestroy} from '@angular/core';
import {IConfirmData} from '../../dialog/dialog.service';
import {Work} from '@models';
import {ToastService} from '../toast/toast.service';
import {LocalStorageService, NavigationService} from '@services';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-settings-label-modal',
  templateUrl: './settings-label-modal.component.html',
  styleUrls: ['./settings-label-modal.component.scss']
})

export class SettingsLabelModalComponent implements OnDestroy{

  n_columns = 2;
  n_rows = 2;
  section = 0;
  works: Work[] = [];
  printRangeSelection = 'all';
  from = 1;
  to = 1;
  print_format = '2x2';
  selectedOption = 'cm';
  language = 'ita';
  language_format = 'ita';
  navigationSubscription: Subscription;
  subTitlePrintStartChoice = '';
  startColumn = 1;
  startRow = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<SettingsLabelModalComponent>,
    public toastService: ToastService,
    public navigationService: NavigationService,
    public localStorageService: LocalStorageService,
) {
    this.works = this.data.item;
    this.language = this.localStorageService.getLanguage();
    this.navigationSubscription = this.navigationService.currentMeasurementUnitObservable.subscribe((change) => {
      this.selectedOption = change;
    });
    this.selectedOption = this.localStorageService.getMeasurementUnit();

  }

  abort() {
    this.dialogRef.close(false);
  }

  confirm() {
    let valid = this.checkCorrectCoordinate();
    if (!valid) {
      this.toastService.error('Coordinate errate');
      return;
    }
    this.setRowsAndColumns();

    if (this.printRangeSelection === 'all') {
      const rangeData = this.setBlankSpaceForInitialCoordinatePrint(this.works);
      this.dialogRef.close({
        rows: this.n_rows,
        cols: this.n_columns,
        allData: this.works,
        rangeData: rangeData,
        language_format: this.language_format});

    } else {
      let validRange = (this.from > 0) && (this.from <= this.works.length) && (this.to > 0) && (this.to <= this.works.length) && (this.to >= this.from);
      if (!validRange) {
        this.toastService.error('Intervallo non valido');
      } else {
        let dataForMatrix = this.getDataRange();
        let dataForMatrixWithDummyData = this.setBlankSpaceForInitialCoordinatePrint(dataForMatrix);
        this.dialogRef.close({
          rows: this.n_rows,
          cols: this.n_columns,
          allData: dataForMatrix,
          rangeData: dataForMatrixWithDummyData,
          language_format: this.language_format
        });
      }
    }
  }

  setBlankSpaceForInitialCoordinatePrint(works) {
    let updatedWorks = [...works];
    if (this.startRow > 1) {
      const number = this.startRow - 1;
      let emptyItemToAddForRow = number * this.n_columns;
      while (emptyItemToAddForRow > 0) {
        updatedWorks.unshift(null);
        emptyItemToAddForRow--;
      }
    }
    if (this.startColumn > 1) {
      let emptyItemToAddForColumn = this.startColumn - 1;
      while (emptyItemToAddForColumn > 0) {
        updatedWorks.unshift(null);
        emptyItemToAddForColumn--;
      }
    }

    return updatedWorks;
  }

  checkCorrectCoordinate() {
    if (this.print_format === '2x2') {
      if (!(this.startColumn > 0 && this.startColumn < 3)) {
        return false;
      }
      if (!(this.startRow > 0 && this.startRow < 3)) {
        return false;
      }
    } else if (this.print_format === '6x2') {
      if (!(this.startColumn > 0 && this.startColumn < 3)) {
        return false;
      }
      if (!(this.startRow > 0 && this.startRow < 7)) {
        return false;
      }

    } else if (this.print_format === '7x3') {
      if (!(this.startColumn > 0 && this.startColumn < 4)) {
        return false;
      }
      if (!(this.startRow > 0 && this.startRow < 8)) {
        return false;
      }

    } else if (this.print_format === '8x3') {
      if (!(this.startColumn > 0 && this.startColumn < 4)) {
        return false;
      }
      if (!(this.startRow > 0 && this.startRow < 9)) {
        return false;
      }
    }
    return true;
  }

  setRowsAndColumns() {
    if (this.print_format === '2x2') {
      this.n_rows = 2;
      this.n_columns = 2;
    } else if (this.print_format === '6x2') {
      this.n_rows = 6;
      this.n_columns = 2;
    } else if (this.print_format === '7x3') {
      this.n_rows = 7;
      this.n_columns = 3;
    } else if (this.print_format === '8x3') {
      this.n_rows = 8;
      this.n_columns = 3;
    }
  }

  getDataRange() {
    return this.works.slice(this.from-1, this.to);
  }

  prev() {
    this.section--;
  }

  next() {
    this.section++;
  }

  nextToSecond() {
    if (this.print_format === '2x2') {
      this.subTitlePrintStartChoice = 'Intervallo: righe e colonne da 1 a 2';
    } else if (this.print_format === '6x2') {
      this.subTitlePrintStartChoice = 'Intervallo: righe da 1 a 6 e colonne da 1 a 2';
    } else if (this.print_format === '7x3') {
      this.subTitlePrintStartChoice = 'Intervallo: righe da 1 a 7 e colonne da 1 a 3';
    } else if (this.print_format === '8x3') {
      this.subTitlePrintStartChoice = 'Intervallo: righe da 1 a 8 e colonne da 1 a 3';
    }
    this.section++;
  }

  changeImageSource(imageTag) {
    imageTag.src = 'assets/img/placeholder-img.jpg';
  }

  moveDown(index) {
    let oldElement = this.works[index+1];
    this.works[index+1] = this.works[index];
    this.works[index] = oldElement;
  }

  moveUp(index) {
    let oldElement = this.works[index-1];
    this.works[index-1] = this.works[index];
    this.works[index] = oldElement;
  }

  getUnit(unit) {
    if (this.language === 'ita') {
      if (unit === 'overall') {
        return 'tot.';
      } else if (unit === 'each') {
        return 'cad.';
      } else {
        return '';
      }
    }

    if (this.language === 'eng') {
      if (unit === 'overall') {
        return 'overall';
      } else if (unit === 'each') {
        return 'each';
      } else {
        return '';
      }
    }

    if (this.language === 'both') {
      if (unit === 'overall') {
        return 'tot./overall';
      } else if (unit === 'each') {
        return 'cad./each';
      } else {
        return '';
      }
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription)
      this.navigationSubscription.unsubscribe();
  }

  getName(data){
    if (!data)
      return;

    if (data.denomination)
      return data.denomination;

    let name = '';
    if (data.name)
      name += data.name + ' ';

    if (data.lastname)
      name += data.lastname;

    return name;
  }
}
