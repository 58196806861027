import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmComponent} from './confirm/confirm.component';
import {DialogService} from './dialog.service';
import {AlertComponent} from './alert/alert.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MyMaterialModule} from '../../modules/my-material/my-material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AddGroupDialogComponent} from '../../modules/contacts/components/add-group-dialog/add-group-dialog.component';
import {AddWorkGroupDialogComponent} from '../../modules/works/components/add-work-group-dialog/add-work-group-dialog.component';
import {AddWorksToGroupDialogComponent} from '../../modules/works/components/add-works-to-group-dialog/add-works-to-group-dialog.component';
import {UploadFileComponent} from './upload-file/upload-file.component';
import {ExhibitionsService, ExhibitionsVideoService} from '../../modules/exhibitions/exhibitions.service';
import {FairsService} from '../../modules/fairs/fairs.service';
import {AddContactsToGroupDialogComponent} from '../../modules/contacts/components/add-contacts-to-group-dialog/add-contacts-to-group-dialog.component';
import {ExhibitionsSearchComponent} from '../../modules/exhibitions/components/exhibitions-search/exhibitions-search.component';
import {PublicationsSearchComponent} from '../../modules/publications/components/publications-search/publications-search.component';
import {FairsSearchComponent} from '../../modules/fairs/components/fairs-search/fairs-search.component';
import {PublicationsService} from '../../modules/publications/publications.service';
import {AddVideoComponent} from './add-video/add-video.component';
import {ContactVideoService} from '../../modules/contacts/contact.service';
import {WorksSearchComponent} from '../../modules/works/components/works-search/works-search.component';
import {SearchAndAddWorksToGroupDialogComponent} from '../../modules/works/components/search-and-add-works-to-group-dialog/search-and-add-works-to-group-dialog.component';
import {ProjectRoomsSearchComponent} from '../../modules/project-rooms/components/project_rooms-search/project-rooms-search.component';
import {ProjectRoomsService, ProjectRoomsVideoService} from '../../modules/project-rooms/project_rooms.service';
import {ContactsSearchComponent} from '../../modules/works/components/contacts-search/contacts-search.component';
import {DocumentSortingModalComponent} from '../components/document-sorting-modal/document-sorting-modal.component';
import {PersonSearchComponent} from '../../modules/contacts/components/person-search/person-search.component';
import {AddWorkToFolderDialogComponent} from '../../modules/works/components/add-work-to-folder-dialog/add-work-to-folder-dialog.component';
import {UploadImageComponent} from './upload-image/upload-image.component';

@NgModule({
    imports: [
        CommonModule,
        MyMaterialModule,
        FormsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
    ],
    declarations: [
        ConfirmComponent,
        AlertComponent,
        AddGroupDialogComponent,
        AddWorkGroupDialogComponent,
        AddWorksToGroupDialogComponent,
        AddWorkToFolderDialogComponent,
        SearchAndAddWorksToGroupDialogComponent,
        AddContactsToGroupDialogComponent,
        UploadFileComponent,
        ExhibitionsSearchComponent,
        PublicationsSearchComponent,
        WorksSearchComponent,
        FairsSearchComponent,
        ProjectRoomsSearchComponent,
        ContactsSearchComponent,
        AddVideoComponent,
        DocumentSortingModalComponent,
        PersonSearchComponent,
      UploadImageComponent,
    ],
    providers: [
        DialogService,
        ExhibitionsService,
        FairsService,
        PublicationsService,
        ExhibitionsVideoService,
        ContactVideoService,
        ProjectRoomsService,
        ProjectRoomsVideoService
    ],
    exports: []
})
export class DialogModule {
  static forRoot(): ModuleWithProviders<DialogModule> {
    return {
      ngModule: DialogModule,
      providers: [DialogService]
    };
  }
}
