import {Component} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'p420';

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon('contacts', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/anagrafica.svg'));
    iconRegistry.addSvgIcon('calendar', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/calendario.svg'));
    iconRegistry.addSvgIcon('documents', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/documenti.svg'));
    iconRegistry.addSvgIcon('fairs', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/fiere.svg'));
    iconRegistry.addSvgIcon('exhibitions', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/mostre.svg'));
    iconRegistry.addSvgIcon('newsletter', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/newsletter.svg'));
    iconRegistry.addSvgIcon('works', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/opere.svg'));
    iconRegistry.addSvgIcon('publications', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/pubblicazioni.svg'));
    iconRegistry.addSvgIcon('sticker', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/sticker.svg'));
    iconRegistry.addSvgIcon('vcard', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/vcard.svg'));
    iconRegistry.addSvgIcon('csv', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/csv.svg'));
    iconRegistry.addSvgIcon('excel', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/excel.svg'));
    iconRegistry.addSvgIcon('add_group', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/add_group.svg'));
    iconRegistry.addSvgIcon('pdf', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/pdf.svg'));
    iconRegistry.addSvgIcon('certificate', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/certificate.svg'));
    iconRegistry.addSvgIcon('prv-settings', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/settings.svg'));
  }

}
