/**
 * @Pipe
 * @description pipe to format numeric values to argentina readable currency values
 * @input number
 * @output formatted numeric value
 */

import { Pipe, PipeTransform } from '@angular/core';
import {LocalStorageService, NavigationService} from '@services';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  currency;

  constructor(
    private localStorageService: LocalStorageService,
    private navigationService: NavigationService,
  ) {
    this.currency = this.localStorageService.getCurrency();
    this.navigationService.currentCurrencyObservable.subscribe((change) => {
      this.currency = change;
    });
  }

  transform(value: any): string {
    const convertedValue = this.convertToCurrentCurrency(value);
    const formattedString = this.localeString(convertedValue);
    const symbol = this.getCurrencySymbol();

    return formattedString + ' ' + symbol;
  }

  convertToCurrentCurrency(value) {
    if (this.currency === 'BGP')
      return value * 0.883667;

    if (this.currency === 'USD')
      return value * 1.10074;

    return value
  }

  getCurrencySymbol() {
    switch (this.currency) {
      case 'USD':
        return '$';
      case 'BGP':
        return '£';
      default:
        return '€';
    }
  }

  missingOneDecimalCheck(nStr) {
    nStr += '';
    const x = nStr.split(',')[1];
    if (x && x.length === 1) return true;
    return false;
  }

  missingAllDecimalsCheck(nStr) {
    nStr += '';
    const x = nStr.split(',')[1];
    if (!x) return true;
    return false;
  }

  moreThanTwoDecimals(nStr) {
    nStr += '';
    const decimals = nStr.split(',')[1];
    if (decimals && decimals.length > 2) return true;
    return false;
  }

  truncateToTwoDecimals(nStr) {
    nStr += '';
    const integer = nStr.split(',')[0];
    const decimals = nStr.split(',')[1];
    if (decimals) {
      let approximateDecimal = decimals / (Math.pow(10,(decimals.length - 2)));
      const firstTwoDigits = approximateDecimal.toString().split('.')[0];
      return integer + ',' + firstTwoDigits;
    }
    return nStr;
  }

  localeString(nStr) {
    if (nStr === '') return '';
    let number, integer, decimals, rgx, y1, y2;
    nStr += '';
    number = nStr.split('.');
    integer = number[0];
    decimals = number.length > 1 ? ',' + number[1] : '';
    rgx = /(\d+)(\d{3})/;
    while (rgx.test(integer)) {
      integer = integer.replace(rgx, '$1' + '.' + '$2');
    }

    /** If value was inputed by user, it could have many decimals(up to 7)
     so we need to reformat previous x1 results */
    if (integer.indexOf(',') !== -1) {
      y1 = integer.slice(integer.lastIndexOf(',')).replace(/\./g, '');

      y2 = integer.split(',');
      number = y2[0] +  y1;
    } else {
      number = integer + decimals;
      if (this.missingOneDecimalCheck(number)) return number += '0';
      if (this.missingAllDecimalsCheck(number)) return number += ',00';
      if (this.moreThanTwoDecimals(number)) return this.truncateToTwoDecimals(number);
    }

    return number;
  }
}
