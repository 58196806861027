<div class="dialog-wrapper" fxLayout="column">

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between start">
    <h1 mat-dialog-title> {{title}}</h1>
  </div>

  <div class="print-table">
    <div fxLayoutAlign="start center"
         *ngFor="let item of installation_views; let index = index; let last = last" >

      <div fxLayoutAlign="start center" class="row-print">

        <div class="arrows">
          <mat-icon [ngClass]="{'disabled': last}" (click)="moveDown(index)">arrow_downward</mat-icon>
          <mat-icon [ngClass]="{'disabled': !(index > 0)}" (click)="moveUp(index)">arrow_upward</mat-icon>
        </div>

        <div class="index">
          {{index+1}}
        </div>

        <div class="work-icon-container">
          <img #imageTag class="work-icon" [src]="item?.path" (error)="changeImageSource(imageTag)">
        </div>

        <span class="nav-caption title">
          {{item?.name}}
        </span>


      </div>


    </div>

    <div fxLayoutAlign="start center" *ngIf="!installation_views?.length">
      <span class="nav-caption">Nessuna Installation Views</span>
    </div>

  </div>


  <div class="dialog-footer" fxLayoutAlign="end center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="abort()"> Annulla</button>
      <button mat-flat-button class="btn" (click)="confirm()"> Salva</button>
    </div>
  </div>

</div>



