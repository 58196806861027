import {Component, Inject} from '@angular/core';
import {IConfirmData} from '../../../../common/dialog/dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-fairs-search',
  templateUrl: './fairs-search.component.html',
  styleUrls: ['./fairs-search.component.scss']
})

export class FairsSearchComponent {

  title;
  work_title;
  artist;
  ajaxLoading = false;
  oldAdvanceSearch = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<FairsSearchComponent>
  ) {
    if (this.data['oldAdvanceSearch']){
      this.initOldSearch();
    }
  }

  initOldSearch() {
    this.oldAdvanceSearch = this.data['oldAdvanceSearch'];
    if (this.oldAdvanceSearch.title)
      this.title = this.oldAdvanceSearch.title;
    if (this.oldAdvanceSearch.work_title)
      this.work_title = this.oldAdvanceSearch.work_title;
    if (this.oldAdvanceSearch.artist)
      this.artist = this.oldAdvanceSearch.artist;
  }

  abort() {
    this.dialogRef.close(null);
  }

  search() {
    const data = new DataSearchFair;

    if (this.title)
      data.title = this.title;
    if (this.artist)
      data.artist = this.artist;
    if (this.work_title)
      data.work_title = this.work_title;

    this.dialogRef.close(data);
  }

}

class DataSearchFair {
  title: string;
  artist: string;
  work_title: string;
}
