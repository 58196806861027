import { Component, OnInit } from '@angular/core';
import {NavigationService} from '@services';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import { ResizeEvent } from 'angular-resizable-element';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  isSmallDevice = false;
  isMediumDevice = false;
  width = 220;
  left = 0;
  sideMenuCollapsed = true;

  constructor(private navigationService: NavigationService, private mediaObserver: MediaObserver) {

    mediaObserver.media$.subscribe((change: MediaChange) => {
      this.isSmallDevice = change.mqAlias === 'xs' || change.mqAlias === 'sm' ;
      this.isMediumDevice = change.mqAlias === 'md';
    });
  }

  ngOnInit() {
  }

  onResize(event: ResizeEvent): void {
    this.width = event.rectangle.right;
    this.left = event.rectangle.right;
  }
}
