import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConfirmData } from '../../../../common/dialog/dialog.service';
import {ToastService} from '../../../../common/components/toast/toast.service';
import {ContactGroupService} from '../../contact.service';
import {ContactGroup} from '@models';

@Component({
  selector: 'add-contacts-to-group-dialog',
  templateUrl: './add-contacts-to-group-dialog.component.html',
  styleUrls: ['./add-contacts-to-group-dialog.component.scss']
})
export class AddContactsToGroupDialogComponent {
  ajaxLoading = false;
  newGroupName: string = '';
  groups: ContactGroup[] = [];
  groupSelected;
  contactIdsSelected;

  constructor(
    private toastService: ToastService,
    private contactGroupService: ContactGroupService,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<AddContactsToGroupDialogComponent>
  ) {}

  ngOnInit() {
    if(this.data.item)
      this.contactIdsSelected = this.data.item;

    this.getAllContactGroups();
  }

  abort() {
    this.dialogRef.close(false);
  }

  changeGroupSelected() {
    this.newGroupName = ''
  }

  changeNewGroupName() {
    this.groupSelected = null
  }

  compareObjects(obj1, obj2): boolean {
    if(obj1 && obj2) {
      return obj1.id === obj2.id;
    }
    return false;
  }

  getAllContactGroups(){
    this.contactGroupService.getList().subscribe( (groups: any) => {
        this.groups = groups;
      },
      () => {})
  }

  save() {
    let data;
    let createdNewGroup = this.newGroupName && this.newGroupName !== '';
    if (createdNewGroup) {
      data = {
        contact_ids: this.contactIdsSelected,
        name: this.newGroupName
      };
    } else {
      data = {
        contact_ids: this.contactIdsSelected,
        group_id: this.groupSelected.id
      };
    }

    this.ajaxLoading = true;
      this.contactGroupService.assignContactsToGroup(data).subscribe(group => {
          this.ajaxLoading = false;
          this.toastService.success('Contatto aggiunto al gruppo con successo');

        if (createdNewGroup) {
          this.dialogRef.close(group);
        } else {
          this.dialogRef.close(null);
        }

        },
        () => {
          this.ajaxLoading = false;
          this.toastService.error('Si è verificato un problema');
        })
  }

}
