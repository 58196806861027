import {Component, OnInit, OnDestroy, Input, ElementRef, Renderer2} from '@angular/core';

@Component({
  selector: 'p420-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  @Input() position;
  @Input() color;
  @Input() minHeight;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.position === 'relative') {
      this.renderer.setStyle(
        this.elRef.nativeElement.parentElement,
        'position',
        'relative'
      );
      this.renderer.setStyle(
        this.elRef.nativeElement.parentElement,
        'min-height',
        this.minHeight ? this.minHeight + 'px' : '300px'
      );
    }
    this.color = this.color || '#3e2723';
  }

  ngOnDestroy() {
    if (this.position === 'relative') {
      this.renderer.removeStyle(
        this.elRef.nativeElement.parentElement,
        'position'
      );
      this.renderer.removeStyle(
        this.elRef.nativeElement.parentElement,
        'min-height'
      );
    }
  }
}
