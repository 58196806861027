import {Contact, ContactGroup, Video} from '@models';
import {Injectable} from '@angular/core';
import {ResourceService} from '@services';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class ContactService extends ResourceService<Contact> {

  API_PATH = 'contacts';
  MODEL = Contact;
  onShowDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  saveWorksPipeline(id, data) {
    return this.httpClient.put(environment.apiBaseUrl + '/' + this.API_PATH + '/' + id + '/save_pipeline', data);
  }

  deleteRelatedItem(id: number, path) {
    return this.httpClient.delete(environment.apiBaseUrl + '/' + path + '/' + id);
  }

}

@Injectable()
export class ContactGroupService extends ResourceService<ContactGroup> {

  API_PATH = 'contact_groups';
  MODEL = ContactGroup ;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  assignContactsToGroup(data) {
    return this.httpClient.put(environment.apiBaseUrl + '/contact_groups_assign_contacts' , data);
  }

}

@Injectable()
export class ArtistService extends ResourceService<Contact> {

  API_PATH = 'get_only_contacts_with_works_light';
  MODEL = ContactGroup ;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

}

@Injectable()
export class ContactVideoService extends ResourceService<Video> {

  API_PATH = 'contact_videos';
  MODEL = Video;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

}
