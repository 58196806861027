import {Exhibition, Image, ProjectRoom} from '@models';

export class HomepageCover {

  id?: number;
  exhibition?: Exhibition;
  project_room?: ProjectRoom;
  project_room_id?: number;
  exhibition_id?: number;
  title: string;
  main_cover_image: string;
  cover?: Image;

  constructor(homepageCover: Partial<HomepageCover> = {}) {
    Object.assign(this, homepageCover);
    if (this.project_room) {
      this.title = this.project_room.title
      this.cover = this.project_room.cover || null;
    }
    if (this.exhibition) {
      this.title = this.exhibition.title
      this.cover = this.exhibition.cover || null;
    }
  }

}
