import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConfirmData } from '../dialog.service';
@Component({
  selector: 'grl-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<ConfirmComponent>
  ) {}
  done() {
    this.dialogRef.close(true);
  }

  abort() {
    this.dialogRef.close(false);
  }
}
