import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConfirmData } from '../dialog.service';
import { ConfirmComponent } from '../confirm/confirm.component';


@Component({
  selector: 'grl-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IConfirmData, public dialogRef: MatDialogRef<ConfirmComponent>) { }
  hide() {
    this.dialogRef.close(true);
  }

}
