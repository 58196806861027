import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-second-sidenav',

  template: `
    <div class="second-sidenav-container" [ngClass]="{'third': third}">
      <ng-content></ng-content>
    </div>
  `,

  styleUrls: ['./second-sidenav.component.scss']
})
export class SecondSidenavComponent implements OnInit {

  @Input() third: boolean;

  constructor() { }

  ngOnInit() {
  }


}
