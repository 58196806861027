import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConfirmData } from '../../../../common/dialog/dialog.service';
import {ToastService} from '../../../../common/components/toast/toast.service';
import {ContactGroupService} from '../../contact.service';
import {ContactGroup} from '@models';

@Component({
  selector: 'add-group-dialog',
  templateUrl: './add-group-dialog.component.html',
  styleUrls: ['./add-group-dialog.component.scss']
})
export class AddGroupDialogComponent {
  ajaxLoading = false;
  group: ContactGroup = new ContactGroup(null);

  constructor(
    private toastService: ToastService,
    public contactGroupService: ContactGroupService,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<AddGroupDialogComponent>
  ) {}

  ngOnInit() {
    if(this.data.item)
      this.group = {...this.data.item};
  }

  abort() {
    this.dialogRef.close(false);
  }

  saveGroup() {
    this.ajaxLoading = true;
      this.contactGroupService.save(this.group).subscribe(group => {
          this.ajaxLoading = false;
          this.toastService.success('Gruppo salvato con successo');
          this.dialogRef.close(this.group);
        },
        (err) => {
          this.ajaxLoading = false;
          if (err.status === 405) {
            this.toastService.error('Elemento in aggiornamento, non può essere modificato');
          } else {
            this.toastService.error('Si è verificato un problema');
          }
        })
  }
}
