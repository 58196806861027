import {Component, Inject} from '@angular/core';
import {IConfirmData} from '../../../../common/dialog/dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-publications-search',
  templateUrl: './publications-search.component.html',
  styleUrls: ['./publications-search.component.scss']
})

export class PublicationsSearchComponent {

  title;
  author;
  artist;
  year;
  year_operator = '>';
  ajaxLoading = false;
  oldAdvanceSearch = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<PublicationsSearchComponent>
  ) {
    if (this.data['oldAdvanceSearch']){
      this.initOldSearch();
    }
  }

  initOldSearch() {
    this.oldAdvanceSearch = this.data['oldAdvanceSearch'];
    if (this.oldAdvanceSearch.title)
      this.title = this.oldAdvanceSearch.title;
    if (this.oldAdvanceSearch.author)
      this.author = this.oldAdvanceSearch.author;
    if (this.oldAdvanceSearch.artist)
      this.artist = this.oldAdvanceSearch.artist;
    if (this.oldAdvanceSearch.year)
      this.year = this.oldAdvanceSearch.year;
    if (this.oldAdvanceSearch.year_operator)
      this.year_operator = this.oldAdvanceSearch.year_operator;
  }

  abort() {
    this.dialogRef.close(null);
  }

  search() {
    const data = new DataSearchPublication;

    if (this.title)
      data.title = this.title;
    if (this.author)
      data.author = this.author;
    if (this.artist)
      data.artist = this.artist;
    if (this.year)
      data.year = this.year;
    if (this.year_operator)
      data.year_operator = this.year_operator;

    this.dialogRef.close(data);
  }
}

class DataSearchPublication {
  title: string;
  author: string;
  artist: string;
  year: string;
  year_operator: string;
}
