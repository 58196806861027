<form (ngSubmit)="submitSearch()" class="global-search-form">

  <mat-form-field floatLabel="never">

    <input matInput placeholder="Cerca nel sito" [matAutocomplete]="searchAutoComplete" [formControl]="stateCtrl">
    <mat-autocomplete #searchAutoComplete="matAutocomplete">
      <mat-optgroup *ngFor="let table of searchResults" [label]="getGroupLabel(table.type)">
        <mat-icon svgIcon="{{getGroupIcon(table.type)}}"></mat-icon>
        <mat-option *ngFor="let data of table.data" [value]="data.title ? data.title : getTitle(data)"
                    (click)="navigate(table, data.id)">
            {{data.title ? data.title : getTitle(data)}}
        </mat-option>
      </mat-optgroup>

      <mat-optgroup *ngIf="!searchResults.length" [label]="loadinResults ? 'Ricerca in corso...' : 'Nessun risultato'">
        <mat-option style="display: none"></mat-option>
      </mat-optgroup>

    </mat-autocomplete>
  </mat-form-field>
</form>
