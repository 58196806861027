<div class="dialog-wrapper" fxLayout="column">

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between center">
    <h1 mat-dialog-title> {{title}}</h1>

    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="abort()"> Annulla</button>
      <button mat-flat-button class="btn" (click)="confirm()"> Salva</button>
    </div>
  </div>

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="selectAll()">Seleziona Tutto</button>
      <button mat-flat-button class="btn" (click)="deSelectAll()">Deseleziona Tutto</button>
    </div>
  </div>

  <div class="print-table">
    <div fxLayoutAlign="start center"
         *ngFor="let work of works; let index = index; let last = last" >

      <div fxLayoutAlign="start center" class="row-print" [ngClass]="{'opacity': !work.selected && selectableWorks}">

        <div class="toggle" *ngIf="selectableWorks">
          <mat-slide-toggle [(ngModel)]="work.selected"></mat-slide-toggle>
        </div>

        <div class="arrows">
          <mat-icon [ngClass]="{'disabled': last}" (click)="moveDown(index)">arrow_downward</mat-icon>
          <mat-icon [ngClass]="{'disabled': !(index > 0)}" (click)="moveUp(index)">arrow_upward</mat-icon>
        </div>

        <div class="index">
          {{index+1}}
        </div>

        <div class="moveToSection" >
          <mat-icon [ngClass]="{'disabled': !work.newPosition || work.newPosition < 1 || work.newPosition > works.length}" (click)="moveTo(index, work.newPosition)">low_priority</mat-icon>
          <input matInput name="newPosition_{{index}}" [(ngModel)]="work.newPosition" type="number">
        </div>

        <div class="work-icon-container">
          <img #imageTag class="work-icon" [src]="work?.cover?.path" (error)="changeImageSource(imageTag)">
        </div>

        <span class="nav-caption title">
          {{(work?.authors?.length ? getName(work?.authors[0]) + ',' : '')}}
          {{(work?.title ? work?.title : '--') + ', '}}
          {{(work?.year ? work?.year + ', ': '')}}
          {{(work?.technique_ita ? work?.technique_ita + (work?.technique_eng ? ' /' : '') : '')}}
          {{(work?.technique_eng ? work?.technique_eng + ', ' : '')}}

          <span *ngIf="work?.dimensions?.height || work?.dimensions?.width || work?.dimensions?.depth">
            {{work?.dimensions?.height | toInch:selectedOption}}
            x {{work?.dimensions?.width | toInch:selectedOption}}
            {{work?.dimensions?.depth ? 'x ' + (work?.dimensions?.depth | toInch:selectedOption) : ''}}
            {{work?.dimensions?.height || work?.dimensions?.width ? selectedOption : ''}}
            {{work?.dimensions?.height || work?.dimensions?.width ? getUnit(work?.dimensions?.overall_unit) : ''}}
          </span>

          <span *ngIf="work?.dimensions?.work_duration">{{work?.dimensions?.work_duration}}</span>

          {{' ' + (work?.edition || '')}}
          </span>


      </div>




    </div>

    <div fxLayoutAlign="start center" *ngIf="!works?.length">
      <span class="nav-caption">Nessuna Opera</span>
    </div>

  </div>


  <div class="dialog-footer" fxLayoutAlign="end center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="abort()"> Annulla</button>
      <button mat-flat-button class="btn" (click)="confirm()"> Salva</button>
    </div>
  </div>

</div>



