import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {remove} from 'lodash';
import {LocalStorageService, NavigationService} from '@services';
import {Subscription} from 'rxjs';
import {Gallery, GalleryItem, ImageSize, ThumbnailsPosition} from 'ng-gallery';
import {Lightbox} from 'ng-gallery/lightbox';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() images: GalleryItem[] = [];
  galleryImages: GalleryItem[] = [];
  @Input() item;
  @Input() noCheckbox = false;
  @Input() noIcons = false;
  @Input() deleteIcon = false;
  @Output() toggleImage: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeImage: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleShowWorkDetail: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectAll: boolean;
  @Input() selectedWorks = [];
  selectedOption = 'cm';
  language = 'ita';
  navigationSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    private gallery: Gallery,
    private lightbox: Lightbox,
    private localStorageService: LocalStorageService,
    private navigationService: NavigationService,
  ) {
  }

  ngOnInit() {
    this.navigationSubscription = this.navigationService.currentMeasurementUnitObservable.subscribe((change) => {
      this.selectedOption = change;
    });
    this.selectedOption = this.localStorageService.getMeasurementUnit();

    this.languageSubscription = this.navigationService.currentLanguageObservable.subscribe((change) => {
      this.language = change;
    });
    this.language = this.localStorageService.getLanguage();

    this.updateImages();
  }

  escapeCharactersForUrl(string) {


    // string = string.replaceAll("(","\(");
    // string = string.replaceAll(")","\)");
    // string = string.replaceAll(":","\:");
    // string = string.replaceAll(" ","%20");
    // string = string.replaceAll("’","%E2%80%99");
    // string = string.replaceAll("<","%3C");
    // string = string.replaceAll(">","%3E");
    // string = string.replaceAll("#","%23");
    // string = string.replaceAll("%","%25");
    // string = string.replaceAll("+","%2B");
    // string = string.replaceAll("{","%7B");
    // string = string.replaceAll("}","%7D");
    // string = string.replaceAll("|","%7C");
    // string = string.replaceAll("\\","%5C");
    // string = string.replaceAll("^","%5E");
    // string = string.replaceAll("~","%7E");
    // string = string.replaceAll("[","%5B");
    // string = string.replaceAll("]","%5D");
    // string = string.replaceAll(";","%3B");
    // // string = string.replaceAll("/","%2F");
    // string = string.replaceAll("@","%40");
    // string = string.replaceAll("$","%24");

    // string = string.replaceAll(":","%3A");
    // string = string.replace("%3A",":"); // replace first :  in http://


    return string
  }

  updateImages() {
    if (this.selectAll === true) {
      this.images.forEach(image => {
        this.parseImagePaths(image);
        image.data.checked_for_portfolio = true;
      });
    } else {
      this.images.forEach(image => {
        this.parseImagePaths(image);
        image.data.checked_for_portfolio = false;
      });
    }
    this.images = [...this.images];
  }

  parseImagePaths(image) {
    if (image.data.src) image.data.src = this.escapeCharactersForUrl(image.data.src);
    if (image.data.thumb) image.data.thumb = this.escapeCharactersForUrl(image.data.thumb);
    if (image.data.thumbnail) image.data.thumbnail = this.escapeCharactersForUrl(image.data.thumbnail);
    if (image.data.original_path) image.data.original_path = this.escapeCharactersForUrl(image.data.original_path);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateImages();

    this.selectedWorks.forEach(work => {
      this.images.forEach(image => {
        if (work.image) {
          if (image.data['id'] === work.image.id)
            image.data.checked_for_portfolio = true;
        } else if (work.cover) {
          if (image.data['id'] === work.cover.id)
            image.data.checked_for_portfolio = true;
        }
      });
    });
  }

  openItem(index) {
    this.updateImages();
    this.loadNex30Images(index);
    if (this.images.length > 30) {
      this.lightbox.open(0, 'anotherLightbox');
    } else {
      this.lightbox.open(index, 'anotherLightbox');
    }
  }

  loadNex30Images(index) {
    this.galleryImages = [];
    let i = index;

    this.images.forEach(image => {
      this.parseImagePaths(image);
    });

    if (this.images.length > 30) {
      while (i < index + 30 && i < this.images.length) {
        this.galleryImages.push(this.images[i]);
        i++;
      }
    } else {
      this.galleryImages = [...this.images];
    }
    this.resetAndLoad();
  }

  resetAndLoad() {
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
    });
    lightboxGalleryRef.reset();
    lightboxGalleryRef.load(this.galleryImages);
  }

  changeImageSource(imageTag) {
    imageTag.src = 'assets/img/placeholder-img.jpg';
  }

  selectedCheckbox(event, index) {
    this.toggleImage.emit({
      item: this.item,
      checked: event,
      index: index
    });
  }

  removeFromList(item, index) {
    remove(this.images, (image) => {
      return image.data.id === item.data.id;
    });
    this.removeImage.emit({
      item: item,
      index: index
    });
  }

  goToDetail(image) {
    this.toggleShowWorkDetail.emit(image.data);
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription)
      this.navigationSubscription.unsubscribe();
    if (this.languageSubscription)
      this.languageSubscription.unsubscribe();
  }

  seeOriginPhoto(path, name) {
    let link = document.createElement("a");
    link.download = name;
    link.target = '_blank';
    link.href = path;
    link.click();
  }

  getName(data){
    if (!data)
      return;

    if (data.denomination)
      return data.denomination;

    let name = '';
    if (data.name)
      name += data.name + ' ';

    if (data.lastname)
      name += data.lastname;

    return name;
  }
}
