import {Component, Inject, OnDestroy} from '@angular/core';
import {ToastService} from '../toast/toast.service';
import {LocalStorageService, NavigationService} from '@services';
import {Cover} from '@models';
import {findIndex, remove} from 'lodash';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-installation-views-sorting-modal',
  templateUrl: './installation-views-sorting-modal.component.html',
  styleUrls: ['./installation-views-sorting-modal.component.scss']
})

export class InstallationViewsSortingModalComponent implements OnDestroy{

  installation_views: Cover[] = [];
  item;
  selectableItems = false;
  installation_views_pipeline;
  selectedOption = 'cm';
  language = 'ita';
  title = 'Scegli nuovo ordine';
  navigationSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<InstallationViewsSortingModalComponent>,
    public toastService: ToastService,
    public navigationService: NavigationService,
    public localStorageService: LocalStorageService,
) {
    this.installation_views = this.data.item;
    this.selectableItems = this.data.selectableItems;
    this.installation_views_pipeline = this.data.installation_views_pipeline;
    if (this.installation_views_pipeline) {
      this.applyPipeline();
    }
    this.language = this.localStorageService.getLanguage();
    this.selectableItems ? this.title = 'Scegli nuovo ordine e deseleziona le installation ' +
      'views da non visualizzare' : this.title = 'Scegli nuovo ordine';
    this.navigationSubscription = this.navigationService.currentMeasurementUnitObservable.subscribe(
      (change) => {
      this.selectedOption = change;
    });
    this.selectedOption = this.localStorageService.getMeasurementUnit();
  }

  applyPipeline() {
    const pipeline = this.installation_views_pipeline.split(',');
    const selectedItems = [];
    const array = [...this.installation_views];
    pipeline.forEach( id => {
      const workToMove = remove(array, (work) => {
        return work.id == id;
      });
      if (workToMove.length) {
        selectedItems.push(workToMove[0]);
      }
    });

    const newArray = selectedItems.concat(array);
    this.installation_views = [...newArray];
  }

  abort() {
    this.dialogRef.close(false);
  }

  confirm() {
    const pipeline = [];
    this.installation_views.forEach( item => {
      let isAlreadyPresent = findIndex(pipeline, item.id);
      if (isAlreadyPresent == -1)
        pipeline.push(item.id);
    });
    this.dialogRef.close(pipeline);
  }

  changeImageSource(imageTag) {
    imageTag.src = 'assets/img/placeholder-img.jpg';
  }

  moveDown(index) {
    let oldElement = this.installation_views[index+1];
    this.installation_views[index+1] = this.installation_views[index];
    this.installation_views[index] = oldElement;
  }

  moveUp(index) {
    let oldElement = this.installation_views[index-1];
    this.installation_views[index-1] = this.installation_views[index];
    this.installation_views[index] = oldElement;
  }

  getUnit(unit) {
    if (this.language === 'ita') {
      if (unit === 'overall') {
        return 'tot.';
      } else if (unit === 'each') {
        return 'cad.';
      } else {
        return '';
      }
    }

    if (this.language === 'eng') {
      if (unit === 'overall') {
        return 'overall';
      } else if (unit === 'each') {
        return 'each';
      } else {
        return '';
      }
    }

    if (this.language === 'both') {
      if (unit === 'overall') {
        return 'tot./overall';
      } else if (unit === 'each') {
        return 'cad./each';
      } else {
        return '';
      }
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription)
      this.navigationSubscription.unsubscribe();
  }

  getName(data){
    if (!data)
      return;

    if (data.denomination)
      return data.denomination;

    let name = '';
    if (data.name)
      name += data.name + ' ';

    if (data.lastname)
      name += data.lastname;

    return name;
  }
}
