import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SearchService} from '../../services/search/search.service';
import {Router} from '@angular/router';
import {find} from 'lodash';

@Component({
  selector: 'p420-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  stateCtrl = new FormControl();
  searchResults: any[] = [];
  loadinResults = false;

  states = {

    contacts_view: {
      label: 'CONTATTI',
      icon: 'contacts',
      route: 'contacts'
    },
    works_view: {
      label: 'OPERE',
      icon: 'works',
      route: 'works'
    },
    exhibitions_view: {
      label: 'MOSTRE',
      icon: 'exhibitions',
      route: 'exhibitions'
    },
    project_rooms_view: {
      label: 'PROJECT ROOM',
      icon: 'project_rooms',
      route: 'project_rooms'
    },
    publications: {
      label: 'PUBBLICAZIONI',
      icon: 'publications',
      route: 'publications'
    },
    fairs: {
      label: 'FIERE E NEWS',
      icon: 'fairs',
      route: 'fairs'
    }
  };

  constructor(private searchService: SearchService,
              private router: Router) {
  }

  ngOnInit() {
  }

  submitSearch() {
    this.loadinResults = true;
    this.searchService.doSearch(this.stateCtrl.value).subscribe(
      (results: any) => {
        this.searchResults = results;
        this.loadinResults = false;
      },
      e => {
        this.searchResults = [];
        this.loadinResults = false;
      });
  }

  getGroupLabel(type) {
    return this.states[type].label;
  }

  getGroupIcon(type) {
    return this.states[type].icon;
  }

  getTitle(data) {
    if (data.denomination) {
      return data.denomination;
    } else {
      return data.name + ' ' + data.lastname;
    }
  }

  navigate(table, id) {
    if (table.type === 'works_view') {
      let work = find(table.data, ['id', +id]);
      const author_id = work.author_id;
      const author_lastname = work.lastname;
      const author_denomination = work.denomination;
      const filter = author_lastname ? author_lastname : author_denomination;

      const route = this.states[table.type].route;
      this.router.navigate([`/admin/${route}`], {queryParams: {t: 'all', detail: author_id, filter: filter, work_id: id}});

    } else {
      const route = this.states[table.type].route;
      this.router.navigate([`/admin/${route}`], {queryParams: {id: id}})
    }

  }
}
