import {FlexLayoutModule} from '@angular/flex-layout';
import {MyMaterialModule} from '../modules/my-material/my-material.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {StorageServiceModule} from 'ngx-webstorage-service';
import {LocalStorageService} from '@services';
import {AuthUtilsService} from '@services';
import { LayoutComponent } from './components/app-layout/layout/layout.component';
import { SidenavListComponent } from './components/app-layout/sidenav-list/sidenav-list.component';
import { HeaderComponent } from './components/app-layout/header/header.component';
import {TopBarComponent} from './components/app-layout/top-bar/top-bar.component';
import {LoaderComponent} from './components/loader/loader.component';
import {DialogModule} from './dialog/dialog.module';
import {GlobalSearchComponent} from './components/global-search/global-search.component';
import {SecondSidenavComponent} from './components/app-layout/second-sidenav/second-sidenav.component';
import {FilterInputComponent} from './components/filter-input/filter-input.component';
import {NumberFormatPipe} from './pipes/number.pipe';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {TruncatePipe} from './pipes/truncate.pipe';
import {ToInchPipe} from './pipes/size.pipe';
import {NgxPrintModule} from 'ngx-print';
import { ImageListComponent } from './components/image-list/image-list.component';
import {BackButtonDirective} from './directives/back-button.directive';
import {ResizableModule} from 'angular-resizable-element';
import {SelectedImagesModalComponent} from './components/selected-images-modal/selected-images-modal.component';
import {SectionPortfolioComponent} from './components/section-portfolio/section-portfolio.component';
import {SectionLabelComponent} from './components/section-label/section-label.component';
import {VideoListComponent} from './components/video-list/video-list.component';
import {SectionWorkSheetComponent} from './components/section-work-sheet/section-work-sheet.component';
import {SectionCertificateComponent} from './components/section-certificate/section-certificate.component';
import {InstallationViewListComponent} from './components/installation-view-list/installation-view-list.component';
import {OrderItemsDialogComponent} from './components/order-items/order-items-dialog.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HeaderInterceptor} from './interceptor/interceptor';
import {GALLERY_CONFIG, GalleryModule} from 'ng-gallery';
import {LightboxModule} from 'ng-gallery/lightbox';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin  from '@fullcalendar/interaction';
import {MainCoverImageComponent} from './components/main-cover-image/main-cover-image.component';
import {CapitalizePipe} from './pipes/capitalize.pipe';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  listPlugin,
  timeGrigPlugin,
]);

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MyMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    InfiniteScrollModule,
    NgxPrintModule,
    ResizableModule,
    GalleryModule,
    LightboxModule.withConfig({
      panelClass: 'fullscreen'
    }),
    FullCalendarModule
],
  declarations: [
    TruncatePipe,
    CapitalizePipe,
    LayoutComponent,
    SidenavListComponent,
    HeaderComponent,
    TopBarComponent,
    LoaderComponent,
    SecondSidenavComponent,
    GlobalSearchComponent,
    FilterInputComponent,
    NumberFormatPipe,
    ToInchPipe,
    ImageListComponent,
    InstallationViewListComponent,
    BackButtonDirective,
    SelectedImagesModalComponent,
    SectionPortfolioComponent,
    SectionLabelComponent,
    SectionWorkSheetComponent,
    SectionCertificateComponent,
    VideoListComponent,
    OrderItemsDialogComponent,
    MainCoverImageComponent,
  ],

  exports: [
    TruncatePipe,
    RouterModule,
    CommonModule,
    MyMaterialModule,
    FlexLayoutModule,
    StorageServiceModule,
    FormsModule,
    ReactiveFormsModule,
    TopBarComponent,
    LoaderComponent,
    DialogModule,
    SecondSidenavComponent,
    FilterInputComponent,
    NumberFormatPipe,
    ToInchPipe,
    InfiniteScrollModule,
    NgxPrintModule,
    GalleryModule,
    LightboxModule,
    //GallerizeModule,
    ImageListComponent,
    InstallationViewListComponent,
    BackButtonDirective,
    ResizableModule,
    SelectedImagesModalComponent,
    SectionPortfolioComponent,
    SectionLabelComponent,
    SectionWorkSheetComponent,
    SectionCertificateComponent,
    VideoListComponent,
    OrderItemsDialogComponent,
    FullCalendarModule,
    MainCoverImageComponent,
    CapitalizePipe,
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    {
      provide: GALLERY_CONFIG,
      useValue: {
        dots: true,
        imageSize: 'cover'
      }
    }
  ]

})
export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [

        LocalStorageService,
        AuthUtilsService,
        {
          provide: GALLERY_CONFIG,
          useValue: {
            dots: true,
            imageSize: 'cover'
          }
        }

      ],

    };
  }
}
