import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Auth} from '@models';
import {NavigationService} from '../location/navigation.service';

const ROLE_VIEWER = 'viewer';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private STORAGE_KEYS = {
    auth: 'auth'
  };
  private KEY_AUTH = 'p420.auth';
  public KEY_PARAMETERS = 'p420.parameters';

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: StorageService,
    private navigationService: NavigationService
  ) {}

  public getAuth() {
    return this.localStorage.get(this.KEY_AUTH) || null;
  }


  removeAuth() {
    this.localStorage.remove(this.KEY_AUTH);
  }

  getAuthInfo() {
    const authInfo = this.localStorage.get(this.STORAGE_KEYS.auth);
    return authInfo || null;
  }

  setAuth(auth: Auth) {
    this.localStorage.set(this.KEY_AUTH, auth);
  }

  getRole() {
    const auth = this.getAuth();
    return auth ? auth.role : ROLE_VIEWER;
  }

  //region PARAMETERS

  getParameters() {
    return this.localStorage.get(this.KEY_PARAMETERS) || {};
  }

  saveParameters(value) {
    this.localStorage.set(this.KEY_PARAMETERS, value);
  }

  setCurrency(value) {
    const parameters = this.getParameters();
    parameters.currency = value;
    this.navigationService.setCurrency(value);
    this.saveParameters(parameters);
  }

  getCurrency() {
    const parameters = this.getParameters();
    return parameters.currency || 'EUR';
  }

  setMeasurementUnit(value) {
    const parameters = this.getParameters();
    parameters.measurementUnit = value;
    this.navigationService.setMeasurementUnit(value);
    this.saveParameters(parameters);
  }

  getMeasurementUnit() {
    const parameters = this.getParameters();
    return parameters.measurementUnit || 'cm';
  }

  setHidePriceFlag(value) {
    const parameters = this.getParameters();
    parameters.hidePriceFlag = value;
    this.navigationService.setHidePriceFlag(value);
    this.saveParameters(parameters);
  }

  getHidePriceFlag() {
    const parameters = this.getParameters();
    return parameters.hidePriceFlag;
  }

  setHideAvailabilityFlag(value) {
    const parameters = this.getParameters();
    parameters.hideAvailabilityFlag = value;
    this.navigationService.setHideAvailabilityFlag(value);
    this.saveParameters(parameters);
  }

  getHideAvailabilityFlag() {
    const parameters = this.getParameters();
    return parameters.hideAvailabilityFlag;
  }

  setLanguage(value) {
    const parameters = this.getParameters();
    parameters.language = value;
    this.navigationService.setLanguage(value);
    this.saveParameters(parameters);
  }

  getLanguage() {
    const parameters = this.getParameters();
    return parameters.language || 'ita';
  }


  // parameter for works list

  setSelectedWorkId(value) {
    const parameters = this.getParameters();
    parameters.selectedWorkId = value;
    this.saveParameters(parameters);
  }

  getSelectedWorkId() {
    const parameters = this.getParameters();
    return parameters.selectedWorkId || null;
  }

  setSelectedFolderId(value) {
    const parameters = this.getParameters();
    parameters.selectedFolderId = value;
    this.saveParameters(parameters);
  }

  getSelectedFolderId() {
    const parameters = this.getParameters();
    return parameters.selectedFolderId || null;
  }
}

