import {Image} from '@models';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';

interface E {
  id?: number;
}

export interface IPagination {
  length: number;
  pageSize: number;
  pageIndex: number;
  lastPage: number;
}

export interface ICollection<T> {
  pagination?: IPagination;
  data: T[];
}

export class ResourceService<T extends E> {

  API_PATH: string;
  MODEL: any;
  constructor(
    public httpClient: HttpClient,
  ) {}

  query({page, filter = '', sort = '', customParams = {}}) {
    let params = { page, filter, sort };
    params = Object.assign({}, params, customParams);
    return this.httpClient.get(environment.apiBaseUrl + '/'
      + this.API_PATH, {responseType: 'json', params})
      .pipe(map( res => { return this.mapCollection(res)}));
  }

  search({ page, query, sort, customParams }) {
    const params = { page, query, sort, customParams };
    return this.httpClient.get(environment.apiBaseUrl + '/' + this.API_PATH, {responseType: 'json', params})
      .pipe(map( res => { return this.mapCollection(res)}));
  }

  get(id: number) {
    return this.httpClient.get(environment.apiBaseUrl + '/' + this.API_PATH + '/' + id)
      .pipe(map( res => { return new this.MODEL(res)}));
  }

  getList() {
    return this.httpClient.get(environment.apiBaseUrl + '/'
      + this.API_PATH, {responseType: 'json'})
      .pipe(map( res => { return this.mapCollection(res)}));
  }


  save(item) {
    if (item.id) {
      return this.httpClient.put(environment.apiBaseUrl + '/' + this.API_PATH + '/' + item.id, item)
        .pipe(map( res => { return new this.MODEL(res)}));
    } else {
      return this.httpClient.post(environment.apiBaseUrl + '/' + this.API_PATH, item)
        .pipe(map( res => { return new this.MODEL(res)}));
    }
  }

  remove(id: number) {
    return this.httpClient.delete(environment.apiBaseUrl + '/' + this.API_PATH + '/' + id);
  }

  getLight(filter) {
    filter = filter || '';
    return this.httpClient.get(environment.apiBaseUrl + '/' + this.API_PATH + '_light', {responseType: 'json', params: {filter}});
  }

  mapCollection(collection: any): any{
    if (collection.data) {
      collection.data = collection.data.map(entity => {
        return new this.MODEL(entity);
      });

      collection.pagination = {
        pageSize: collection.perPage,
        pageIndex: collection.page -1 ,
        lastPage: collection.lastPage,
        length: collection.total
      }
    }
    else {
      collection.map(entity => {
        return new this.MODEL(entity);
      });
    }
    return collection;
  }

  saveWorkImages(){
    const images = new Image();
    return this.httpClient.put(environment.apiBaseUrl +  '/' + this.API_PATH  + '/work_change_images', images)
      .pipe(map( res => { return new this.MODEL(res)}));
  }

  exportAsCsv(data) {
    const params = data || {};
    return this.httpClient.get(environment.apiBaseUrl + '/' +
      this.API_PATH + '_export_to_csv', {responseType: 'json', params: params});
  }

  exportAsVcard(data) {
    const params = data || {};
    return this.httpClient.get(environment.apiBaseUrl + '/' +
      this.API_PATH + '_export_as_vcard', {responseType: 'json', params: params});
  }

  duplicateItem(id) {
    return this.httpClient.post(environment.apiBaseUrl + '/' + this.API_PATH + '/' + id + '/duplicate', {});
  }

  advanceSearch(data) {
    return this.httpClient.get(environment.apiBaseUrl + '/advance_search/' +
      this.API_PATH, {responseType: 'json', params: data})
      .pipe(map( res => { return this.mapCollection(res)}));
  }

  updateFile(formData, item_id) {
    return this.httpClient.put(environment.apiBaseUrl +  '/' + this.API_PATH + '/' + item_id + '/document', formData);
  }

  updateImage(formData, item_id) {
    return this.httpClient.put(environment.apiBaseUrl +  '/' + this.API_PATH + '/' + item_id + '/main_cover_image', formData);
  }

  deleteFile(documentId) {
    return this.httpClient.delete(environment.apiBaseUrl + '/' + this.API_PATH + '/documents/' + documentId);
  }

  saveImage(item_id, formData){
    return this.httpClient.put(environment.apiBaseUrl +  '/' + this.API_PATH + '/' + item_id + '/images', formData);
  }

  deleteImage(imageId){
    return this.httpClient.delete(environment.apiBaseUrl + '/' + this.API_PATH + '/images/' + imageId);
  }

  removeFromGroup(itemId, groupId) {
    return this.httpClient.delete(environment.apiBaseUrl + '/' + this.API_PATH + '/' + itemId + '/removeFromGroup/' + groupId);
  }

  saveNewOrder(data) {
    return this.httpClient.put(environment.apiBaseUrl +  '/saveNewOrder_' + this.API_PATH , data)
      .pipe(map( res => { return new this.MODEL(res)}));
  }

  getAllData() {
    return this.httpClient.get(environment.apiBaseUrl + '/no_auth_' + this.API_PATH)
      .pipe(map( res => { return this.mapCollection(res)}));
  }

  savePipelineDocument(id, pipeline) {
    return this.httpClient.put(environment.apiBaseUrl +  '/' + this.API_PATH + '/' + id + '/save_document_pipeline' , pipeline);
  }

  getCountersOfEntityByType(entity) {
    return this.httpClient.get(environment.apiBaseUrl + '/getCountersOfEntityByType/' + entity);
  }
}
