import {Component, Inject} from '@angular/core';
import {IConfirmData} from '../../../../common/dialog/dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-works-search',
  templateUrl: './works-search.component.html',
  styleUrls: ['./works-search.component.scss']
})

export class WorksSearchComponent {

  title;
  code;
  photographer_code;
  collocation;
  exhibition;
  fair;
  publication;
  height;
  width;
  depth;

  ajaxLoading = false;
  oldAdvanceSearch = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<WorksSearchComponent>
  ) {
    if (this.data['oldAdvanceSearch']){
      this.initOldSearch();
    }
  }

  initOldSearch() {
    this.oldAdvanceSearch = this.data['oldAdvanceSearch'];
    if (this.oldAdvanceSearch.title)
      this.title = this.oldAdvanceSearch.title;
    if (this.oldAdvanceSearch.code)
      this.code = this.oldAdvanceSearch.code;
    if (this.oldAdvanceSearch.photographer_code)
      this.photographer_code = this.oldAdvanceSearch.photographer_code;
    if (this.oldAdvanceSearch.collocation)
      this.collocation = this.oldAdvanceSearch.collocation;
    if (this.oldAdvanceSearch.exhibition)
      this.exhibition = this.oldAdvanceSearch.exhibition;
    if (this.oldAdvanceSearch.fair)
      this.fair = this.oldAdvanceSearch.fair;
    if (this.oldAdvanceSearch.publication)
      this.publication = this.oldAdvanceSearch.publication;
    if (this.oldAdvanceSearch.height)
      this.height = this.oldAdvanceSearch.height;
    if (this.oldAdvanceSearch.width)
      this.width = this.oldAdvanceSearch.width;
    if (this.oldAdvanceSearch.depth)
      this.depth = this.oldAdvanceSearch.depth;
  }

  abort() {
    this.dialogRef.close(null);
  }

  search() {
    const data = new DataSearchWork;

    if (this.title)
      data.title = this.title;
    if (this.code)
      data.code = this.code;
    if (this.photographer_code)
      data.photographer_code = this.photographer_code;
    if (this.collocation)
      data.collocation = this.collocation;
    if (this.exhibition)
      data.exhibition = this.exhibition;
    if (this.fair)
      data.fair = this.fair;
    if (this.publication)
      data.publication = this.publication;
    if (this.height)
      data.height = this.height;
    if (this.width)
      data.width = this.width;
    if (this.depth)
      data.depth = this.depth;

    this.dialogRef.close(data);
  }

}

class DataSearchWork {
  title: string;
  code: string;
  photographer_code: string;
  collocation: string;
  exhibition: string;
  fair: string;
  publication: string;
  height: string;
  width: string;
  depth: string;
}
