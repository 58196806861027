<div class="dialog-wrapper" fxLayout="column" (onclose)="abort()">

  <div>
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>

  <div fxFlex fxLayoutAlign="space-between" fxLayout="column">
    <div class="padding-top" fxLayout="column" >

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Titolo" name="title" [(ngModel)]="title" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Codice opera" name="code" [(ngModel)]="code" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Codice fotografo" name="photographer_code" [(ngModel)]="photographer_code" type="text">
        </mat-form-field>
      </div>

      <div class="padding-top" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field>
          <input matInput placeholder="Altezza (CM)" name="height" [(ngModel)]="height" type="number">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Larghezza (CM)" name="width" [(ngModel)]="width" type="number">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Spessore (CM)" name="depth" [(ngModel)]="depth" type="number">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Collocazione" name="collocation" [(ngModel)]="collocation" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Titolo mostra" name="exhibition" [(ngModel)]="exhibition" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Titolo fiera" name="fair" [(ngModel)]="fair" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Titolo pubblicazione" name="publication" [(ngModel)]="publication" type="text">
        </mat-form-field>
      </div>

    </div>

    <div class="dialog-footer" fxLayoutAlign="end center">
      <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
        <button mat-flat-button class="btn" (click)="abort()"> {{ data.cancelButtonText }}</button>
        <button mat-flat-button class="btn" (click)="search()"> {{ data.okButtonText }}</button>
      </div>
    </div>
  </div>

</div>
