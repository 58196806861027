import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Work} from '@models';

@Component({
  selector: 'section-label',
  templateUrl: './section-label.component.html',
  styleUrls: ['./section-label.component.scss']
})
export class SectionLabelComponent implements OnInit, OnChanges {

  @Input() matrixForLabels = [];
  @Input() n_rows_for_labels;
  @Input() n_columns_for_labels;
  @Input() selectedOption = 'cm';
  @Input() language = 'ita';
  @Input() language_format = 'ita';

  constructor() {
  }

  ngOnInit() {
    this.matrixForLabels = this.matrixForLabels.map(row => {
      return row.map(work => {
        if (work) {
          return new Work(work);
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.matrixForLabels = this.matrixForLabels.map(row => {
      return row.map(work => {
        if (work) {
          return new Work(work);
        }
      });
    });

  }

  isMultipleOf(number, divider) {
    if (number === 0) {
      return false;
    }
    const remainder = number % divider;
    return !(!!remainder);
  }

  getUnit(unit, isPrincipal) {
    if (unit === 'overall') {
      if (this.language_format === 'ita') {
        return 'tot.';
      } else if (this.language_format === 'eng') {
        return 'overall';
      } else {
        return 'tot.' + '/' + 'overall';
      }
    } else if (unit === 'each') {
      if (this.language_format === 'ita') {
        return 'cad.';
      } else if (this.language_format === 'eng') {
        return 'each';
      } else {
        return 'cad.' + '/' + 'each';
      }
    } else {
      return '';
      // if (isPrincipal) {
      //   if (this.language_format === 'ita') {
      //     return 'tot.';
      //   } else if (this.language_format === 'eng') {
      //     return 'overall';
      //   } else {
      //     return 'tot.' + '/' + 'overall';
      //   }
      // } else {
      //   if (this.language_format === 'ita') {
      //     return 'cad.';
      //   } else if (this.language_format === 'eng') {
      //     return 'each';
      //   } else {
      //     return 'cad.' + '/' + 'each';
      //   }
      // }
    }
  }

  getTechnique(work) {
    if (this.language_format === 'ita') {
      return work.technique_ita;
    } else if (this.language_format === 'eng') {
      return work.technique_eng;
    } else {
      return work.technique_ita + (work.technique_eng !== '' ? (' / ' + work.technique_eng) : '');
    }
  }

  getName(data, index, arrayLength) {
    if (!data) {
      return;
    }

    if (data.denomination) {
      if (index < arrayLength) {
        return data.denomination + ', ';
      }
      return data.denomination;
    }

    let name = '';
    if (data.name) {
      name += data.name + ' ';
    }

    if (data.lastname) {
      name += data.lastname;
    }

    if (index < arrayLength) {
      return name + ', ';
    }
    return name;
  }

}
