import {Collocation, Contact, ContactLight, Document, Image, Publication, Work} from '@models';

export class Exhibition {

  id?: number;
  title?: string;
  start_date?: string;
  year?: number;
  end_date?: string;
  exhibition_venue?: Collocation;
  exhibition_venue_id?: number;
  note_ita?: string;
  note_eng?: string;
  press_release?: string;
  publications?: Publication[] = [];
  curators?: Contact[] = [];
  artists?: Contact[] = [];
  artist_ids?: number[] = [];
  curator_ids?: number[] = [];
  works?:  Work[] = [];
  work_ids?: number[] = [];
  owner_type?: string;
  documents?: Document[] = [];
  installation_views?: Cover[] = [];
  exhibition_description?: string;
  exhibition_description_eng?: string;
  visible_on_site?: number;
  works_pipeline?: string;
  installation_view_pipeline?: string;
  document_pipeline?:string;
  cover: Image;
  main_cover_image?: string;

  constructor(exhibition: Partial<Exhibition> = {}) {
    Object.assign(this, exhibition);
    if (this.installation_views.length == 0) {
      let newImage = new Cover();
      this.installation_views.push(newImage);
    }
    else if(this.installation_views.length > 0){
      this.installation_views = this.installation_views.map(image => {return new Cover(image)});
    }
  }

}


class Cover {
  id?: number;
  name?: string;
  is_principal?: any;
  HR?: any;
  type?: string;
  photographer_code?: string;
  photographer_id?: number;
  photographer: ContactLight;
  note: string;
  path?: string;
  order_number?: number;
  file?: any;
  exhibition_id?: number;

  metadata?: {
    data_updated?: boolean;
    file_changed?: boolean;
    checked_for_portfolio?: boolean;
    loading?: boolean;
    size_error?: boolean;
    has_error?: boolean;
    err_message?: string;
    succeeded?: boolean;
  };

  constructor(cover: Partial<Cover> = {}) {
    Object.assign(this, cover);

    this.is_principal = !!cover.is_principal;
    this.HR = !!cover.HR;

    if(cover && cover.metadata)
      this.metadata.loading = false;

    if(!cover.metadata)
      this.metadata = {};
  }
}
