import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmComponent } from '../confirm/confirm.component';
import {Video} from '@models';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss']
})
export class AddVideoComponent implements OnInit {

  video = new Video();
  error: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmComponent>
  ) {}

  ngOnInit() {}

  abort() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.video);
  }

}
