import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {AuthService} from '@services';
import {DialogService} from '../../../dialog/dialog.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit {

  @Input() isMediumDevice = false;

  constructor(
    public authService: AuthService,
    private dialogService: DialogService,
    private router: Router

  ) { }

  ngOnInit() {
  }

  logout(){
    this.authService.logout();
  }

  openSettingsModal() {
    const config = {
      data: {
        title: 'IMPOSTAZIONI',
        okButtonText: 'CHIUDI'
      }
    };
    this.dialogService
        .settingsModal(config)
        .afterClosed()
        .subscribe(result => {
        });
  }

  isLinkActive(url){
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    return baseUrl.includes(url);
  }

}
