<div class="confirm-dialog">

  <div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <h1 class="mat-headline">Aggiungi opere al gruppo {{group?.name | titlecase}}</h1>
      <h4 class="mat-h4" *ngIf="data.contentText">Inserisci il nome del gruppo nel campo sottostante</h4>
    </div>

    <mat-card>

      <div fxLayout="column" fxLayoutAlign="space-between">
        <div fxFlex="100">
          <mat-form-field class="large-select">
            <mat-label>OPERE</mat-label>
            <mat-select [(ngModel)]="worksSelected" name="works" [multiple]="true" [compareWith]="compareObjects">
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Cerca'"
                                       [noEntriesFoundLabel]="'Nessuna Opera Trovata'"
                                       name="works-search"
                                       ngModel (ngModelChange)="filterWorks($event)">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let work of works" [value]="work">
                {{(work?.authors?.length ? getName(work?.authors[0]) + ',' : '')}}
                {{(work?.code + ',')}}

                <i>{{(work?.title ? work?.title + ',' : '--')}}</i>

                {{(work?.year ? work?.year + ',' : '')}}

                {{(work?.technique_ita && work?.technique_eng ? work?.technique_ita + '/' + work?.technique_eng +
                ((work?.dimensions?.work_duration || work?.edition) ? ',' : '') : '')}}

                {{(work?.technique_ita && !work?.technique_eng ? work?.technique_ita +
                ((work?.dimensions?.work_duration || work?.edition) ? ',' : '') : '')}}

                {{(!work?.technique_ita && work?.technique_eng ? work?.technique_eng +
                ((work?.dimensions?.work_duration || work?.edition) ? ',' : '') : '')}}

                {{(work?.dimensions?.work_duration ? work?.dimensions?.work_duration +
                (work?.edition ? work?.edition + ',' : '' ) : '')}}

                {{(work?.edition ? work?.edition : '')}}

              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="100" class="show_select_value" *ngIf="worksSelected.length">
          <mat-label>Opere selezionate</mat-label>

          <div *ngFor="let work of worksSelected; let index = index">
                    <span>
                    {{(work?.authors?.length ? (work?.authors[0].denomination ?
                      work?.authors[0].denomination + ',' : work?.authors[0].name + ' ' + work?.authors[0].lastname + ',') : '')}}
                      <i>{{(work?.title ? work?.title + ',' : '--')}}</i>
                      {{(work?.year ? work?.year + ',' : '')}}

                      {{(work?.technique_ita && work?.technique_eng ? work?.technique_ita + '/' + work?.technique_eng +
                      ((work?.dimensions?.work_duration || work?.edition) ? ',' : '') : '')}}

                      {{(work?.technique_ita && !work?.technique_eng ? work?.technique_ita +
                      ((work?.dimensions?.work_duration || work?.edition) ? ',' : '') : '')}}

                      {{(!work?.technique_ita && work?.technique_eng ? work?.technique_eng +
                      ((work?.dimensions?.work_duration || work?.edition) ? ',' : '') : '')}}

                      {{(work?.dimensions?.work_duration ? work?.dimensions?.work_duration +
                      (work?.edition ? work?.edition + ',' : '' ) : '')}}

                      {{(work?.edition ? work?.edition : '')}}

                    </span>
          </div>
        </div>
      </div>
    </mat-card>

    <div class="dialog-footer" fxLayoutAlign="end center">
      <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
        <button mat-raised-button class="cancel-btn" (click)="abort()"
                tabindex="2"> Annulla</button>
        <button mat-raised-button class="ok-btn" (click)="save()"
                [ngClass]="{'disabled': !worksSelected.length }"
                [disabled]="!worksSelected.length"> AGGIUNGI </button>
      </div>
    </div>
  </div>

</div>
