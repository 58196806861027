import {Component, Inject} from '@angular/core';
import {IConfirmData} from '../../../../common/dialog/dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-person-search',
  templateUrl: './person-search.component.html',
  styleUrls: ['./person-search.component.scss']
})

export class PersonSearchComponent {

  name;
  lastname;
  denomination;
  company;
  address;
  city;
  country;
  tel;
  email;
  note;
  photographer_code;
  ajaxLoading = false;
  oldAdvanceSearch = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<PersonSearchComponent>
  ) {
    if (this.data['oldAdvanceSearch']) {
      this.initOldSearch();
    }
  }

  initOldSearch() {
    this.oldAdvanceSearch = this.data['oldAdvanceSearch'];
    if (this.oldAdvanceSearch.name)
      this.name = this.oldAdvanceSearch.name;
    if (this.oldAdvanceSearch.lastname)
      this.lastname = this.oldAdvanceSearch.lastname;
    if (this.oldAdvanceSearch.denomination)
      this.denomination = this.oldAdvanceSearch.denomination;
    if (this.oldAdvanceSearch.photographer_code)
      this.photographer_code = this.oldAdvanceSearch.photographer_code;

    if (this.oldAdvanceSearch.company)
      this.company = this.oldAdvanceSearch.company;
    if (this.oldAdvanceSearch.address)
      this.address = this.oldAdvanceSearch.address;
    if (this.oldAdvanceSearch.city)
      this.city = this.oldAdvanceSearch.city;
    if (this.oldAdvanceSearch.country)
      this.country = this.oldAdvanceSearch.country;
    if (this.oldAdvanceSearch.photographer_code)
      this.photographer_code = this.oldAdvanceSearch.photographer_code;
    if (this.oldAdvanceSearch.tel)
      this.tel = this.oldAdvanceSearch.tel;
    if (this.oldAdvanceSearch.email)
      this.email = this.oldAdvanceSearch.email;
    if (this.oldAdvanceSearch.note)
      this.note = this.oldAdvanceSearch.note;
  }

  abort() {
    this.dialogRef.close(null);
  }

  search() {
    const data = new DataSearchContact;

    if (this.name)
      data.name = this.name;
    if (this.lastname)
      data.lastname = this.lastname;
    if (this.denomination)
      data.denomination = this.denomination;
    if (this.company)
      data.company = this.company;
    if (this.address)
      data.address = this.address;
    if (this.city)
      data.city = this.city;
    if (this.country)
      data.country = this.country;
    if (this.photographer_code)
      data.photographer_code = this.photographer_code;
    if (this.tel)
      data.tel = this.tel;
    if (this.email)
      data.email = this.email;
    if (this.note)
      data.note = this.note;

    this.dialogRef.close(data);
  }

}

class DataSearchContact {
  name: string;
  lastname: string;
  denomination: string;
  company: string;
  address: string;
  city: string;
  country: string;
  tel: string;
  email: string;
  photographer_code: string;
  note: string;
}
