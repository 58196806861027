import {Component, Inject} from '@angular/core';
import {IConfirmData} from '../../../../common/dialog/dialog.service';
import * as moment from 'moment';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-project-rooms-search',
  templateUrl: './project-rooms-search.component.html',
  styleUrls: ['./project-rooms-search.component.scss']
})

export class ProjectRoomsSearchComponent {

  title;
  curator;
  artist;
  start_date;
  start_date_operator = '>';
  end_date;
  end_date_operator = '<';
  ajaxLoading = false;
  oldAdvanceSearch = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<ProjectRoomsSearchComponent>
  ) {
    if (this.data['oldAdvanceSearch']){
      this.initOldSearch();
    }
  }

  initOldSearch() {
    this.oldAdvanceSearch = this.data['oldAdvanceSearch'];
    if (this.oldAdvanceSearch.title)
      this.title = this.oldAdvanceSearch.title;
    if (this.oldAdvanceSearch.curator)
      this.curator = this.oldAdvanceSearch.curator;
    if (this.oldAdvanceSearch.artist)
      this.artist = this.oldAdvanceSearch.artist;
    if (this.oldAdvanceSearch.start_date)
      this.start_date = moment(this.oldAdvanceSearch.start_date).format('YYYY-MM-DD');
    if (this.oldAdvanceSearch.start_date_operator)
      this.start_date_operator = this.oldAdvanceSearch.start_date_operator;
    if (this.oldAdvanceSearch.end_date)
      this.end_date = moment(this.oldAdvanceSearch.end_date).format('YYYY-MM-DD');
    if (this.oldAdvanceSearch.end_date_operator)
      this.end_date_operator = this.oldAdvanceSearch.end_date_operator;
  }

  abort() {
    this.dialogRef.close(null);
  }

  search() {
    const data = new DataSearchProjectRoom;

    if (this.title)
      data.title = this.title;
    if (this.curator)
      data.curator = this.curator;
    if (this.artist)
      data.artist = this.artist;
    if (this.start_date)
      data.start_date = moment(this.start_date).format('YYYY/MM/DD').toString();
    if (this.start_date_operator)
      data.start_date_operator = this.start_date_operator;
    if (this.end_date)
      data.end_date = moment(this.end_date).format('YYYY/MM/DD').toString();
    if (this.end_date_operator)
      data.end_date_operator = this.end_date_operator;

    this.dialogRef.close(data);
  }

}

class DataSearchProjectRoom {
  title: string;
  curator: string;
  artist: string;
  start_date: string;
  start_date_operator: string;
  end_date: string;
  end_date_operator: string;
}
