import {Collocation, Work} from '../work/work';
import {ContactLight, Document, Exhibition} from '@models';

export class Fair {

  id?: number;
  title?: string;
  start_date?: string;
  end_date?: string;
  works?: Work[] = [];
  exhibitions?: Exhibition[] = [];
  artists?: ContactLight[] = [];
  address?: Collocation;
  address_id?: number;
  category?: string;
  note?: string;
  note_ita?: string;
  note_eng?: string;
  work_ids?: number[] = [];
  exhibition_ids?: number[] = [];
  artist_ids?: number[] = [];
  documents?: Document[] = [];
  path?: string;
  file?: any;
  publishing_date?: string;
  link?: string;
  installation_views?: Cover[] = [];
  document_pipeline?:string;
  visible_on_site?: number;
  order_number?: number;

  constructor(fair: Partial<Fair> = {}) {
    Object.assign(this, fair);
    if(fair.works)
      this.works = fair.works.map(work => {return new Work(work)});

    if (this.installation_views.length == 0) {
      let newImage = new Cover();
      // newImage.is_principal = true;
      this.installation_views.push(newImage);
    }
    else if(this.installation_views.length > 0){
      this.installation_views = this.installation_views.map(image => {return new Cover(image)});
    }
  }

}


class Cover {
  id?: number;
  name?: string;
  is_principal?: any;
  HR?: any;
  type?: string;
  photographer_code?: string;
  photographer_id?: number;
  photographer: ContactLight;
  note: string;
  path?: string;
  order_number?: number;
  file?: any;
  fair_id?: number;

  metadata?: {
    data_updated?: boolean;
    file_changed?: boolean;
    checked_for_portfolio?: boolean;
    loading?: boolean;
    size_error?: boolean;
    has_error?: boolean;
    err_message?: string;
    succeeded?: boolean;
  };

  constructor(cover: Partial<Cover> = {}) {
    Object.assign(this, cover);

    this.is_principal = !!cover.is_principal;
    this.HR = !!cover.HR;

    if(cover && cover.metadata)
      this.metadata.loading = false;

    if(!cover.metadata)
      this.metadata = {};
  }
}
