import {ContactLight} from '..';
import {FairLight} from '..';
import {WorkGroup} from './work-group';
import {Exhibition, Publication} from '..';

export class Price {

  id?: number;
  currency: string;
  price_no_vat?: number;
  price_with_vat?: number;
  custom_price?: number;
  vat?: number;
  retail_price?: number;
  production_cost_p420?: number;
  production_cost_artist?: number;

  constructor(price: Partial<Price> = {}) {
    Object.assign(this, price);
  }

}


export class Work {
  id?: number;
  authors?: ContactLight[] = [];
  type?: string;
  title?: string;
  code?: string;
  groups: WorkGroup[] = [];
  register_number?: string;
  copies?: string;
  year?: string;
  technique_ita?: string;
  technique_eng?: string;
  origin_ita?: string;
  origin_eng?: string;
  dimensions?: Dimensions;
  framed?: boolean;
  edition?: string;
  signature?: string;
  authentication_date?: string;
  authentic?: boolean;
  delivered?: boolean;
  notes?: string;
  status?: string;
  collocation?: Collocation;
  collocation_id?: number;
  collocation_note?: string;
  on_consignment?: boolean;
  consignment_from?: ContactLight;
  consignment_from_id?: number;
  certificate_for_heirs?: boolean;
  certificate_for_family?: boolean;
  certificate_for_artist?: boolean;
  saleStatus?: SaleStatus;
  price?: Price;
  images?: Image[] = [];
  publications?: Publication[] = [];
  publication_ids?: number[] = [];
  exhibition_ids?: number[] = [];
  fair_ids?: number[] = [];
  group_ids?: number[] = [];
  author_ids?: number[] = [];
  exhibitions?: Exhibition[] = [];
  fairs?: FairLight[] = [];
  cover: Image;
  note_pdf_ita?: string;
  note_pdf_eng?: string;

  selected?: boolean = true;
  newPosition?: number;

  constructor(work: Partial<Work> = {}) {
    Object.assign(this, work);

    if (!this.saleStatus)
      this.saleStatus = new SaleStatus();
    if (!this.price)
      this.price = new Price();
    if (!this.dimensions)
      this.dimensions = new Dimensions();

    if (this.images.length == 0) {
      let newImage = new Image();
      this.images.push(newImage);
    }
    else if(this.images.length > 0){
      this.images = this.images.map(image => {return new Image(image)});
    }
  }

}

export class Image {
  id?: number;
  name?: string;
  is_principal?: any;
  HR?: any;
  type?: any;
  photographer_code?: string;
  photographer_id?: number;
  photographer: ContactLight;
  note?: string;
  note_ita?: string;
  note_eng?: string;
  path?: string;
  file?: any;
  work_id?: number;
  metadata?: {
    data_updated?: boolean;
    file_changed?: boolean;
    checked_for_portfolio?: boolean;
    loading?: boolean;
    size_error?: boolean;
    has_error?: boolean;
    err_message?: string;
    succeeded?: boolean;
  };

  constructor(image: Partial<Image> = {}) {
    Object.assign(this, image);
    this.is_principal = !!image.is_principal;
    this.HR = !!image.HR;
    if(image && image.metadata)
      this.metadata.loading = false;

    if(!image.metadata)
      this.metadata = {};
  }
}

export class OutGoingImage {

  id?: number;
  is_principal?: any;
  HR?: any;
  type?: any;
  photographer_code?: string;
  photographer_id?: number;
  photographer: ContactLight;
  order_number?: number;
  note?: string;
  file?: any;
  index?: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }

}

export class Dimensions {

  id?: number;
  height?: number;
  width?: number;
  depth?: number;
  weight?: string;
  overall_unit?: string;
  package_height?: number;
  package_width?: number;
  package_depth?: number;
  package_weight?: string;
  package_overall_unit?: string;
  with_frame_height?: number;
  with_frame_width?: number;
  with_frame_depth?: number;
  with_frame_weight?: string;
  with_frame_overall_unit?: string;
  packaging_material?: string;
  work_duration?: number;
  variable_dimensions?: boolean;
  notes?: string;
  height_additional?: number;
  width_additional?: number;
  depth_additional?: number;
  weight_additional?: string;
  overall_unit_additional?: string;

  constructor(dimension: Partial<Dimensions> = {}) {
    Object.assign(this, dimension);
  }

}

export class SaleStatus {
  id?: number;
  sold: boolean;
  date_of_purchase?: any;
  invoice_number?: string;
  client?: ContactLight;
  client_id?: number;
  returned?: boolean;
  return_date?: any;
  delivery?: boolean;
  delivery_note?: string;

  constructor(sale: Partial<SaleStatus> = {}) {
    Object.assign(this, sale);
  }

}


export class Collocation {

  id?: number;
  country: string;
  province?: string;
  city?: string;
  address?: string;
  zip?: string;
  description?: string;
  contact_id?: number;
  name?: string;
  lastname?: string;
  denomination?: string;
  fiscal_code?: string;
  p_iva?: string;

  constructor(collocation: Partial<Collocation> = {}) {
    Object.assign(this, collocation);
  }

}

export class WorkLight {
  id?: number;
  name?: string;
  lastname?: string;

  constructor(workLight: Partial<WorkLight> = {}) {
    Object.assign(this, workLight);
  }

}
