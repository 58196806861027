import {Injectable} from '@angular/core';
import {ResourceService} from '@services';
import {Fair} from '@models';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable()
export class FairsService extends ResourceService<Fair> {

  API_PATH = 'fairs';
  MODEL = Fair ;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  save(item, id = null) {
    if (id) {
      return this.httpClient.put(environment.apiBaseUrl + '/' + this.API_PATH + '/' + id, item)
        .pipe(map( res => { return new this.MODEL(res)}));
    } else {
      return this.httpClient.post(environment.apiBaseUrl + '/' + this.API_PATH, item)
        .pipe(map( res => { return new this.MODEL(res)}));
    }
  }

}

