export class Document {

  id?: number;
  name?: string;
  path?: string;
  type?: string;
  created_at?: string;
  selected?: boolean;
  newPosition?: number;

  mimetype?: string;
  filename?: string;
  thumbnail?: string;

  metadata?: {
    file_changed?: boolean;
    loading?: boolean;
    has_error?: boolean;
    succeeded?: boolean;
  };

  constructor(document: Partial<Document> = {}) {
    Object.assign(this, document);
  }

}
