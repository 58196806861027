import { Component, Inject } from '@angular/core';
import { LocalStorageService} from '@services';
import {IConfirmData} from '../../dialog/dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'p420-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})

export class SettingsModalComponent {

  currentCurrency = 'EUR';
  currentMeasurementUnit = 'cm';
  currentHidePriceFlag =  false;
  currentHideAvailabilityFlag =  false;
  currentLanguage =  'ita';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmData,
    public dialogRef: MatDialogRef<SettingsModalComponent>,
    private localstorageService: LocalStorageService,
  ) {
    this.currentCurrency = localstorageService.getCurrency();
    this.currentLanguage = localstorageService.getLanguage();
    this.currentMeasurementUnit = localstorageService.getMeasurementUnit();
    this.currentHidePriceFlag = localstorageService.getHidePriceFlag();
    if (this.currentHidePriceFlag === undefined)
      this.currentHidePriceFlag = false;

    this.currentHideAvailabilityFlag = localstorageService.getHideAvailabilityFlag();
    if (this.currentHideAvailabilityFlag === undefined)
      this.currentHideAvailabilityFlag = false;
  }


  abort() {
    this.dialogRef.close(false);
  }

  priceFlagChanged(value) {
    this.localstorageService.setHidePriceFlag(value);
  }

  availabilityFlagChanged(value) {
    this.localstorageService.setHideAvailabilityFlag(value);
  }

  currencyChanged(value) {
    this.localstorageService.setCurrency(value);
  }

  measurementUnitChanged(value) {
    this.localstorageService.setMeasurementUnit(value);
  }

  languageChanged(value) {
    this.localstorageService.setLanguage(value);
  }



}
