<div class="dialog-wrapper" fxLayout="column" (onclose)="abort()">

  <div>
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>

  <div fxFlex fxLayoutAlign="space-between" fxLayout="column">
    <div class="padding-top" fxLayout="column" >

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Nome" name="name" [(ngModel)]="name" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Cognome" name="lastname" [(ngModel)]="lastname" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Denominazione" name="denomination" [(ngModel)]="denomination" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Codice fotografo" name="photographer_code" [(ngModel)]="photographer_code" type="text">
        </mat-form-field>
      </div>

    </div>

    <div class="dialog-footer" fxLayoutAlign="end center">
      <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
        <button mat-flat-button class="btn" (click)="abort()"> {{ data.cancelButtonText }}</button>
        <button mat-flat-button class="btn" (click)="search()"> {{ data.okButtonText }}</button>
      </div>
    </div>
  </div>

</div>
