
export class PublicationLight {

  id?: number;
  title?: string;


  constructor(publication_light: Partial<PublicationLight> = {}) {
    Object.assign(this, publication_light);
  }

}
