<p420-loader *ngIf="ajaxLoading"></p420-loader>

<div class="add-file">
  <mat-icon matTooltip="Aggiungi video"  (click)="openDialog()">add_circle_outline</mat-icon>
</div>

<mat-grid-list *ngIf="videos?.data" [cols]="1" rowHeight="40px" gutterSize="20px">

  <mat-grid-tile *ngFor="let video of videos?.data; let index = index">

    <div fxLayout="row" fxFlex="100" class="video-row" fxLayoutAlign="space-between center" fxLayoutGap="30px">
      <span fxFlex="33">{{video.description}}</span>
      <span fxFlex="33">{{video.description_eng}}</span>
      <span fxFlex="33" class="link" (click)="goToLink(video.link)">{{video.link}}</span>
      <div fxFlex="5" fxLayoutAlign="end center"><mat-icon (click)="deleteVideo(video.id)">delete</mat-icon></div>
    </div>

  </mat-grid-tile>

  <div fxLayoutAlign="center center" *ngIf="!videos?.data?.length" >
    <p>Nessun Video Trovato</p>
  </div>

</mat-grid-list>
