<div class="dialog-wrapper" fxLayout="column" (onclose)="abort()">

  <div>
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>

  <div fxFlex fxLayoutAlign="space-between" fxLayout="column">
    <div class="padding-top" fxLayout="column" >

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Titolo" name="title" [(ngModel)]="title" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Autore" name="author" [(ngModel)]="author" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="padding-top">
        <mat-form-field>
          <input matInput placeholder="Artista" name="artist" [(ngModel)]="artist" type="text">
        </mat-form-field>
      </div>

      <div fxLayout="row" class="padding-top">
        <mat-form-field>
          <mat-select name="year_operator" [(ngModel)]="year_operator">
            <mat-option [value]="'>'">maggiore del </mat-option>
            <mat-option [value]="'>='">maggiore o uguale del </mat-option>
            <mat-option [value]="'<'">minore del </mat-option>
            <mat-option [value]="'<='">minore o uguale del </mat-option>
            <mat-option [value]="'='">uguale</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex>
          <input matInput placeholder="Anno" name="year" [(ngModel)]="year" type="text">
        </mat-form-field>
      </div>

    </div>

    <div class="dialog-footer" fxLayoutAlign="end center">
      <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
        <button mat-flat-button class="btn" (click)="abort()"> {{ data.cancelButtonText }}</button>
        <button mat-flat-button class="btn" (click)="search()"> {{ data.okButtonText }}</button>
      </div>
    </div>
  </div>

</div>
