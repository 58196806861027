import {Injectable} from '@angular/core';
import {ResourceService} from '@services';
import {WorkGroup} from '../../common/models/work/work-group';
import {Work, Collocation} from '@models';
import {WorkFolder} from '../../common/models/work/work-folder';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable()
export class WorksService extends ResourceService<Work> {

  API_PATH = 'works';
  MODEL = Work ;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  saveImage(work_id, formData){
    return this.httpClient.put(environment.apiBaseUrl + '/' + this.API_PATH + '/' + work_id + '/images', formData);
  }

  deleteImage(imageId){
    return this.httpClient.delete(environment.apiBaseUrl + '/' + this.API_PATH + '/images/' + imageId);
  }
}

@Injectable()
export class WorkGroupsService extends ResourceService<WorkGroup> {

  API_PATH = 'work_groups';
  MODEL = WorkGroup ;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  assignWorksToGroup(data) {
    return this.httpClient.put(environment.apiBaseUrl + '/work_groups_assign_works', data);
  }

}

@Injectable()
export class WorkFoldersService extends ResourceService<WorkFolder> {

  API_PATH = 'work_folders';
  MODEL = WorkFolder ;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  getFoldersByAuthorId(id) {
    return this.httpClient.get(environment.apiBaseUrl + '/getFoldersByAuthorId/' + id)
      .pipe(map( res => { return this.mapCollection(res)}));
  }

  assignWorkToFolder(data) {
    return this.httpClient.put(environment.apiBaseUrl + '/assignWorkToFolder' , data);
  }

}

@Injectable()
export class CollocationService extends ResourceService<Collocation> {

  API_PATH = 'collocations';
  MODEL = Collocation ;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

}
