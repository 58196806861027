<div class="header-container">
  <div fxFlex fxLayout fxLayoutAlign="start">
    <ul fxLayout fxLayoutGap="15px" class="navigation-items">
      <li class="menu-icon" (click)="toggle()">
        <mat-icon class="menu-icon">menu</mat-icon>
      </li>

      <li>
        <a class="logo" routerLink="/home">P420</a>
      </li>
    </ul>
  </div>

  <div fxFlex fxLayout fxLayoutAlign="end">

    <ul fxLayout fxLayoutGap="15px" class="navigation-items">
      <li class="search-wrapper">
        <p420-global-search></p420-global-search>
      </li>
    </ul>
  </div>
</div>
