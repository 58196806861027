<div class="dialog-wrapper" fxLayout="column" *ngIf="section === 0">

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between start">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>

  <div class="form" fxLayout="column" fxLayoutAlign="space-between start">
    <mat-radio-group class="radio-group" aria-label="Select an option" [(ngModel)]="print_format">
      <mat-radio-button value="2x2">2x2</mat-radio-button>
      <mat-radio-button value="6x2">6x2</mat-radio-button>
      <mat-radio-button value="7x3">7x3</mat-radio-button>
      <mat-radio-button value="8x3">8x3</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between start">
    <h1 mat-dialog-title> Scegli la lingua </h1>
  </div>

  <div class="form" fxLayout="column" fxLayoutAlign="space-between start">
    <mat-radio-group class="radio-group" aria-label="Select an option" [(ngModel)]="language_format">
      <mat-radio-button value="ita">ITA</mat-radio-button>
      <mat-radio-button value="eng">ENG</mat-radio-button>
      <mat-radio-button value="both">ITA/ENG</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="dialog-footer" fxLayoutAlign="end center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="abort()"> Annulla</button>
      <button mat-flat-button class="btn" (click)="nextToSecond()"> Continua</button>
    </div>
  </div>

</div>


<div class="dialog-wrapper" fxLayout="column" *ngIf="section === 1">

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between start">
    <h1 mat-dialog-title> Seleziona ordine di stampa</h1>
  </div>

  <div class="dialog-header" fxLayout="column" fxLayoutAlign="space-between start">

    <div fxLayout="row" fxLayoutAlign="space-between start">

      <mat-radio-group class="radio-group" aria-label="Select an option" [(ngModel)]="printRangeSelection">
        <mat-radio-button value="all">Tutti</mat-radio-button>
        <mat-radio-button value="from_to">Intervallo</mat-radio-button>
      </mat-radio-group>

      <div>
        <div fxFlex="50">
          <mat-form-field>
            <input matInput placeholder="Da" name="from" [(ngModel)]="from" type="number"
                   [disabled]="printRangeSelection !== 'from_to'">
          </mat-form-field>
        </div>

        <div fxFlex="50">
          <mat-form-field>
            <input matInput placeholder="A" name="to" [(ngModel)]="to" type="number"
                   [disabled]="printRangeSelection !== 'from_to'">
          </mat-form-field>
        </div>
      </div>
    </div>

  </div>

  <div class="print-table">
    <div fxLayoutAlign="start center" class="row-print"
         *ngFor="let work of works; let index = index; let last = last">

      <div class="arrows">
        <mat-icon [ngClass]="{'disabled': last}" (click)="moveDown(index)">arrow_downward</mat-icon>
        <mat-icon [ngClass]="{'disabled': !(index > 0)}" (click)="moveUp(index)">arrow_upward</mat-icon>
      </div>

      <div class="index">
        {{index + 1}}
      </div>

      <div class="work-icon-container">
        <img #imageTag class="work-icon" [src]="work?.cover?.path" (error)="changeImageSource(imageTag)">
      </div>

      <span class="nav-caption title">
        {{(work?.authors?.length ? getName(work?.authors[0]) + ',' : '')}}
        {{(work?.title ? work?.title : '--') + ', '}}
        {{(work?.year ? work?.year + ', ' : '')}}
        {{(work?.technique_ita ? work?.technique_ita + (work?.technique_eng ? ' /' : '') : '')}}
        {{(work?.technique_eng ? work?.technique_eng +
        ((work?.dimensions?.height || work?.dimensions?.width || work?.dimensions?.depth ||
          work?.dimensions?.work_duration || work?.edition) ? ',' : '')
        : '')}}

        <span *ngIf="work?.dimensions?.height || work?.dimensions?.width || work?.dimensions?.depth">
          {{work?.dimensions?.height | toInch:selectedOption}}
          x {{work?.dimensions?.width | toInch:selectedOption}}
          {{work?.dimensions?.depth ? 'x ' + (work?.dimensions?.depth | toInch:selectedOption) : ''}}

          {{work?.dimensions?.height || work?.dimensions?.width ? selectedOption : ''}}
          {{work?.dimensions?.height || work?.dimensions?.width ? getUnit(work?.dimensions?.overall_unit)
           +
          ((work?.dimensions?.work_duration || work?.edition) ? ',' : '') : ''}}
        </span>

        <span *ngIf="work?.dimensions?.work_duration">{{work?.dimensions?.work_duration}}</span>

        {{' ' + (work?.edition || '')}}

          </span>
    </div>

    <div fxLayoutAlign="start center" *ngIf="!works?.length">
      <span class="nav-caption">Nessuna Opera</span>
    </div>

  </div>


  <div class="dialog-footer" fxLayoutAlign="end center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="prev()"> Indietro</button>
      <button mat-flat-button class="btn" (click)="next()"> Continua</button>
    </div>
  </div>

</div>

<div class="dialog-wrapper" fxLayout="column" *ngIf="section === 2">

  <div class="dialog-header" fxFlex fxLayoutAlign="space-between start">
    <h1 mat-dialog-title> Seleziona le coordinate di inizio stampa</h1>
  </div>

  <div class="dialog-header" fxLayout="column" fxLayoutAlign="space-between start">
    {{subTitlePrintStartChoice}}
  </div>

  <div class="start-print-choice">
    <div>
      <div fxFlex="50">
        <mat-form-field>
          <input matInput placeholder="Riga" name="row" [(ngModel)]="startRow" type="number">
        </mat-form-field>
      </div>

      <div fxFlex="50">
        <mat-form-field>
          <input matInput placeholder="Colonna" name="column" [(ngModel)]="startColumn" type="number">
        </mat-form-field>
      </div>
    </div>
  </div>


  <div class="dialog-footer" fxLayoutAlign="end center">
    <div class="button-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
      <button mat-flat-button class="btn" (click)="prev()"> Indietro</button>
      <button mat-flat-button class="btn" (click)="confirm()"> Stampa</button>
    </div>
  </div>

</div>


