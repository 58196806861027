import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LocalStorageService} from '@services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Output() toggleSideMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
  ) { }

  ngOnInit() {

  }

  toggle() {
    this.toggleSideMenu.emit(true);
  }
}
