import {Injectable} from '@angular/core';
import {ResourceService} from '@services';
import {ProjectRoom, Video} from '@models';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class ProjectRoomsService extends ResourceService<ProjectRoom> {

  API_PATH = 'project_rooms';
  MODEL = ProjectRoom;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  savePipeline(id, pipeline) {
    return this.httpClient.put(environment.apiBaseUrl + '/' + this.API_PATH + '/' + id + '/save_pipeline', pipeline);
  }

}

@Injectable()
export class ProjectRoomsVideoService extends ResourceService<Video> {

  API_PATH = 'project_room_videos';
  MODEL = Video;

  constructor(
    public httpClient: HttpClient,
  ) {
    super(httpClient);
  }

}
