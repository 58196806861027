import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ToastService} from '../../../../common/components/toast/toast.service';
import {WorkGroup} from '../../../../common/models/work/work-group';
import {WorkFoldersService, WorkGroupsService} from '../../works.service';

@Component({
  selector: 'add-work-group-dialog',
  templateUrl: './add-work-group-dialog.component.html',
  styleUrls: ['./add-work-group-dialog.component.scss']
})
export class AddWorkGroupDialogComponent {
  ajaxLoading = false;
  group: WorkGroup = new WorkGroup( null);

  constructor(
    private toastService: ToastService,
    private workGroupService: WorkGroupsService,
    private workFoldersService: WorkFoldersService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddWorkGroupDialogComponent>
  ) {}

  ngOnInit() {
    if(this.data.item)
      this.group = this.data.item;
  }

  hide() {
    this.dialogRef.close(true);
  }

  abort() {
    this.dialogRef.close(false);
  }

  save() {
    this.ajaxLoading = true;

    if (this.data.author_id) {
      const data = {
        id: this.group.id,
        name: this.group.name,
        author_id: this.data.author_id,
      }
      this.workFoldersService.save(data).subscribe(group => {
          this.ajaxLoading = false;
          this.toastService.success('Cartella creata con successo');
          this.dialogRef.close(group);
        },
        () => {
          this.ajaxLoading = false;
          this.toastService.error('Si è verificato un problema');
        })
    } else {
      this.workGroupService.save(this.group).subscribe(group => {
          this.ajaxLoading = false;
          this.toastService.success('Gruppo caricato con successo');
          this.dialogRef.close(group);
        },
        () => {
          this.ajaxLoading = false;
          this.toastService.error('Si è verificato un problema');
        })
    }

  }
}
