<div class="alert-dialog">
  <div *ngIf="data.type === 'success'">
    <div class="success animate" style="display: block;">
      <span class="line tip animateSuccessTip"></span>
      <span class="line long animateSuccessLong"></span>
      <div class="placeholder"></div>
      <div class="fix"></div>
    </div>
    <h2>{{ data.title }}</h2>
    <p>{{ data.textContent }}</p>
    <div class="button-container">
      <button mat-raised-button class="green white-text" (click)="hide()" tabindex="1">{{ data.ok }}</button>
    </div>
  </div>
  <div *ngIf="data.type === 'error'">
    <div class="error pulseError">
      <span class="body pulseErrorIns"></span>
      <span class="dot pulseErrorIns"></span>
    </div>
    <div class="text">
      <h2>{{ data.title }}</h2>
      <p>{{ data.textContent }}</p>
    </div>
    <div class="row-btn" fxLayout="row" fxLayoutAlign="space-around center">
      <div class="button-container">
        <button class="back" mat-raised-button (click)="hide()" tabindex="2">INDIETRO</button>
      </div>
      <div class="button-container">
        <button class="create" mat-raised-button (click)="hide()" tabindex="2">CREA NUOVO</button>
      </div>
    </div>
  </div>
</div>
