<div [ngClass]="{
        'margin_8x3': n_rows_for_labels === 8,
        'margin_7x3': n_rows_for_labels === 7,
        'margin_6x2': n_rows_for_labels === 6,
        'margin_2x2': n_rows_for_labels === 2
       }">
  <div *ngFor="let row of matrixForLabels; let index = index"
       [ngClass]="{
            'print_format_2x2': (n_rows_for_labels === 2),
            'print_format_6x2': (n_rows_for_labels === 6),
            'print_format_7x3': (n_rows_for_labels === 7),
            'print_format_8x3': (n_rows_for_labels === 8),
            'break_after': isMultipleOf(index+1, n_rows_for_labels),
            'first_row_of_page_2x2': isMultipleOf(index, n_rows_for_labels) && (n_rows_for_labels === 2),
            'first_row_of_page_6x2': isMultipleOf(index, n_rows_for_labels) && (n_rows_for_labels === 6),
            'first_row_of_page_7x3': isMultipleOf(index, n_rows_for_labels) && (n_rows_for_labels === 7),
            'first_row_of_page_8x3': isMultipleOf(index, n_rows_for_labels) && (n_rows_for_labels === 8)
            }">
    <div class="row">

      <div *ngFor="let item of row; let indexRow = index" class="cell"
           [ngClass]="{
            'first_cell_2x2': indexRow === 0 && (n_rows_for_labels === 2),
            'second_cell_2x2': indexRow === 1 && (n_rows_for_labels === 2),
            'first_cell_6x2': indexRow === 0 && (n_rows_for_labels === 6),
            'second_cell_6x2': indexRow === 1 && (n_rows_for_labels === 6),
            'cell_8x3': (indexRow === 0 || indexRow === 1 || indexRow === 2) && (n_rows_for_labels === 8)
            }">

        <div *ngIf="item">
          <div class="img-container"
               *ngIf="n_rows_for_labels === 2">
            <img class="img" src="{{item?.image ? item?.image?.path : item?.cover?.path}}">
          </div>

          <div style="display: flex; flex-direction: column; " class="text-container">
            <span class="authors">
              <span *ngFor="let author of item?.authors; let index = index">{{getName(author, index, item?.authors.length-1)}}</span>
            </span>

            <span><i>{{item?.title}}</i>, {{item.year}}</span>

            <span>{{getTechnique(item)}}</span>

            <span *ngIf="item?.dimensions?.height || item?.dimensions?.width || item?.dimensions?.depth">
              {{selectedOption === 'cm' ? 'cm' : 'inches'}}
              {{item?.dimensions?.height | toInch:selectedOption}}
              x
              {{item?.dimensions?.width | toInch:selectedOption}}
              {{item?.dimensions?.depth ? 'x ' + (item?.dimensions?.depth | toInch:selectedOption) : ''}}
              {{item?.dimensions?.height || item?.dimensions?.width ? getUnit(item?.dimensions?.overall_unit, true) : ''}}
            </span>

            <span *ngIf="item?.dimensions?.height_additional || item?.dimensions?.width_additional || item?.dimensions?.depth_additional">
              {{selectedOption === 'cm' ? 'cm' : 'inches'}}
              {{item?.dimensions?.height_additional | toInch:selectedOption}}
              x
              {{item?.dimensions?.width_additional | toInch:selectedOption}}
              {{item?.dimensions?.depth_additional ? 'x ' + (item?.dimensions?.depth_additional | toInch:selectedOption) : ''}}
              {{item?.dimensions?.height_additional || item?.dimensions?.width_additional ? getUnit(item?.dimensions?.overall_unit_additional, false) : ''}}
            </span>

            <span *ngIf="item?.dimensions?.work_duration">{{item?.dimensions?.work_duration}}</span>

            <span>{{item?.edition}}</span>

            <p *ngIf="(n_columns_for_labels === 2) && (n_rows_for_labels === 2 || n_rows_for_labels === 6) ">{{item?.code}}</p>

          </div>

          <div class="logo-container">
            <img src="../../assets/img/logo.svg">
          </div>
        </div>

        <div *ngIf="!item">
          <div class="img-container" *ngIf="n_rows_for_labels === 2"></div>
          <div style="display: flex; flex-direction: column; " class="text-container"></div>
          <div class="logo-container"></div>
        </div>

      </div>

    </div>
  </div>
</div>

