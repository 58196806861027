import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from '../toast/toast.service';
import {DialogService} from '../../dialog/dialog.service';
import {Video} from '@models';
import {filter} from 'lodash';
import {ExhibitionsVideoService} from '../../../modules/exhibitions/exhibitions.service';
import {ContactVideoService} from '../../../modules/contacts/contact.service';
import {ProjectRoomsVideoService} from '../../../modules/project-rooms/project_rooms.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit, OnChanges {

  @Input() item;
  @Input() type = 'exhibition';
  ajaxLoading = false;
  videos: MatTableDataSource<Video> = new MatTableDataSource([]);
  service;

  constructor(public toastService: ToastService,
              public dialogService: DialogService,
              public exhibitionsVideoService: ExhibitionsVideoService,
              public projectRoomsVideoService: ProjectRoomsVideoService,
              public contactVideoService: ContactVideoService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    if (!this.item.videos)
      this.item.videos = [];

    this.videos.data = [...this.item.videos];
    if (this.type === 'exhibition')
      this.service = this.exhibitionsVideoService ;
    else if (this.type === 'project_room')  {
      this.service = this.projectRoomsVideoService;
    } else  {
      this.service = this.contactVideoService;
    }
  }

  openDialog() {
    this.dialogService
      .addVideo({})
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.saveVideo(result);
        }
      });
  }

  saveVideo(video) {
    this.ajaxLoading = true;
    if (this.type === 'exhibition')
      video.exhibition_id = this.item.id;
    else if (this.type === 'project_room')
      video.project_room_id = this.item.id;
    else
      video.artist_id = this.item.id;

    this.service.save(video).subscribe(res => {
        this.toastService.success('Video salvato con successo');
        this.videos.data.push(res);
        this.ajaxLoading = false;
      },
      error => {
        console.log(error);
        this.ajaxLoading = false;
        this.toastService.error('Si è verificato un problema');
      });
  }

  deleteVideo(id) {
    const config = {
      data: {
        title: 'Sei sicuro di voler cancellare questo elemento?',
        errTextContent: 'Cliccando su ELIMINA procederai con la cancellazione.',
        okButtonText: 'Elimina',
        cancelButtonText: 'Annulla',
      }
    };
    this.dialogService
      .confirm(config)
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this.ajaxLoading = true;
          this.service.remove(id).subscribe(res => {
              this.toastService.success('Video cancellato con successo');
              this.videos.data = filter(this.videos.data, (item) => {
                return item.id !== id;
              });
              this.ajaxLoading = false;
            },
            error => {
              console.log(error);
              this.ajaxLoading = false;
              this.toastService.error('Si è verificato un problema');
            });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      if (this.item.videos) {
        if (this.item.videos.length) {
          this.videos.data = [...this.item.videos];
        } else {
          this.videos.data = [];
        }
      } else {
        this.videos.data = [];
      }
    }
  }

  goToLink(url) {
    window.open(url, '_blank');
  }

}
