import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {LocalStorageService, NavigationService} from '@services';
import {Gallery, GalleryItem, ImageSize, ThumbnailsPosition} from 'ng-gallery';
import {Subscription} from 'rxjs';
import {Lightbox} from 'ng-gallery/lightbox';

@Component({
  selector: 'app-installation-view-list',
  templateUrl: './installation-view-list.component.html',
  styleUrls: ['./installation-view-list.component.scss']
})
export class InstallationViewListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() images: GalleryItem[] = [];
  galleryImages: GalleryItem[] = [];
  @Input() item;
  @Input() noCheckbox = false;
  @Input() noIcons = false;
  @Output() toggleShowWorkDetail: EventEmitter<any> = new EventEmitter<any>();
  navigationSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    private gallery: Gallery,
    private lightbox: Lightbox,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.images = [...this.images];
  }

  openItem(index) {
    this.loadNex30Images(index);
    if (this.images.length > 30) {
      this.lightbox.open(0, 'anotherLightbox');
    } else {
      this.lightbox.open(index, 'anotherLightbox');
    }
  }

  loadNex30Images(index) {
    this.galleryImages = [];
    let i = index;

    if (this.images.length > 30) {
      while (i < index + 30 && i < this.images.length) {
        this.galleryImages.push(this.images[i]);
        i++;
      }
    } else {
      this.galleryImages = [...this.images];
    }
    this.resetAndLoad();
  }

  resetAndLoad() {
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
    });
    lightboxGalleryRef.reset();
    lightboxGalleryRef.load(this.galleryImages);
  }

  changeImageSource(imageTag) {
    imageTag.src = 'assets/img/placeholder-img.jpg';
  }

  goToDetail(image) {
    this.toggleShowWorkDetail.emit(image.data);
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription)
      this.navigationSubscription.unsubscribe();
    if (this.languageSubscription)
      this.languageSubscription.unsubscribe();
  }

  seeOriginPhoto(path, name) {
    let link = document.createElement("a");
    link.download = name;
    link.target = '_blank';
    link.href = path;
    link.click();
  }

  getName(data){
    if (!data)
      return;

    if (data.denomination)
      return data.denomination;

    let name = '';
    if (data.name)
      name += data.name + ' ';

    if (data.lastname)
      name += data.lastname;

    return name;
  }
}
