import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ToastComponent} from './toast.component';

interface IToastConfig {
    message?: string;
    duration?: number;
    horizontalPosition?: 'left' | 'right' | 'center' | 'start' | 'end';
    verticalPosition?: 'top' | 'bottom';
}

@Injectable({
    providedIn: 'root'
})

export class ToastService {

    constructor(private snackBar: MatSnackBar) {
    }

    error(message, data?: IToastConfig) {
        data = data || {};
        return this.snackBar.openFromComponent(ToastComponent, {
            data: {
                message: message || 'error toast!'
            },
            horizontalPosition: data.horizontalPosition || 'center',
            verticalPosition: data.verticalPosition || 'top',
            panelClass: 'error',
            duration: data.duration || 5000
        });
    }

    success(message, data?: IToastConfig) {
      data = data || {};
      return this.snackBar.openFromComponent(ToastComponent, {
        data: {
          message: message || 'success toast!'
        },
        horizontalPosition: data.horizontalPosition || 'center',
        verticalPosition: data.verticalPosition || 'top',
        panelClass: 'success',
        duration: data.duration || 5000
      });
    }

}
